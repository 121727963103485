import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';



import { fetchAdminById, updateAdmin } from '../../services/admin.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';


const EditAdmin = props =>
{
    const id = props.selectedRow.id;
    const [ show, setModalShow ] = useState( props.open );
    const [ loading, setLoading ] = useState( false );
    const [ message, setMessage ] = useState( {} );

    const {
        control,
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm( {
        defaultValues: {},
    } );

    const getAdmins = async () =>
    {
        try
        {
            const resp = await fetchAdminById( id );
            Object.keys(resp).forEach(key => {
                if (key === 'is_active') {
                    setValue('is_active', resp.is_active.toString());
                } else {
                    setValue(key, resp[key]);
                }
            });

        } catch ( error )
        {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            )
            {
                setMessage( {
                    type: 'Error',
                    message: `${ error.response.data.detail }`,
                } );
            }
        }
    };

    useEffect( () =>
    {
        setModalShow( props.open );
        getAdmins();
    }, [ props ] ); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () =>
    {
        setModalShow( false );
        props.setEditForm( false );
        if ( Object.keys( message ).length !== 0 )
        {
            props.reloadDataTable();
        }
    };

    const saveAdmin = async data =>
    {
        setLoading( true );
        setMessage( {} );
        try {
            await updateAdmin(data, id);
            setMessage({
                type: 'Success',
                message: 'Changes have been saved successfully',
            });
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }
        setLoading(false);
    };

    return (
        <AppModal
            size="lg"
            show={ show }
            onHide={ handleModalClose }
            backdrop="static"
            modalTitle="Update Admin">
            <div className="formView">
                <div className="col-12">
                    <h5>Admin</h5>
                </div>
            </div>

            { message && <MessageContainer message={ message } /> }
            <form className="row g-2" onSubmit={ handleSubmit( saveAdmin ) }>
                <div className="col-md-6">
                    <label htmlFor="FirstName">First Name</label>
                    <input
                        type="text"
                        className={
                            errors?.first_name
                                ? 'form-control is-invalid'
                                : 'form-control'
                        }
                        id="FirstName"
                        placeholder="Enter Your First Name"
                        { ...register( 'first_name', {
                            required: true,
                            maxLength: {
                                value: 255,
                                message:
                                    'First name can be max. 255 char length',
                            },
                        } ) }
                    />
                    { errors && (
                        <div className="invalid-feedback">
                            { errors.first_name?.message }
                        </div>
                    ) }
                </div>
                <div className="col-md-6">
                    <label htmlFor="LastName">Last Name</label>
                    <input
                        type="text"
                        className={
                            errors?.last_name
                                ? 'form-control is-invalid'
                                : 'form-control'
                        }
                        id="LastName"
                        placeholder="Enter Your Last Name"
                        { ...register( 'last_name', {
                            required: true,
                            maxLength: {
                                value: 255,
                                message:
                                    'Last name can be max. 255 char length',
                            },
                        } ) }
                    />
                    { errors && (
                        <div className="invalid-feedback">
                            { errors.last_name?.message }
                        </div>
                    ) }
                </div>
                <div className="col-md-6">
                    <label htmlFor="Email">Email</label>
                    <input
                        type="email"
                        className={
                            errors?.email
                                ? 'form-control is-invalid'
                                : 'form-control'
                        }
                        id="Email"
                        placeholder="Enter email"
                        { ...register( 'email', { required: true } ) }
                    />
                    { errors && (
                        <div className="invalid-feedback">
                            { errors.email?.message }
                        </div>
                    ) }
                </div>
                <div className="col-md-6">
                    <label htmlFor="mobileNumber">Mobile Number</label>
                    <PhoneInputWithCountry
                        name="mobile_number"
                        countries={ [ 'US' ] }
                        control={ control }
                        defaultCountry="US"
                        placeholder="Enter phone number"
                        rules={ {
                            required: true,
                            validate: isPossiblePhoneNumber,
                        } }
                        className={
                            errors?.mobile_number
                                ? 'form-control is-invalid'
                                : 'phone-number'
                        }
                    />
                    { errors && (
                        <div className="invalid-feedback">
                            { errors.mobile_number?.message }
                        </div>
                    ) }
                </div>
                <div className="col-md-6">
                    <label htmlFor="role-name">Role</label>
                    <select
                        className="form-control"
                        id="role-name"
                        { ...register( 'role_name', {
                            required: true,
                        } ) }>
                        { [ "Admin", "Moderator" ].map( role => (
                            <option value={ role } key={ role }>
                                { role }
                            </option>
                        ) ) }
                    </select>
                    { errors && (
                        <div className="invalid-feedback">
                            { errors.role?.message }
                        </div>
                    ) }
                </div>
                <div className="col-md-12">
                    <label htmlFor="isActive">Status</label>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            id="radio-isprimary-yes"
                            value={ true }
                            { ...register( 'is_active' ) }
                        />
                        <label
                            className="form-check-label"
                            htmlFor="radio-isprimary-yes">
                            Active
                        </label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            id="radio-isprimary-no"
                            value={ false }
                            { ...register( 'is_active' ) }
                        />
                        <label
                            className="form-check-label"
                            htmlFor="radio-isprimary-no">
                            Inactive
                        </label>
                    </div>
                </div>
                <div className="col-md-12">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={ loading }>
                        { loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        ) }
                        Submit
                    </button>
                </div>
            </form>
        </AppModal>
    );
};

export default EditAdmin;