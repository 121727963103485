import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';

import { fetchAccountList } from '../../services/account.service';
import {
    activateKits,
    fetchAddedKitsCount,
} from '../../services/kitInventories.service';
import { fetchKitOrderList } from '../../services/kitOrder.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';

const ActivateKitInventory = props => {
    const [show, setModalShow] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [kitOrderList, setKitOrderList] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState({});
    const [selectedKitOrder, setSelectedKitOrder] = useState({});
    const [kitOrdersKitCount, setKitOrdersKitCount] = useState({});

    const getAccountList = async () => {
        setLoading(true);
        try {
            const accounts = await fetchAccountList();
            setAccountList(accounts);
            setValue('accountId', accounts[0].id);
            setSelectedAccount(accounts[0]);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during account list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const handleModalClose = () => {
        setModalShow(false);
        props.setOpenAddForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadAccountTable(1, 10);
        }
    };
    useEffect(() => {
        setModalShow(props.open);
        getAccountList();
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const addedKitsCount = async () => {
        if (selectedKitOrder.id) {
            const resp = await fetchAddedKitsCount(selectedKitOrder.id);
            setKitOrdersKitCount(resp);
        }
    };

    useEffect(() => {
        addedKitsCount();
    }, [selectedKitOrder]); // eslint-disable-line react-hooks/exhaustive-deps

    const getKitOrderList = async () => {
        if (!selectedAccount?.id > 0) return [];
        try {
            const kitOrders = await fetchKitOrderList(selectedAccount.id, ['OC','PG']);
            setKitOrderList(kitOrders);
            if (kitOrders.length > 0) {
                setSelectedKitOrder(kitOrders[0]);
                setValue('kit_order_id', kitOrders[0].id);
            } else {
                setMessage({
                    message: 'No eligible kit ordered found',
                    type: 'Success',
                });
                setSelectedKitOrder({});
                setValue('kit_order_id', null);
            }
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during product list loading.',
                type: 'Error',
            });
        }
    };

    useEffect(() => {
        getKitOrderList();
        setValue('account_id', selectedAccount.id);
    }, [selectedAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setValue('kit_order_id', selectedKitOrder.id);
    }, [selectedKitOrder]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeSetAccount = accountId => {
        const account = accountList.find(
            account => account.id === parseInt(accountId),
        );
        setSelectedAccount(account);
    };

    const onChangeKitOrderSelection = kitOrderId => {
        console.log(parseInt(kitOrderId));
        console.log(kitOrderId);
        const kitOrder = kitOrderList.find(
            kitOrder => kitOrder.id === parseInt(kitOrderId),
        );
        setSelectedKitOrder(kitOrder);
        console.log(kitOrder);
    };

    const { register, handleSubmit, setValue, reset } = useForm({
        defaultValues: { is_active: true },
    });

    const handleActivateKit = async data => {
        console.log(data);
        try {
            const resp = await activateKits(selectedKitOrder.id, {
                is_active: data.is_active,
            });
            setKitOrdersKitCount(resp);
            setMessage({ message: 'Updated successfully', type: 'success' });
        } catch (error) {
            setMessage({ message: error.response.data.detail, type: 'Error' });
        }
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Activate Kits">
            {message && message.type && <MessageContainer message={message} />}

            <div className="formView">
                <form
                    className="row g-2"
                    onSubmit={handleSubmit(handleActivateKit)}>
                    <div className="col-8">
                        <label htmlFor="account">Account</label>
                        <select
                            className="form-control"
                            id="account"
                            {...register('accountId', {
                                onChange: e => {
                                    onChangeSetAccount(e.target.value);
                                },
                            })}>
                            {accountList.map(account => (
                                <option value={account.id} key={account.id}>
                                    {account.name}(id: {account.id})
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-8">
                        <label htmlFor="kit_order_id">Order Id</label>
                        <select
                            className="form-control"
                            id="kit_order_id"
                            {...register('kit_order_id', {
                                onChange: e => {
                                    onChangeKitOrderSelection(e.target.value);
                                },
                            })}>
                            {kitOrderList.map(kitOrder => (
                                <option value={kitOrder.id} key={kitOrder.id}>
                                    {`order Id: ${kitOrder.id}, product: ${kitOrder.product_name}, units:${kitOrder.quantity} `}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-12">
                        <div>
                            <span className="txt-label">
                                Ordered kits count
                            </span>
                            <span className="txt-label-value">
                                {selectedKitOrder?.item_counts}
                            </span>
                        </div>
                        <div>
                            <span className="txt-label">Added kits</span>
                            <span className="txt-label-value">
                                {kitOrdersKitCount.n_total}
                            </span>
                            <span className="txt-label">Active kits</span>
                            <span className="txt-label-value">
                                {kitOrdersKitCount.n_active}
                            </span>
                            <span className="txt-label">Inactive kits</span>
                            <span className="txt-label-value">
                                {kitOrdersKitCount.n_inactive}
                            </span>
                        </div>
                    </div>
                    {kitOrdersKitCount.n_total ===
                        kitOrdersKitCount.n_active && (
                        <div className="col-md-12">
                            <span
                                className="txt-label"
                                style={{ color: 'red' }}>
                                All added kits are active state
                            </span>
                        </div>
                    )}
                    <div className="new-line"></div>

                    <div className="col-md-12">
                        {kitOrdersKitCount.n_total !==
                            kitOrdersKitCount.n_active && (
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                Activate all inactive kits
                            </button>
                        )}

                        <span className="mx-1"></span>
                        <button
                            type="Cancel"
                            className="btn btn-secondary"
                            disabled={loading}
                            onClick={e => {
                                e.preventDefault();
                                reset();
                                handleModalClose();
                            }}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </AppModal>
    );
};

export default WithLogin(ActivateKitInventory);
