import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const NotifyConfirmation = ({
    showModal,
    hideModal,
    confirmModal,
    notifyAccountId,
    message,
    buttonName
}) => {
    const handleClose = notifyAccountId => {
        confirmModal(notifyAccountId);
        hideModal(true);
    };

    return (
        <Modal show={showModal} onHide={hideModal} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Notify Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="alert alert-success">{message}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" onClick={hideModal}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={() => handleClose(notifyAccountId)}>
                    {buttonName}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NotifyConfirmation;
