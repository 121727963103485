import DeleteIcon from '@mui/icons-material/Delete';
import Multiselect from 'multiselect-react-dropdown';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

import { accountRoles } from '../../constants';
import { fetchAccountList } from '../../services/account.service';
import {
    createAccountUser,
    fetchAccRoles,
    fetchTitleList,
} from '../../services/accountUser.service';
import { PccProvidersDropdown } from '../../services/pcc.service';
import { fetchAdminClinicsList } from '../../services/provider.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';

const AddAccountUser = props => {
    const [show, setModalShow] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [clinics, setClinics] = useState([]);
    const [title, setTitle] = useState(null);
    const [accountId, setAccountId] = useState();
    const [acmSettings, setACMSettings] = useState(false);
    const [providerSettings, setProviderSettings] = useState(false);
    const [careTeamSettings, setCareTeamSettings] = useState(false);
    const [accountList, setAccountList] = useState([]);
    const [titleList, setTitleList] = useState([]);
    const [pccProviders, setPccProviders] = useState([]);
    const [carePersonsProviders, setCarePersonsProviders] = useState([]);
    const [clinicId, setClinicId] = useState();
    const [clinicData, setClinicData] = useState();
    const [otherTitle, setOtherTitle] = useState(null);
    const [acmRoleData, setACMRoleData] = useState({
        id: 0,
        name: 'default',
    });
    const [providerRoleData, setProviderRoleData] = useState({
        id: 0,
        name: 'default',
    });
    const [careTeamRoleData, setCareTeamRoleData] = useState({
        id: 0,
        name: 'default',
    });

    const setRoleBasedSettingsACM = value => {
        if (value.target?.checked) setACMSettings(true);
        else setACMSettings(false);
    };
    const setRoleBasedSettingsProvider = value => {
        if (value.target?.checked) setProviderSettings(true);
        else setProviderSettings(false);
    };
    const setRoleBasedSettingsCareTeam = value => {
        if (value.target?.checked) setCareTeamSettings(true);
        else setCareTeamSettings(false);
    };

    const onDelete = deletedRow => {
        const pccClinicsProvidersList = carePersonsProviders.filter(
            obj =>
                !(
                    obj.clinic_id === deletedRow.clinic_id &&
                    obj.provider_id === deletedRow.provider_id
                ),
        );
        setCarePersonsProviders(pccClinicsProvidersList);
    };

    const setPCCProvidersListing = (provider, clinicData) => {
        const clinicProvider = {
            clinic_id: clinicData.value,
            clinic_name: clinicData.label,
            provider_id: provider.value,
            name: provider.label,
        };
        // check for duplicate
        const isExist = !!carePersonsProviders.find(
            obj =>
                obj.clinic_id === clinicProvider.clinic_id &&
                obj.provider_id === clinicProvider.provider_id,
        );
        if (!isExist) {
            setCarePersonsProviders([...carePersonsProviders, clinicProvider]);
        }
    };

    const onChangeClinic = clinic => {
        const clinic_ids = [];
        Object.keys(clinic).forEach(k => {
            clinic_ids.push(clinic[k]['value']);
        });
        setValue('clinic_ids', clinic_ids);
    };

    const columns = [
        {
            name: 'Site ID',
            selector: row => row.clinic_name,
        },
        {
            name: 'P.I Name',
            selector: row => row.name,
        },
        {
            name: 'Action',
            sortable: false,
            cell: row => {
                return (
                    <Fragment>
                        <span className="table-action-icon">
                            <DeleteIcon
                                className="table-action-icon red"
                                onClick={() => {
                                    onDelete(row);
                                }}
                            />
                        </span>
                    </Fragment>
                );
            },
        },
    ];

    const accRoles = async () => {
        const roles = await fetchAccRoles();
        roles.forEach(role => {
            if (role.name === accountRoles.ACM) setACMRoleData(role);
            if (role.name === accountRoles.PROVIDER) setProviderRoleData(role);
            if (role.name === accountRoles.PCC) setCareTeamRoleData(role);
        });
    };

    const getClinicList = async accountId => {
        const response = await fetchAdminClinicsList(accountId);
        const clinics = response.map(clinic => {
            return {
                value: clinic.id,
                label: clinic.name,
            };
        });
        setClinics(clinics);
    };

    const getAccountList = async () => {
        setLoading(true);
        try {
            const accounts = await fetchAccountList();
            setAccountList(accounts);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during account list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };
    const getTitleList = async () => {
        setLoading(true);
        try {
            const titles = await fetchTitleList();
            setOtherTitle(
                titles.find(t => t.title === 'Others')['id'].toString(),
            );
            setTitleList(titles);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during titles loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const handleModalClose = () => {
        setModalShow(false);
        props.setAddForm(false);
        if (Object.keys(message).length !== 0) {
            props.setReloadData(!props.reloadData);
        }
    };

    const saveAccountUser = async formData => {
        setMessage({});
        let assigned_roles = [];
        if (acmSettings && acmRoleData?.id !== 0)
            assigned_roles.push(acmRoleData?.id);
        if (providerSettings && providerRoleData?.id !== 0) {
            assigned_roles.push(providerRoleData?.id);
            if (formData?.clinic_ids.length === 0) {
                setMessage({
                    type: 'Error',
                    message: `site is required`,
                });
                return;
            }
        }
        if (careTeamSettings && careTeamRoleData?.id !== 0)
            assigned_roles.push(careTeamRoleData?.id);
        if (assigned_roles.length === 0) {
            setMessage({
                type: 'Error',
                message: `Please select at least one role`,
            });
            return;
        }

        const data = {
            account_id: formData.accountId,
            title_id: formData.title_id,
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            is_primary: formData.is_primary,
            npi_id: formData.npi_id,
            clinic_ids: formData?.clinic_ids,
            care_team_clinics: formData?.care_team_clinics,
            assigned_roles: assigned_roles,
            pcc_clinics_provider: carePersonsProviders.map(obj => ({
                clinic_id: obj.clinic_id,
                provider_id: obj.provider_id,
            })),
            other_title: formData.other_title,
        };

        setLoading(true);
        setMessage({});
        try {
            await createAccountUser(data);
            setMessage({
                type: 'Success',
                message: 'Account user created successfully',
            });
            reset();
            setACMSettings(false);
            setProviderSettings(false);
            setCareTeamSettings(false);
            props.setReloadData(!props.reloadData);
        } catch (error) {
            error.response.data.errors?.forEach(validationError => {
                Object.keys(validationError).forEach(key => {
                    setMessage({
                        type: 'Error',
                        message:
                            validationError[key] ===
                            'pcc_clinics_provider is required'
                                ? 'Please associate providers for patient care collaborator role.'
                                : `${validationError[key]}`,
                    });
                });
            });
        }
        setLoading(false);
    };

    const getPccProviders = async clinic => {
        setLoading(true);
        setClinicData(clinic);
        if (clinic) setClinicId(clinic.value);
        try {
            const resp = await PccProvidersDropdown(clinic.value);

            const providers = resp.map(provider => {
                return {
                    value: provider.id,
                    label: `${provider.first_name} ${provider.last_name}`,
                };
            });

            setPccProviders(providers);
        } catch (error) {
            setMessage({});

            if (error.response.data.detail) {
                console.log(error.response.data.detail);
                if (error.response.data.detail === 'provider not found')
                    setPccProviders([]);
                setMessage({
                    type: 'Error',
                    message:
                        error.response.data.detail === 'provider not found'
                            ? 'Selected site has no providers associated'
                            : `${error.response.data.detail}`,
                });
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        setModalShow(props.open);
        accRoles();
        getAccountList();
        getTitleList();
        setMessage({});
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        control,
        register,
        handleSubmit,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            account_id: props.accountId,
            is_primary: 'false',
            contact_office_number: null,
            npi_id: '',
            clinic_ids: [],
            care_team_clinics: [],
        },
    });

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Create Account User">
            <div className="formView">
                <div className="col-12">
                    <h5>Account User</h5>
                </div>
                {message && <MessageContainer message={message} />}
                <form
                    className="row g-2"
                    onSubmit={handleSubmit(saveAccountUser)}>
                    <div className="col-12">
                        <label htmlFor="acmFirstName">Account</label>
                        <select
                            className={
                                errors?.accountId
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="accountId"
                            {...register('accountId', { required: true })}
                            onChange={e => {
                                setAccountId(e.target.value);
                                getClinicList(e.target.value);
                                setCarePersonsProviders([]);
                                setClinicId();
                                accountList.forEach(account => {
                                    if (
                                        account.id.toString() === e.target.value
                                    ) {
                                        setValue(
                                            'contact_office_number',
                                            account.contact_office_number,
                                        );
                                    }
                                });
                            }}>
                            <option value="" key="0">
                                Select an account
                            </option>
                            {accountList.map(account => (
                                <option value={account.id} key={account.id}>
                                    {account.name}(id: {account.id})
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-12">
                        <label htmlFor="title_id">Title</label>
                        <select
                            className={
                                errors?.title_id
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="title_id"
                            {...register('title_id', { required: true })}
                            onChange={e => {
                                setTitle(e.target.value);
                            }}>
                            <option value="" key="0">
                                Select a title
                            </option>
                            {titleList.map(title => (
                                <option value={title.id} key={title.id}>
                                    {title.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    {title === otherTitle && (
                        <div className="col-12">
                            <label htmlFor="other_title"> {''}</label>
                            <input
                                type="text"
                                className={
                                    errors?.first_name
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                id="other_title"
                                {...register('other_title')}
                            />
                        </div>
                    )}
                    <div className="col-md-6">
                        <label htmlFor="acmFirstName">First name</label>
                        <input
                            type="text"
                            className={
                                errors?.first_name
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="acmFirstName"
                            placeholder="Enter first name"
                            {...register('first_name', {
                                required: true,
                                maxLength: {
                                    value: 200,
                                    message:
                                        'First name can be max. 200 char length',
                                },
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.first_name?.message.replace('acm.', '')}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="acmLastName">Last name</label>
                        <input
                            type="text"
                            className={
                                errors?.last_name
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="acmLastName"
                            placeholder="Enter last name"
                            {...register('last_name', {
                                required: true,
                                maxLength: {
                                    value: 200,
                                    message:
                                        'Last name can be max. 200 char length',
                                },
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.last_name?.message.replace('acm.', '')}
                            </div>
                        )}
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="acmEmail">Email</label>
                        <input
                            type="text"
                            className={
                                errors?.email
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="acmEmail"
                            placeholder="Enter email"
                            {...register('email', { required: true })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.email?.message}
                            </div>
                        )}
                    </div>

                    <label sm={2}>Assign Roles</label>
                    <div className="form-check col-md-12">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="acmRole"
                            {...register('is_acm', {
                                required: false,
                            })}
                            onClick={e => setRoleBasedSettingsACM(e)}
                        />
                        <label className="form-check-label" htmlFor="acmRole">
                            Account Manager
                        </label>
                    </div>
                    <div className="form-check col-md-12">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="providerRole"
                            {...register('is_provider', {
                                required: false,
                            })}
                            onClick={e => setRoleBasedSettingsProvider(e)}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="providerRole">
                            {accountRoles.PI}
                        </label>
                    </div>
                    <div className="form-check col-md-12">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="pccRole"
                            {...register('is_pcc', {
                                required: false,
                            })}
                            onClick={e => setRoleBasedSettingsCareTeam(e)}
                        />
                        <label className="form-check-label" htmlFor="pccRole">
                            Patient Care Collaborator
                        </label>
                    </div>

                    {acmSettings && (
                        <>
                            <h6>Account Manager Settings</h6>

                            <div className="col-md-6">
                                <label htmlFor="isPrimaryAccount">
                                    Is primary
                                </label>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id="radio-isprimary-yes"
                                        value={true}
                                        {...register('is_primary')}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="radio-isprimary-yes">
                                        Yes
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id="radio-isprimary-no"
                                        value={false}
                                        {...register('is_primary')}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="radio-isprimary-no">
                                        No
                                    </label>
                                </div>
                            </div>
                        </>
                    )}

                    {providerSettings && (
                        <>
                            <h6>Provider Settings</h6>
                            <div className="col-md-12">
                                <label htmlFor="NpiID">NPI ID</label>
                                <input
                                    type="text"
                                    className={
                                        errors?.npi_id
                                            ? 'form-control is-invalid'
                                            : 'form-control'
                                    }
                                    id="NpiID"
                                    placeholder="Enter NPI ID"
                                    {...register('npi_id', {
                                        required: !!providerSettings,
                                        maxLength: {
                                            value: 255,
                                            message:
                                                'NPI ID can be max. 255 char length',
                                        },
                                    })}
                                />
                                {errors && (
                                    <div className="invalid-feedback">
                                        {errors.npi_id?.message}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="Clinics">Sites</label>
                                <Controller
                                    control={control}
                                    id="Clinics"
                                    name="clinic_ids"
                                    render={() => (
                                        <Multiselect
                                            className="col-md-6"
                                            displayValue="label"
                                            isObject={true}
                                            placeholder="Please select a site"
                                            disable={accountId ? false : true}
                                            key={accountId}
                                            options={clinics}
                                            onSelect={clinic => {
                                                onChangeClinic(clinic);
                                            }}
                                            onRemove={clinic => {
                                                onChangeClinic(clinic);
                                            }}
                                            required
                                        />
                                    )}
                                />
                                {errors && (
                                    <div className="invalid-feedback">
                                        {errors?.clinic_ids?.message}
                                    </div>
                                )}
                            </div>
                        </>
                    )}

                    {careTeamSettings && (
                        <>
                            <h6>Patient Care Collaborator Settings</h6>
                            <div className="col-md-12">
                                <label htmlFor="associatedClinics">
                                    Associated site
                                </label>
                                <Controller
                                    control={control}
                                    name="associatedClinics"
                                    render={({ field: { value, ref } }) => {
                                        return (
                                            <Select
                                                inputRef={ref}
                                                options={clinics}
                                                key={accountId}
                                                value={clinics.find(
                                                    c => c.label === value,
                                                )}
                                                onChange={clinic => {
                                                    getPccProviders(clinic);
                                                }}
                                                isDisabled={
                                                    accountId ? false : true
                                                }
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-md-12">
                                <label htmlFor="pccProvider">
                                    {accountRoles.PI}s
                                </label>
                                <Controller
                                    control={control}
                                    name="pccProvider"
                                    render={({ field: { ref } }) => {
                                        return (
                                            <Select
                                                inputRef={ref}
                                                key={clinicId}
                                                options={pccProviders}
                                                onChange={val => {
                                                    setPCCProvidersListing(
                                                        val,
                                                        clinicData,
                                                    );
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                            <div className="accounts-list-table">
                                <DataTable
                                    width="100%"
                                    columns={columns}
                                    data={carePersonsProviders}
                                />
                            </div>
                        </>
                    )}

                    <div className="col-md-12">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </AppModal>
    );
};

export default WithLogin(AddAccountUser);
