import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import { fetchPCCbyId } from '../../services/pcc.service';
import { manageClinicsList } from '../../services/pcc.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';

const ViewPCC = props => {
    const [message, setMessage] = useState({});
    const [pccData, setPccData] = useState({});
    const [show, setModalShow] = useState(props.open);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const handlePageChange = page => {
        setPage(page);
        getPCCs(page, perPage, props.selectedRow.account_person_id);
    };

    const getProviderNames = providers => {
        return providers.map(provider => {
            return `${provider.first_name} ${provider.last_name}`;
        });
    };
    const getPCCs = async (
        toPage,
        toPerPage,
        account_person_id = props.selectedRow.account_person_id,
    ) => {
        setLoading(true);
        toPage = toPage ? toPage : page;
        toPerPage = toPerPage ? toPerPage : perPage;
        try {
            setMessage({});
            const response = await manageClinicsList(
                toPage,
                toPerPage,
                account_person_id,
            );
            setData(response.items);
            setTotalRows(response.total);
            Promise.resolve();
        } catch (error) {
            setMessage({
                message: 'An error has occurrded during data loading.',
            });
            Promise.reject();
        }
        setLoading(false);
    };

    const handlePerRowsChange = async newPerPage => {
        setLoading(true);
        setMessage({});
        getPCCs(page, perPage, props.selectedRow.account_person_id);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const columns = [
        {
            name: 'Clinic',
            selector: row => row.clinic_name,
        },
        {
            name: 'Providers',
            cell: row => {
                const providerNames = getProviderNames(row.providers);
                return (
                    <div key={row.id}>
                        {providerNames.sort().map((name, key) => (
                            <>
                                <span key={key}>{name}</span>
                                {key !== providerNames.length - 1 && <span>, </span>}
                            </>
                        ))}
                    </div>
                );
            },
        },
    ];

    const getPCCDetails = async () => {
        try {
            const resp = await fetchPCCbyId(
                props.selectedRow.account_person_id,
            );
            setPccData(resp);
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };

    useEffect(() => {
        setModalShow(props.open);
        getPCCDetails();
        getPCCs(page, perPage, props.selectedRow.account_person_id);
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable();
        }
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle=" Patient Care Collaborator Details">
            <div className="mt-2">
                <div className="col-md-12">
                    <span className="txt-label">Name</span>
                    <span className="txt-label-value">
                        {pccData.first_name} {pccData.last_name}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Email</span>
                    <span className="txt-label-value">{pccData.email}</span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Gender</span>
                    <span className="txt-label-value">{pccData.gender}</span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Mobile Number</span>
                    <span className="txt-label-value">
                        {pccData.mobile_number}
                    </span>
                </div>
                <div>
                    {message && <MessageContainer message={message} />}
                    <div className="accounts-list-table">
                        <DataTable
                            width="100%"
                            columns={columns}
                            data={data}
                            progressPending={loading}
                            pagination
                            paginationServer
                            paginationDefaultPage={page}
                            paginationResetDefaultPage={true}
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </AppModal>
    );
};

export default WithLogin(ViewPCC);
