import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Admin from './user.component'


function AdminBase() {
    const { isLoggedIn } = useSelector(state => state.auth);
    if (!isLoggedIn) {
        return <Navigate to="/login/" />;
    }

    return (
        <>
            {/* <div className="col-md-12">
                <AccountSideBar />
            </div> */}
            <div className="col-md-12">
                <Routes>
                    <Route path="/" element={<Admin />} />
                </Routes>
            </div>
        </>
    );


}

export default AdminBase;