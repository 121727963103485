import React from 'react';
import { checkLogin } from '../app_store/actions/auth';

export default function WithLogin(Component) {
    function ComponentWithRouterProp(props) {
        const isLoggedIn = checkLogin();
        if (!isLoggedIn) {
            window.history.replaceState(null, '', '/login/');
            window.location.reload();
        }
        return <Component {...props} />;
    }
    return ComponentWithRouterProp;
}
