import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { clearSpinner, setSpinner } from '../../app_store/actions/spinner';
import { labOrderStatus } from '../../constants';
import { fetchExportLabOrders } from '../../services/labOrder.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';

const ViewExportLabOrderForm = props => {
    const [show, setModalShow] = useState(props.open);
    const [message, setMessage] = useState({});
    const [loading, setLoading] = useState(false);
    const partners = props.partnerList;
    const dispatch = useDispatch();

    useEffect(() => {
        setModalShow(props.open);
        setMessage({});
    }, [props.open]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
    };
    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {
            exportType: 'CSV',
            ...props.filter,
        },
    });

    useEffect(() => {
        setValue(
            'startDate',
            props.filter?.startDate
                ? moment(props.filter?.startDate).toDate()
                : null,
        );
        setValue(
            'endDate',
            props.filter?.endDate
                ? moment(props.filter?.endDate).toDate()
                : null,
        );
        console.log('props.filter>>', props.filter);
        Object.keys(props.filter).forEach(key => {
            if (key !== 'startDate' && key !== 'endDate') {
                console.log(key, props.filter[key]);
                setValue(key, props.filter[key]);
            }
        });
    }, [props.filter]); // eslint-disable-line react-hooks/exhaustive-deps

    const exportData = async data => {
        const exportLabOrderPayload = {
            startDate: moment(data.startDate).format('YYYY-MM-DD HH:mm'),
            endDate: moment(data.endDate).format('YYYY-MM-DD HH:mm'),
            searchText: data.searchText.trim(),
            partnerId: data.partnerId,
            accountId: props.filter.accountId,
            currentStatus: data.currentStatus,
            exportType: data.exportType,
        };
        dispatch(setSpinner());
        try {
            setLoading(true);
            const res = await fetchExportLabOrders(exportLabOrderPayload);
            dispatch(clearSpinner());
            await downloadExcel(res, data.exportType);
        } catch (error) {
            console.log(error);
            setMessage({
                message: 'Please select start and end date',
                type: 'Error',
            });
            dispatch(clearSpinner());
        } //end catch
        setLoading(false);
    };

    const downloadExcel = async (resp, exportType) => {
        try {
            const blob = new Blob([resp], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('href', url);
            if (exportType === 'EXCEL') {
                a.setAttribute('download', 'lab_order.xlsx');
            } else if (exportType === 'CSV') {
                a.setAttribute('download', 'lab_order.csv');
            }

            a.click();
        } catch (error) {
            setMessage({
                message: 'Error in downloading excel file',
                type: 'Error',
            });
        }
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Lab Order Export">
            <div style={{ minHeight: '350px' }}>
                {message && <MessageContainer message={message} />}
                <form className="row g-2" onSubmit={handleSubmit(exportData)}>
                    <div className="col-md-6">
                        <label htmlFor="partnerId">Partner:</label>
                        <select
                            className="form-control"
                            id="partnerId"
                            {...register('partnerId')}>
                            <option value="" key="0">
                                All
                            </option>
                            {partners.map(partner => (
                                <option value={partner.id} key={partner.id}>
                                    {partner.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="searchText">Search by sample :</label>
                        <input
                            type="text"
                            className="form-control"
                            id="searchText"
                            placeholder="Enter kit sample id"
                            {...register('searchText')}
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="currentStatus">Status:</label>
                        <select
                            className="form-control"
                            id="currentStatus"
                            {...register('currentStatus')}>
                            <option value="" key="0">
                                All
                            </option>
                            {Object.keys(labOrderStatus).map(status => (
                                <option
                                    value={labOrderStatus[status]}
                                    key={status}>
                                    {labOrderStatus[status]}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="startDate">Start Date</label>
                        <Controller
                            control={control}
                            id="startDate"
                            name="startDate"
                            render={({ field }) => (
                                <DatePicker
                                    selected={field.value}
                                    maxDate={new Date()}
                                    onChange={e => setValue('startDate', e)}
                                    onSelect={e => setValue('startDate', e)}
                                    dateFormat="MMMM d, yyyy"
                                    placeholder="Select a date"
                                    required={true}
                                />
                            )}
                        />
                        {errors && (
                            <div className="text-danger">
                                {errors.dob?.message}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="endDate">End Date</label>
                        <Controller
                            control={control}
                            id="endDate"
                            name="endDate"
                            render={({ field }) => (
                                <DatePicker
                                    selected={field.value}
                                    maxDate={new Date()}
                                    onChange={e => setValue('endDate', e)}
                                    onSelect={e => setValue('endDate', e)}
                                    dateFormat="MMMM d, yyyy"
                                    placeholder="Select a date"
                                    required={true}
                                />
                            )}
                        />
                        {errors && (
                            <div className="text-danger">
                                {errors.dob?.message}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="exportType">Export type</label>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="radio-exportType-excel"
                                value={'EXCEL'}
                                {...register('exportType')}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="radio-exportType-excel">
                                Excel
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="radio-exportType-csv"
                                value={'CSV'}
                                {...register('exportType')}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="radio-exportType-csv">
                                CSV
                            </label>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </AppModal>
    );
};
export default WithLogin(ViewExportLabOrderForm);
