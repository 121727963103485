import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { accountRoles } from '../../constants';
import { fetchAccountList } from '../../services/account.service';
import {
    deleteAccountUser,
    fetchAccountUsers,
} from '../../services/accountUser.service';
import { customTableStyles } from '../../utils';
import NotifyAccount from '../account/notifyAccountManager.component';
import AccountManagerList from '../accountManager/accountManagerList.component';
import DeleteConfirmation from '../deleteConfirmation.component';
import MessageContainer from '../message.component';
import NotifyConfirmation from '../notifyConfirmation.component';
import PCCListComponent from '../pcc/pccList.component';
import ProviderList from '../provider/providerList.component';
import WithLogin from '../withLogin.component';
import AddAccountUser from './accountUserAdd.component';
import EditAccountUser from './accountUserEdit.component';
import ViewAccountUser from './accountUserView.component';

const AccountUserList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [message, setMessage] = useState({});
    const [openAddForm, setOpenAddForm] = useState(false);
    const [accountList, setAccountList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [userType, setUserType] = useState('All Users');
    const [accountId, setAccountId] = useState();
    const [searchText, setSearchText] = useState();
    const [openEditForm, setOpenEditForm] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [openViewForm, setOpenViewForm] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showNotifyConfirmation, setShowNotifyConfirmation] = useState(false);
    const [openNotifyForm, setOpenNotifyForm] = useState(false);
    const [accountPersonId, setAccountPersonId] = useState(false);
    const [reloadData, setReloadData] = useState(false);

    const { register, handleSubmit, reset } = useForm({
        defaultValues: {},
    });

    const DeleteAccountUser = async deleteID => {
        setMessage({});
        try {
            const resp = await deleteAccountUser(deleteID);
            await getAccountUsers();
            setMessage({
                type: 'Success',
                message: `${resp.detail}`,
            });
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
        setShowDeleteConfirmation(false);
    };

    const onDelete = async row => {
        setShowDeleteConfirmation(true);
        setSelectedRow(row);
    };

    const onEdit = row => {
        setSelectedRow(row);
        setOpenEditForm(true);
    };

    const onView = row => {
        setOpenViewForm(true);
        setSelectedRow(row);
    };

    const onNotify = row => {
        setAccountPersonId(row.id);
        setShowNotifyConfirmation(true);
        setSelectedRow(row);
    };

    const notifyAccountUser = row => {
        setOpenNotifyForm(true);
        setSelectedRow(row);
    };

    const columns = [
        {
            name: 'id',
            selector: row => row.id,
            width: '10%',
        },
        {
            name: 'Account',
            selector: row => row.account.name,
        },
        {
            name: 'Name',
            selector: row => `${row.first_name} ${row.last_name}`,
        },
        {
            name: 'Email',
            selector: row => row.email,
            width: '20%',
        },

        {
            key: 'action',
            text: 'Action',
            name: 'Action',
            className: 'action',
            width: '130px',
            align: 'left',
            sortable: false,
            cell: row => {
                return (
                    <Fragment>
                        <span className="table-action-icon">
                            <EditIcon
                                className="icon-color"
                                onClick={() => {
                                    onEdit(row);
                                }}
                            />
                        </span>
                        &nbsp;
                        <VisibilityIcon
                            className="icon-color"
                            onClick={() => {
                                onView(row);
                            }}
                        />
                        &nbsp;
                        {!row?.account_manager?.is_primary && (
                            <DeleteIcon
                                color="red"
                                className="table-action-icon red"
                                onClick={() => onDelete(row)}
                            />
                        )}
                        &nbsp;
                        {!row.is_activated_by_user && (
                            <Link
                                onClick={() => {
                                    onNotify(row);
                                }}
                                className="table-action-icon">
                                <NotificationsNoneIcon className="icon-color" />
                            </Link>
                        )}
                    </Fragment>
                );
            },
        },
    ];

    const getAccountUsers = async (
        toPage,
        toPerPage,
        searchText,
        accountId,
    ) => {
        setLoading(true);
        toPage = toPage ? toPage : page;
        toPerPage = toPerPage ? toPerPage : perPage;
        try {
            setMessage({});
            const response = await fetchAccountUsers(
                toPage,
                toPerPage,
                searchText,
                accountId,
            );
            setData(response.items);
            setTotalRows(response.total);
            Promise.resolve();
        } catch (error) {
            setMessage({
                message: 'An error has occurrded during data loading.',
            });
            Promise.reject();
        }
        setLoading(false);
    };

    const getAccountList = async () => {
        setLoading(true);
        try {
            const accounts = await fetchAccountList();
            setAccountList(accounts);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during account list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const setSearchFilters = async data => {
        setSearchText(data.searchText.trim());
        setAccountId(data.accountId);
        getAccountUsers(
            page,
            perPage,
            data.searchText ? data.searchText.trim() : null,
            data.accountId ? data.accountId : null,
        );
    };

    const getRoleList = async () => {
        setLoading(true);
        try {
            const roles = [
                { name: accountRoles.ACM },
                { name: accountRoles.PI },
                { name: accountRoles.PCC },
            ];
            setRoleList(roles);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during account list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const setResetAction = () => {
        reset();
        setAccountId();
        setSearchText();
        getAccountUsers(page, perPage, data.accountId);
    };

    useEffect(() => {
        getAccountList();
        getRoleList();
        getAccountUsers();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getAccountUsers(page, perPage, searchText, accountId);
    }, [userType, reloadData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = page => {
        setPage(page);
        getAccountUsers(page);
    };
    const reloadDataTable = (toPage, perPage) => {
        getAccountUsers(toPage, perPage, searchText, accountId);
        if (toPage) {
            setPage(toPage);
        }
    };

    const handlePerRowsChange = async (newPerPage, toPage) => {
        setLoading(true);
        setMessage({});
        await getAccountUsers(toPage, newPerPage);
        setPerPage(newPerPage);
        setLoading(false);
    };

    return (
        <>
            {openAddForm && (
                <AddAccountUser
                    open={openAddForm}
                    setAddForm={setOpenAddForm}
                    setReloadData={setReloadData}
                    reloadData={reloadData}
                />
            )}
            {openNotifyForm && (
                <NotifyAccount
                    open={openNotifyForm}
                    setNotifyForm={setOpenNotifyForm}
                    accountPersonId={accountPersonId}
                    reloadDataTable={reloadDataTable}
                />
            )}
            <div>
                <div className="pageHeader">
                    <span id="header">
                        <h4>Users</h4>
                    </span>
                    <span id="header-add-btn">
                        <button
                            className="btn btn-primary"
                            onClick={() => setOpenAddForm(true)}>
                            + Add User
                        </button>
                    </span>
                </div>
                <hr />
                <div>
                    <h6>User Type</h6>
                    <div>
                        {[{ name: 'All Users', id: 0 }, ...roleList].map(
                            (role, i) => (
                                <span key={i} style={{ margin: '5px' }}>
                                    <input
                                        type="radio"
                                        name="usertype"
                                        id={role.name}
                                        value={role.name}
                                        checked={userType === role.name}
                                        onChange={e => {
                                            let value = e.target.value;
                                            setUserType(value);
                                        }}
                                    />{' '}
                                    <label
                                        htmlFor={role.name}
                                        style={{ display: 'inline' }}>
                                        {role.name}
                                    </label>{' '}
                                </span>
                            ),
                        )}
                    </div>
                </div>
                <div className="new-line" />

                <div>
                    <form
                        className="row g-2"
                        onSubmit={handleSubmit(setSearchFilters)}>
                        <div className="col-md-3">
                            <input
                                type="text"
                                className="form-control"
                                id="search"
                                placeholder={
                                    userType === accountRoles.PROVIDER
                                        ? 'Enter name or npi id'
                                        : 'Enter name or email'
                                }
                                {...register('searchText')}
                            />
                        </div>
                        <div className="col-md-3">
                            <select
                                className="form-control"
                                id="accountId"
                                {...register('accountId')}>
                                <option value="" key="0">
                                    Select an account filter
                                </option>
                                {accountList.map(account => (
                                    <option value={account.id} key={account.id}>
                                        {account.name}(id: {account.id})
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-3">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                Search
                            </button>
                            <span className="mx-1"></span>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={loading}
                                onClick={() => {
                                    setResetAction();
                                }}>
                                Reset
                            </button>
                        </div>
                    </form>
                </div>
                {openViewForm && (
                    <ViewAccountUser
                        open={openViewForm}
                        setEditForm={setOpenViewForm}
                        selectedRow={selectedRow}
                        reloadDataTable={reloadDataTable}
                    />
                )}
                {openEditForm && userType === 'All Users' && (
                    <EditAccountUser
                        open={openEditForm}
                        setEditForm={setOpenEditForm}
                        selectedRow={selectedRow}
                        reloadDataTable={reloadDataTable}
                        accountPersonId={selectedRow.id}
                    />
                )}
                {message && <MessageContainer message={message} />}
                {userType === 'All Users' && (
                    <>
                        <div className="new-line"></div>
                        <div className="accounts-list-table">
                            <DataTable
                                width="100%"
                                columns={columns}
                                customStyles={customTableStyles}
                                data={data}
                                progressPending={loading}
                                pagination
                                paginationServer
                                paginationDefaultPage={page}
                                paginationResetDefaultPage={true}
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                            />
                        </div>
                        <DeleteConfirmation
                            showModal={showDeleteConfirmation}
                            hideModal={() => setShowDeleteConfirmation(false)}
                            confirmModal={DeleteAccountUser}
                            deleteId={selectedRow.id}
                            message="Are you sure you want to delete it"
                        />
                    </>
                )}
                {userType === accountRoles.ACM && (
                    <AccountManagerList
                        accountId={accountId}
                        acmSearchText={searchText}
                        reloadData={reloadData}
                    />
                )}
                {userType === accountRoles.PI && (
                    <ProviderList
                        accountId={accountId}
                        providerSearchText={searchText}
                        reloadData={reloadData}
                    />
                )}
                {userType === accountRoles.PCC && (
                    <PCCListComponent
                        accountId={accountId}
                        pccSearchText={searchText}
                        reloadData={reloadData}
                    />
                )}
                {showNotifyConfirmation && (
                    <NotifyConfirmation
                        showModal={showNotifyConfirmation}
                        hideModal={() => setShowNotifyConfirmation(false)}
                        confirmModal={notifyAccountUser}
                        notifyAccountId={selectedRow}
                        buttonName="Send"
                        message={`Do you want to resend welcome email with login credential to ${selectedRow.first_name} ${selectedRow.last_name} (${selectedRow.email})`}
                    />
                )}
            </div>
        </>
    );
};

export default WithLogin(AccountUserList);
