import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import WithLogin from '../withLogin.component';
import DeleteConfirmation from '../deleteConfirmation.component';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchAdminList, deleteAdminById  } from '../../services/admin.service';
import EditUser from './userEdit.component';
import ViewUser from './userView.component';
import AddUser from './userAdd.component';

import { customTableStyles, dateTimeFormat } from '../../utils';
import MessageContainer from '../message.component';
import DataTable from 'react-data-table-component';

const User = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [openAddForm, setOpenAddForm] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [openViewForm, setOpenViewForm] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [ showDeleteConfirmation, setShowDeleteConfirmation ] = useState( false );
    const [message, setMessage] = useState({});

    const [adminSearchFilters, setAdminSearchFilters] = useState({
        searchText: '',
        adminId: '',
    });

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            width: '10%',
        },
        {
            name: 'Name',
            selector: row => `${row.first_name} ${row.last_name}`,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Mobile Number',
            selector: row => row.mobile_number,
        },
        {
            name: 'Role',
            selector: row => row.role_name,
        },
        {
            name: 'Status',
            selector: row => (row.is_active ? 'Active' : 'Inactive'),
        },
        {
            name: 'Created at',
            selector: row => dateTimeFormat(row.created_at),
        },
        {
            key: 'action',
            text: 'Action',
            name: 'Action',
            className: 'action',
            width: '100px',
            align: 'left',
            sortable: false,
            cell: row => {
                return (
                    <Fragment>
                        <span className="table-action-icon">
                            <EditIcon
                                className="icon-color"
                                onClick={() => {
                                    onEdit(row);
                                }}
                            />
                        </span>
                        &nbsp;
                        <span className="table-action-icon">
                            <VisibilityIcon className="icon-color"
                                onClick={() => {
                                    onView(row);
                                }}
                            />
                        </span>
                        &nbsp;
                        <DeleteIcon
                            color="red"
                            className="table-action-icon red"
                            onClick={ () => onDelete( row ) }
                        />
                    </Fragment>
                );
            },
        },
    ];

    const onEdit = row => {
        setSelectedRow(row);
        setOpenEditForm(true);
    };

    const onView = row => {
        setOpenViewForm(true);
        setSelectedRow(row);
    };

    const onDelete = async row =>
    {
        setSelectedRow( row );
        setShowDeleteConfirmation( true );
    };

    const handleOpenAddForm = () => {
        setOpenAddForm(true);
    };

    const getAdmins = async (toPage, toPerPage) => {
        setLoading(true);
        setMessage({});
        toPage = toPage ? toPage : page;
        toPerPage = toPerPage ? toPerPage : perPage;
        try {
            const admins = await fetchAdminList(
                toPage,
                toPerPage,
                adminSearchFilters,
            );
            setData(admins.items);
            setTotalRows(admins.total);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during data loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        getAdmins(1);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const deleteAdminUser = async id =>
    {
        setMessage( {} );
        try
        {
            const resp = await deleteAdminById( id );
            await getAdmins();
            setMessage( {
                type: 'Success',
                message: `${ resp.detail }`,
            } );
        } catch ( error )
        {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            )
            {
                setMessage( {
                    type: 'Error',
                    message: `${ error.response.data.detail }`,
                } );
            }
        }
        setShowDeleteConfirmation( false );
    };

    const handlePageChange = toPage => {
        getAdmins(toPage);
        setPage(toPage);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        await getAdmins(page, newPerPage);
        setPerPage(newPerPage);
    };

    const { register, handleSubmit, reset } = useForm({
        defaultValues: {},
    });

    useEffect(() => {
        getAdmins(1);
        setPage(1);
    }, [adminSearchFilters]); // eslint-disable-line react-hooks/exhaustive-deps


    const setSearchFilters = async data => {
        setAdminSearchFilters({
            searchText: data.searchText.trim(),
            accountId: data.accountId,
        });
    };

    const reloadDataTable = toPage => {
        getAdmins(toPage);
        if (toPage) {
            setPage(toPage);
        }
    };

    return (
        <>
            {openAddForm && (
                <AddUser
                    open={openAddForm}
                    setOpenAddForm={setOpenAddForm}
                    reloadDataTable={reloadDataTable}
                />
            )}
            {openEditForm && (
                <EditUser
                    open={openEditForm}
                    setEditForm={setOpenEditForm}
                    selectedRow={selectedRow}
                    reloadDataTable={reloadDataTable}
                />
            )}
            {openViewForm && (
                <ViewUser
                    open={openViewForm}
                    setEditForm={setOpenViewForm}
                    selectedRow={selectedRow}
                    reloadDataTable={reloadDataTable}
                />
            )}
            <div>
                <div className="pageHeader">
                    <span id="header">
                        <h4>Admin/Moderator users</h4>
                    </span>
                    <span id="header-add-btn">
                        <button
                            className="btn btn-primary"
                            onClick={handleOpenAddForm}>
                            + Add Admin
                        </button>
                    </span>
                </div>

                <hr />

                {message && <MessageContainer message={message} />}
                <form className="row g-2" onSubmit={handleSubmit(setSearchFilters)}>
                    <div className="col-md-3">
                        <input
                            type="text"
                            className="form-control"
                            id="search"
                            placeholder="Enter admin's ID or name"
                            {...register('searchText')}
                        />
                    </div>
                    <div className="col-md-9">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Search
                        </button>
                        <span className="mx-1"></span>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                        onClick={() => {
                            reset();
                        }}>
                        Reset
                    </button>
                    </div>
                </form>
                <div className="new-line"></div>
                <div className="admin-list-table">
                    <DataTable
                        width="100%"
                        customStyles={customTableStyles}
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationDefaultPage={page}
                        paginationResetDefaultPage={true}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                    <DeleteConfirmation
                        showModal={ showDeleteConfirmation }
                        hideModal={ () => setShowDeleteConfirmation( false ) }
                        confirmModal={ deleteAdminUser }
                        deleteId={ selectedRow.id }
                        message="Are you sure you want to delete it"
                    />
                </div>
            </div>
        </>
    )
}

export default WithLogin(User);