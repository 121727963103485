import api from './api';
import { API_URL } from './base';

export const fetchAccounts = (page, perPage, searchText) => {
    let url = `${API_URL}/admin/accounts/?page=${page}&size=${perPage}`;
    if (searchText) {
        url = `${url}&q=${searchText}`;
    }
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const fetchAccountList = () => {
    let url = `${API_URL}/admin/account-list/`;
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const notifyAccountManager = id => {
    return api.get(`${API_URL}/admin/accounts/${id}/notify/`).then(resp => {
        return resp.data;
    });
};

export const notifyAccountUser = account_person_id => {
    return api.get(`${API_URL}/admin/accounts/user/${account_person_id}/notify/`).then(resp => {
        return resp.data;
    });
};

export const addAccount = data => {
    return api.post(`${API_URL}/admin/accounts/`, data).then(resp => {
        return resp.data;
    });
};

export const getAccountById = id => {
    return api.get(`${API_URL}/admin/accounts/${id}/`).then(resp => {
        return resp.data;
    });
};

export const updateAccount = (id, data) => {
    return api.put(`${API_URL}/admin/accounts/${id}/`, data).then(resp => {
        console.log(resp);
        return resp.data;
    });
};

export const fetchAccountManagers = (
    page,
    perPage,
    searchText,
    accountId = 0,
) => {
    let url = `${API_URL}/admin/account-managers/?page=${page}&size=${perPage}`;
    if (accountId) {
        url = `${url}&account_id=${accountId}`;
    }
    if (searchText) {
        url = `${url}&q=${searchText}`;
    }
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const fetchAccount = id => {
    const url = `${API_URL}/admin/accounts/${id}/`;
    return api.get(url).then(resp => {
        return resp.data;
    });
};
