import React, { useEffect, useState } from 'react';

import { fetchPatientById } from '../../services/patient.service';
import { dateFormat, dateTimeFormat } from '../../utils';
import AppModal from '../appModal.component';
import WithLogin from '../withLogin.component';

const ViewPatient = props => {
    const [message, setMessage] = useState({});
    const [patient, setPatient] = useState({});
    const [show, setModalShow] = useState(props.open);

    const getPatients = async () => {
        try {
            const resp = await fetchPatientById(
                props.accountID,
                props.selectedRow.id,
            );
            setPatient(resp);
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };

    useEffect(() => {
        setModalShow(props.open);
        getPatients();
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable();
        }
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Patient Detail">
            <div className="mt-2">
                <div className="col-md-12">
                    <span className="txt-label">ID</span>
                    <span className="txt-label-value">{patient.id}</span>
                </div>

                <div className="col-md-12">
                    <span className="txt-label">Subject ID</span>
                    <span className="txt-label-value">
                        {patient.subject_id}
                    </span>
                </div>

                <div className="col-md-12">
                    <span className="txt-label">Sex</span>
                    <span className="txt-label-value">{patient.sex}</span>
                </div>

                <div className="col-md-12">
                    <span className="txt-label">DOB</span>
                    <span className="txt-label-value">
                        {dateFormat(patient.dob)}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Ethnicity</span>
                    <span className="txt-label-value">{patient.ethnicity}</span>
                </div>

                <div className="col-md-12">
                    <span className="txt-label">Created at</span>
                    <span className="txt-label-value">
                        {dateTimeFormat(patient.created_at)}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Updated at</span>
                    <span className="txt-label-value">
                        {dateTimeFormat(patient.updated_at)}
                    </span>
                </div>
            </div>
        </AppModal>
    );
};

export default WithLogin(ViewPatient);
