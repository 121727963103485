import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';

import { fetchAccountList } from '../../services/account.service';
import { fetchPatients } from '../../services/patient.service';
import {
    customTableStyles,
    customTableStylesAccountView,
    dateFormat,
    dateTimeFormat,
} from '../../utils';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';
import AddPatient from './patientAdd.component';
import EditPatient from './patientEdit.component';
import ViewPatient from './patientView.component';

const PatientList = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openAddForm, setopenAddForm] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [openViewForm, setOpenViewForm] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [accountList, setAccountList] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [message, setMessage] = useState({});
    const showFilters = props.showFilters == null ? true : false;

    const { register, handleSubmit, reset } = useForm({
        defaultValues: {},
    });

    const setResetAction = async () => {
        await getPatients();
        reset();
    };

    const setSearchFilters = async data => {
        getPatients(page, perPage, data.searchText.trim(), data.accountId);
    };

    const getAccountList = async () => {
        setLoading(true);
        try {
            const accounts = await fetchAccountList();
            setAccountList(accounts);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during account list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const columns = [
        {
            name: 'id',
            selector: row => row.id,
            width: '10%',
        },
        {
            name: 'Account',
            selector: row => row.account_name,
        },
        {
            name: 'Subject ID',
            selector: row => row.subject_id,
        },
        {
            name: 'DOB',
            selector: row => dateFormat(row.dob),
        },
        {
            name: 'Sex assigned at birth',
            selector: row => row.sex,
        },
        {
            name: 'Ethnicity',
            selector: row => row.ethnicity,
        },

        {
            name: 'Created at',
            selector: row => dateTimeFormat(row.created_at),
        },
        {
            name: 'Updated at',
            selector: row => dateTimeFormat(row.created_at),
        },
        {
            key: 'action',
            text: 'Action',
            name: 'Action',
            className: 'action',
            width: '75px',
            align: 'left',
            sortable: false,
            cell: row => {
                return (
                    <Fragment>
                        <span className="table-action-icon">
                            <EditIcon
                                className="icon-color"
                                onClick={() => {
                                    onEdit(row);
                                }}
                            />
                        </span>
                        &nbsp;
                        <VisibilityIcon
                            className="icon-color"
                            onClick={() => {
                                onView(row);
                            }}
                        />
                    </Fragment>
                );
            },
        },
    ];

    const onEdit = row => {
        setOpenEditForm(true);
        setSelectedRow(row);
    };

    const onView = row => {
        setOpenViewForm(true);
        setSelectedRow(row);
    };

    const handleOpenAddForm = () => {
        setopenAddForm(true);
    };

    const getPatients = async (toPage, toPerPage, searchText, accountId) => {
        setLoading(true);
        setMessage({});
        toPage = toPage ? toPage : page;
        toPerPage = toPerPage ? toPerPage : perPage;
        accountId = (accountId === '') | !accountId ? 0 : accountId;
        try {
            const response = await fetchPatients(
                toPage,
                toPerPage,
                searchText,
                accountId,
            );
            setData(response.items);
            setTotalRows(response.total);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during data loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        getPatients(page, perPage, props.pccSearchText, props.accountId);
        getAccountList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = toPage => {
        getPatients(toPage);
        setPage(toPage);
    };

    const reloadDataTable = toPage => {
        toPage = toPage ? toPage : page;
        props.accountId == null
            ? getPatients(toPage)
            : getPatients(page, perPage, '', props.accountId);
        if (toPage) {
            setPage(toPage);
        }
    };

    const handlePerRowsChange = async (newPerPage, toPage) => {
        setLoading(true);
        setMessage({});
        getPatients(toPage, newPerPage);
        setPerPage(newPerPage);

        setLoading(false);
    };

    return (
        <>
            {openAddForm && (
                <AddPatient
                    open={openAddForm}
                    setopenAddForm={setopenAddForm}
                    reloadDataTable={reloadDataTable}
                    accountId={props.accountId}
                />
            )}
            {openEditForm && (
                <EditPatient
                    open={openEditForm}
                    setEditForm={setOpenEditForm}
                    selectedRow={selectedRow}
                    reloadDataTable={reloadDataTable}
                />
            )}
            {openViewForm && (
                <ViewPatient
                    open={openViewForm}
                    setEditForm={setOpenViewForm}
                    selectedRow={selectedRow}
                    reloadDataTable={reloadDataTable}
                    accountId={props.accountId}
                />
            )}

            <div>
                {showFilters && (
                    <>
                        <div className="pageHeader">
                            <span id="header">
                                <h4>Patients</h4>
                            </span>
                            <span id="header-add-btn">
                                <button
                                    className="btn btn-primary"
                                    onClick={handleOpenAddForm}>
                                    + Add Patients
                                </button>
                            </span>
                        </div>
                        <hr />
                        <form
                            className="row g-2"
                            onSubmit={handleSubmit(setSearchFilters)}>
                            <div className="col-md-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="search"
                                    placeholder={'Enter name or email'}
                                    {...register('searchText')}
                                />
                            </div>
                            <div className="col-md-3">
                                <select
                                    className="form-control"
                                    id="accountId"
                                    {...register('accountId')}>
                                    <option value="" key="0">
                                        Select an account filter
                                    </option>
                                    {accountList.map(account => (
                                        <option
                                            value={account.id}
                                            key={account.id}>
                                            {account.name}(id: {account.id})
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-3">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={loading}>
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    Search
                                </button>
                                <span className="mx-1"></span>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={loading}
                                    onClick={() => {
                                        setResetAction();
                                    }}>
                                    Reset
                                </button>
                            </div>
                        </form>
                    </>
                )}
                <span
                    color="primary"
                    className="float-end"
                    style={{ marginRight: '10px' }}
                    onClick={() => reloadDataTable()}>
                    <SyncIcon />
                </span>
                <div className="new-line"></div>
                {message && <MessageContainer message={message} />}
                <div className="accounts-list-table">
                    <DataTable
                        width="100%"
                        customStyles={
                            props.accountId
                                ? customTableStylesAccountView
                                : customTableStyles
                        }
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationDefaultPage={page}
                        paginationResetDefaultPage={true}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </div>
            </div>
        </>
    );
};

export default WithLogin(PatientList);
