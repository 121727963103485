import { API_URL } from './base';
import api from './api';

// list
export const fetchAdminList = (page, perPage, adminSearchFilters) => {
    let url = `${API_URL}/admin/users/?page=${page}&size=${perPage}`;
    if (adminSearchFilters.adminId) {
        url = `${url}&q=${adminSearchFilters.adminId}`;
    }
    if (adminSearchFilters.searchText) {
        url = `${url}&q=${adminSearchFilters.searchText}`;
    }
    return api.get(url).then(resp => {
        return resp.data;
    });
};

// view
export const fetchAdminById = id => {
    return api.get(`${API_URL}/admin/users/${id}/`).then(resp => {
        return resp.data;
    });
};

// edit
export const updateAdmin = (data, id) => {
    return api
        .put(`${API_URL}/admin/users/${id}/`, data)
        .then(resp => {
            return resp.data;
        });
};

//add
export const addAdmin = (data) => {
    return api
        .post(`${API_URL}/admin/users/`, data)
        .then(resp => {
            return resp.data;
        });
};

// delete a user
export const deleteAdminById = id =>
{
    return api.delete( `${ API_URL }/admin/users/${ id }/` ).then( resp =>
    {
        return resp.data;
    } );
};

//reset password
export const resetPassword = id =>
{
    return api.put( `${ API_URL }/admin/users/${ id }/reset-password/` ).then( resp =>
    {
        return resp.data;
    } );
};
