import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';

import { accountRoles } from '../../constants';
import { fetchAccountList } from '../../services/account.service';
import { deleteClinicById, fetchClinics } from '../../services/clinic.service';
import {
    customTableStyles,
    customTableStylesAccountView,
    dateTimeFormat,
    formatAddress,
} from '../../utils';
import DeleteConfirmation from '../deleteConfirmation.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';
import AddClinic from './clinicAdd.component';
import EditClinic from './clinicEdit.component';
import ViewClinic from './clinicView.component';

const ClinicList = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [openViewForm, setOpenViewForm] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [page, setPage] = useState(1);
    const [openAddForm, setopenAddForm] = useState(false);
    const [accountList, setAccountList] = useState([]);
    const [message, setMessage] = useState({});
    const [searchText, setSearchText] = useState({});
    const [accountId, setAccountId] = useState({});

    const showFilters = props.showFilters == null ? true : false;

    const setSearchFilters = async data => {
        getClinics(page, perPage, data.searchText.trim(), data.accountId);
    };

    const setResetAction = async () => {
        props.accountId == null
            ? getClinics()
            : getClinics(page, perPage, '', props.accountId);
        reset();
    };

    const columns = [
        {
            name: 'id',
            selector: row => row.clinic_details.id,
            width: '10%',
        },
        {
            name: 'Account',
            selector: row => row.account_name,
        },
        {
            name: 'Name',
            selector: row => row.clinic_details.name,
        },
        {
            name: 'Address',
            selector: row => formatAddress(row.clinic_details.address),
        },
        {
            name: `${accountRoles.PI}s`,
            selector: row => row.provider_count,
        },
        {
            name: 'Created at',
            selector: row => dateTimeFormat(row.clinic_details.created_at),
        },
        {
            key: 'action',
            text: 'Action',
            name: 'Action',
            className: 'action',
            width: '100px',
            align: 'left',
            sortable: false,
            cell: row => {
                return (
                    <Fragment>
                        <span className="table-action-icon">
                            <EditIcon
                                className="icon-color"
                                onClick={() => {
                                    onEdit(row);
                                }}
                            />
                        </span>
                        &nbsp;
                        <VisibilityIcon
                            className="icon-color"
                            onClick={() => {
                                onView(row);
                            }}
                        />
                        <DeleteIcon
                            color="red"
                            className="table-action-icon red"
                            onClick={() => onDelete(row)}
                        />
                    </Fragment>
                );
            },
        },
    ];

    const onEdit = row => {
        setOpenEditForm(true);
        setSelectedRow(row);
    };

    const getAccountList = async () => {
        setLoading(true);
        try {
            const accounts = await fetchAccountList();
            setAccountList(accounts);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during account list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const onView = row => {
        setOpenViewForm(true);
        setSelectedRow(row);
    };

    const onDelete = async row => {
        setShowDeleteConfirmation(true);
        setSelectedRow(row);
    };

    const handleOpenAddForm = () => {
        setopenAddForm(true);
    };

    const { register, handleSubmit, reset } = useForm({
        defaultValues: {},
    });

    const getClinics = async (toPage, toPerPage, searchText, accountId) => {
        setLoading(true);
        setMessage({});
        toPage = toPage ? toPage : page;
        toPerPage = toPerPage ? toPerPage : perPage;
        accountId = (accountId === '') | !accountId ? 0 : accountId;
        try {
            const response = await fetchClinics(
                toPage,
                toPerPage,
                searchText,
                accountId,
            );
            setData(response.items);
            setTotalRows(response.total);
            setSearchText(searchText);
            setAccountId(accountId);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during data loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        props.accountId == null
            ? getClinics()
            : getClinics(page, perPage, '', props.accountId);
        getAccountList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const deleteClinic = async deleteID => {
        setMessage({});
        try {
            const resp = await deleteClinicById(deleteID.clinic_details.id);
            reloadDataTable(page);
            setMessage({
                type: 'Success',
                message: `${resp.detail}`,
            });
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
        setShowDeleteConfirmation(false);
    };

    const handlePageChange = toPage => {
        getClinics(page);
        setPage(toPage);
    };

    const handlePerRowsChange = async (newPerPage, toPage) => {
        setLoading(true);
        setMessage({});
        await getClinics(toPage, newPerPage);
        setPerPage(newPerPage);
        setLoading(false);
    };
    const reloadDataTable = (toPage, newPerPage) => {
        getClinics(toPage, newPerPage, searchText, accountId);
        if (toPage) {
            setPage(toPage);
        }
    };

    return (
        <>
            {openAddForm && (
                <AddClinic
                    open={openAddForm}
                    setopenAddForm={setopenAddForm}
                    reloadDataTable={reloadDataTable}
                    reloadClinicListTable={getClinics}
                />
            )}
            {openEditForm && (
                <EditClinic
                    open={openEditForm}
                    setEditForm={setOpenEditForm}
                    selectedRow={selectedRow}
                    reloadDataTable={reloadDataTable}
                />
            )}
            {openViewForm && (
                <ViewClinic
                    open={openViewForm}
                    setEditForm={setOpenViewForm}
                    selectedRow={selectedRow}
                    reloadDataTable={reloadDataTable}
                />
            )}
            <div>
                {showFilters && (
                    <>
                        <div className="pageHeader">
                            <span id="header">
                                <h4>Sites</h4>
                            </span>
                            <span id="header-add-btn">
                                <button
                                    className="btn btn-primary"
                                    onClick={handleOpenAddForm}>
                                    + Add Site
                                </button>
                            </span>
                        </div>
                        <hr />
                        <div>
                            <form
                                className="row g-2"
                                onSubmit={handleSubmit(setSearchFilters)}>
                                <div className="col-md-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="search"
                                        placeholder={'Enter site ID'}
                                        {...register('searchText')}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <select
                                        className="form-control"
                                        id="accountId"
                                        {...register('accountId')}>
                                        <option value="" key="0">
                                            Select an account filter
                                        </option>
                                        {accountList.map(account => (
                                            <option
                                                value={account.id}
                                                key={account.id}>
                                                {account.name}(id: {account.id})
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-md-3">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={loading}>
                                        {loading && (
                                            <span className="spinner-border spinner-border-sm"></span>
                                        )}
                                        Search
                                    </button>
                                    <span className="mx-1"></span>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={loading}
                                        onClick={() => {
                                            setResetAction();
                                        }}>
                                        Reset
                                    </button>
                                </div>
                            </form>
                        </div>
                    </>
                )}
                <span
                    color="primary"
                    className="float-end"
                    style={{ marginRight: '10px' }}
                    onClick={() => reloadDataTable()}>
                    <SyncIcon />
                </span>

                <div className="new-line"></div>
                {message && <MessageContainer message={message} />}
                <div className="accounts-list-table">
                    <DataTable
                        width="100%"
                        customStyles={
                            props.accountId
                                ? customTableStylesAccountView
                                : customTableStyles
                        }
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationDefaultPage={page}
                        paginationResetDefaultPage={true}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                    <DeleteConfirmation
                        showModal={showDeleteConfirmation}
                        hideModal={() => setShowDeleteConfirmation(false)}
                        confirmModal={deleteClinic}
                        deleteId={selectedRow}
                        message="Are you sure you want to delete it"
                    />
                </div>
            </div>
        </>
    );
};

export default WithLogin(ClinicList);
