import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';

import { statusList } from '../../constants';
import {
    getKitOrder,
    updateKitOrder,
    updateNote,
} from '../../services/kitOrder.service';
import { formatAddress, titleCase } from '../../utils';
import AlertModal from '../alertmodal.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';
import Notes from './notes.component';
import ViewStatusLogs from './statusLogView.component';

const ViewKitOrder = () => {
    const { id } = useParams();
    const [message, setMessage] = useState({});
    const [loading, setLoading] = useState(false);
    const [kitOrder, setKitOrder] = useState({});

    const [showAlertModal, setShowAlertModal] = useState({
        showModal: false,
        message: '',
        type: '',
    });
    const [accordianKey, setAccordiankey] = useState('StatusLog');
    const [kitOrderStatusEdit, setKitOrderStatusEdit] = useState(false);

    const fetchKitOrder = async id => {
        try {
            const kitOrderData = await getKitOrder(id);
            setKitOrder({ ...kitOrder, ...kitOrderData });
        } catch (error) {
            setMessage({ type: 'Error', message: error.response.data.detail });
        }
    };
    useEffect(() => {
        fetchKitOrder(id);
    }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAlertModalClose = closeSection => {
        fetchKitOrder(id);
        switch (closeSection) {
            case 'currentStatus':
                setKitOrderStatusEdit(false);
                break;
            default:
        }
    };

    const handleNoteUpdate = async (data, field_name) => {
        await updateNote(id, { ...data, field_name });
        fetchKitOrder(id);
    };

    const {
        register: statusUpdateFrmRegister,
        handleSubmit: statusUpdateFrmHandleSubmit,
        setValue: statusUpdateFrmSetValue,
    } = useForm({
        defaultValues: {},
    });

    const handleTransactionStratusChange = async data => {
        setLoading(true);

        try {
            const resp = await updateKitOrder(id, data);
            setShowAlertModal({
                showModal: true,
                message: resp.detail,
                type: 'Success',
                closeSection: 'currentStatus',
                handleAlertModalClose,
            });
        } catch (error) {
            setShowAlertModal({
                showModal: true,
                message: `${error.response.data.detail}`,
                type: 'Error',
            });
        }

        setLoading(false);
    };

    return (
        <div className="mt-2">
            <div className="pageHeader">
                <span id="header">
                    <h4>Kit order Details</h4>
                </span>
            </div>
            <hr />
            {message && message.type && <MessageContainer message={message} />}
            {kitOrder?.uuid && (
                <>
                    <div className="col-md-12">
                        <span className="txt-label w-15">ID</span>
                        <span className="txt-label-value">{kitOrder.id}</span>
                    </div>
                    <div className="col-md-12">
                        <span className="txt-label w-15">Account Name</span>
                        <span className="txt-label-value">
                            {kitOrder?.account?.name}
                        </span>
                    </div>
                    <div className="col-md-12">
                        <span className="txt-label w-15">Product Name</span>
                        <span className="txt-label-value">
                            {kitOrder?.product?.name}
                        </span>
                    </div>
                    <div className="col-md-12">
                        <span className="txt-label w-15">Site</span>
                        <span className="txt-label-value">
                            {kitOrder?.clinic?.name}
                        </span>
                    </div>
                    <div className="col-md-12">
                        <span className="txt-label w-15">Quantity</span>
                        <span className="txt-label-value">
                            {kitOrder.quantity}
                        </span>
                    </div>
                    <div className="col-md-12">
                        <span className="txt-label w-15">#Device count</span>
                        <span className="txt-label-value">
                            {kitOrder.item_counts}
                        </span>
                    </div>
                    <div className="col-md-12">
                        <span className="txt-label w-15">Shipping Address</span>
                        <span className="txt-label-value">
                            {formatAddress(kitOrder.shipping_address)}
                        </span>
                    </div>

                    <div className="col-md-12">
                        <span className="txt-label w-15">Current status</span>
                        {!kitOrderStatusEdit ? (
                            <span className="txt-label-value">
                                {titleCase(kitOrder.current_status)}
                            </span>
                        ) : (
                            <form
                                className="form-inline"
                                style={{ display: 'inline' }}
                                onSubmit={statusUpdateFrmHandleSubmit(
                                    handleTransactionStratusChange,
                                )}>
                                <span className="txt-label-value">
                                    <select
                                        id="transactionStatus"
                                        {...statusUpdateFrmRegister(
                                            'current_status',
                                            {
                                                required: true,
                                            },
                                        )}>
                                        {Object.keys(statusList).map(
                                            statusCode => (
                                                <option
                                                    value={
                                                        statusList[statusCode]
                                                    }
                                                    key={statusCode}>
                                                    {statusList[statusCode]}
                                                </option>
                                            ),
                                        )}
                                    </select>
                                </span>
                                <IconButton type="Submit">
                                    <DoneIcon
                                        disabled={loading}
                                        style={{
                                            color: 'green',
                                            cursor: 'pointer',
                                        }}
                                        title="Save"
                                    />
                                </IconButton>
                                <span className="mx-1"></span>
                                <HighlightOffIcon
                                    style={{
                                        color: 'red',
                                        cursor: 'pointer',
                                    }}
                                    title="Cancel"
                                    onClick={e => {
                                        e.preventDefault();
                                        setKitOrderStatusEdit(false);
                                    }}
                                />
                            </form>
                        )}
                        {!kitOrderStatusEdit &&
                            kitOrder.current_status !== 'Delivered' && (
                                <span>
                                    <Link
                                        className="icon-color"
                                        onClick={e => {
                                            e.preventDefault();
                                            setKitOrderStatusEdit(true);
                                            statusUpdateFrmSetValue(
                                                'current_status',
                                                kitOrder.current_status,
                                            );
                                        }}>
                                        <EditIcon className="icon-color" /> Edit
                                    </Link>
                                </span>
                            )}
                    </div>
                    <div className="new-line" />
                    <Accordion
                        defaultActiveKey="StatusLog"
                        flush
                        onSelect={key => {
                            setAccordiankey(key);
                        }}>
                        <Accordion.Item eventKey="StatusLog">
                            <Accordion.Header>
                                <LibraryBooksIcon />
                                <>&nbsp;</>
                                Status Logs
                            </Accordion.Header>
                            <Accordion.Body>
                                {accordianKey === 'StatusLog' && id > 0 && (
                                    <>
                                        <div className="new-line" />
                                        <ViewStatusLogs
                                            kitOrderId={id}
                                            kitOrder={kitOrder}
                                        />
                                    </>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="OrderNotes">
                            <Accordion.Header>
                                <LibraryBooksIcon />
                                <>&nbsp;</>
                                Order Notes
                            </Accordion.Header>
                            <Accordion.Body>
                                {accordianKey === 'OrderNotes' && id > 0 && (
                                    <>
                                        <div className="new-line" />
                                        <Notes
                                            orderId={id}
                                            title="Order notes"
                                            count={
                                                kitOrder?.order_notes?.length
                                            }
                                            notes={kitOrder?.order_notes}
                                            handleUpdate={handleNoteUpdate}
                                            field_name="order_notes"
                                        />
                                    </>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="InternalNotes">
                            <Accordion.Header>
                                <LibraryBooksIcon />
                                <>&nbsp;</>
                                Internal Notes
                            </Accordion.Header>
                            <Accordion.Body>
                                {accordianKey === 'InternalNotes' && id > 0 && (
                                    <>
                                        <div className="new-line" />
                                        <Notes
                                            orderId={id}
                                            title="Internal notes"
                                            count={
                                                kitOrder?.internal_notes?.length
                                            }
                                            notes={kitOrder?.internal_notes}
                                            handleUpdate={handleNoteUpdate}
                                            field_name="internal_notes"
                                        />
                                    </>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </>
            )}
            <AlertModal
                modalData={showAlertModal}
                handleAlertModalClose={handleAlertModalClose}
            />
        </div>
    );
};

export default WithLogin(ViewKitOrder);
