import LinkOffIcon from '@mui/icons-material/LinkOff';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';

import {
    fetchPatientProviders,
    unlinkPatientProvider,
} from '../../services/patient.service';
import { dateFormat } from '../../utils';

const PatientProviderList = props => {
    const [patientProviders, setPatientProviders] = useState([]);
    const [loading, setLoading] = useState(false);
    const { setError } = useForm({
        defaultValues: {},
    });

    const setMessage = props.setMessage;

    const columns = [
        {
            name: 'P.I Name',
            selector: row => `${row.first_name} ${row.last_name}`,
        },
        {
            name: 'Start Date',
            selector: row => dateFormat(row.start_date),
        },
        {
            name: 'End Date',
            selector: row => dateFormat(row.end_date),
        },
        {
            name: 'Status',
            selector: row => (row.end_date ? 'Inactive' : 'Active'),
        },
        {
            key: 'action',
            text: 'Action',
            name: 'Action',
            className: 'action',
            width: '75px',
            align: 'left',
            sortable: false,
            cell: row => {
                return (
                    <Fragment>
                        <span className="table-action-icon">
                            {!row.end_date ? (
                                <LinkOffIcon
                                    className="icon-color"
                                    onClick={() => {
                                        onUnlink(row);
                                    }}
                                />
                            ) : null}
                        </span>
                    </Fragment>
                );
            },
        },
    ];

    const getPatientProviders = async () => {
        setLoading(true);
        const resp = await fetchPatientProviders(props.patientId);
        setPatientProviders(resp);

        setLoading(false);
    };

    const onUnlink = async row => {
        setLoading(true);
        setMessage({});
        try {
            const unlinkProvider = await unlinkPatientProvider(
                parseInt(props.accountId),
                row.provider_id,
                parseInt(props.patientId),
            );
            getPatientProviders();
            setMessage({
                type: 'Success',
                message: unlinkProvider.details,
            });
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        getPatientProviders();
    }, [props.labOrder, props.reload]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <div className="mt-2">
                <DataTable
                    width="100%"
                    columns={columns}
                    data={patientProviders}
                    progressPending={loading}
                />
            </div>
        </>
    );
};

export default PatientProviderList;
