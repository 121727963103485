import api from './api';
import { API_URL } from './base';

export const fetchPCCs = (page, perPage, searchText, accountId) => {
    let url = `${API_URL}/admin/account/care-team-persons/?page=${page}&size=${perPage}`;
    if (accountId) {
        url = `${url}&account_id=${accountId}`;
    }
    if (searchText) {
        url = `${url}&q=${searchText}`;
    }
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const manageClinicsList = (
    page,
    perPage,
    account_person_id,
    clinic_id,
) => {
    let url = `${API_URL}/admin/account/care-team-person-detail-list/${account_person_id}/?page=${page}&size=${perPage}`;
    if (clinic_id) {
        url = `${url}&clinic_id=${clinic_id}`;
    }
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const fetchPCCbyId = account_person_id => {
    const url = `${API_URL}/admin/account/account-persons/${account_person_id}/`;
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const PccClinicsDropdown = account_id => {
    let url = `${API_URL}/admin/accounts/${account_id}/clinics/`;
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const PccProvidersDropdown = clinic_id => {
    let url = `${API_URL}/admin/account/clinics/${clinic_id}/providers/`;
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const assignPccClinic = data => {
    return api
        .post(`${API_URL}/admin/account/add-care-person-clinic/`, data)
        .then(resp => {
            return resp.data;
        });
};

export const assignProvider = data => {
    return api
        .post(`${API_URL}/admin/account/add-care-person-provider/`, data)
        .then(resp => {
            return resp.data;
        });
};

export const detachPccClinic = data => {
    return api
        .put(`${API_URL}/admin/account/detach-care-person-clinic/`, data)
        .then(resp => {
            return resp.data;
        });
};

export const detachPccProvider = data => {
    return api
        .put(`${API_URL}/admin/account/detach-care-person-provider/`, data)
        .then(resp => {
            return resp.data;
        });
};
