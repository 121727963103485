import { CLEAR_SPINNER, SET_SPINNER } from '../actions/types';

const initialState = { active: false };

const spinner = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case SET_SPINNER:
            return { active: true };

        case CLEAR_SPINNER:
            return { active: false };

        default:
            return state;
    }
};
export default spinner;
