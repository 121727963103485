import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
    fetchLabOrderById,
    updateLabOrder,
} from '../../services/labOrder.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';

const EditLabOrder = props => {
    const id = props.selectedRow.id;
    const [show, setModalShow] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});

    const heightSelectTypes = [
        {
            value: 'CM',
            label: 'cm',
        },
        {
            value: 'IN',
            label: 'in',
        },
    ];

    const weightSelectTypes = [
        {
            value: 'KG',
            label: 'kg',
        },
        {
            value: 'LB',
            label: 'lb',
        },
    ];

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const getLabOrders = async () => {
        try {
            const resp = await fetchLabOrderById(id);
            setValue('height', resp.height);
            setValue('weight', resp.weight);
            setValue('waist_circumference', resp.waist_circumference);
            setValue('height_unit', resp.height_unit);
            setValue('weight_unit', resp.weight_unit);
            setValue('waist_circumference_unit', resp.waist_circumference_unit);
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };

    useEffect(() => {
        setModalShow(props.open);
        getLabOrders();
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable();
        }
    };

    const saveLabOrder = async data => {
        setLoading(true);
        setMessage({});
        try {
            await updateLabOrder(data, id);
            setMessage({
                type: 'Success',
                message: 'Changes have been saved successfully',
            });
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }
        setLoading(false);
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Update lab order">
            <div className="formView">
                <div className="col-12">
                    <h5>Lab Order</h5>
                </div>
            </div>

            {message && <MessageContainer message={message} />}
            <form className="row g-2" onSubmit={handleSubmit(saveLabOrder)}>
                <div className="col-md-8">
                    <label htmlFor="height">Height</label>
                    <div style={{ position: 'relative' }}>
                        <input
                            type="text"
                            className={
                                errors?.height
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="height"
                            placeholder="Enter height in cm"
                            {...register('height', {
                                required: true,
                                maxLength: {
                                    value: 50,
                                    message:
                                        'Height can be max. 50 char length',
                                },
                            })}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                top: '0',
                            }}>
                            <select
                                className={
                                    errors?.title_id
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                id="title_id"
                                {...register('height_unit', { required: true })}
                                onChange={e => {
                                    if (
                                        e?.target.value === 'CM' &&
                                        getValues('height') !== 'CM' &&
                                        getValues('height')
                                    ) {
                                        setValue(
                                            'height',
                                            parseFloat(
                                                (
                                                    getValues('height') * 2.54
                                                ).toFixed(3),
                                            ),
                                        );
                                    } else if (
                                        e?.target.value === 'IN' &&
                                        getValues('height') !== 'IN' &&
                                        getValues('height')
                                    ) {
                                        setValue(
                                            'height',
                                            (
                                                getValues('height') / 2.54
                                            ).toFixed(3),
                                        );
                                    }
                                    setValue('height_unit', e.target.value);
                                }}>
                                {heightSelectTypes.map(heightSelectType => (
                                    <option
                                        value={heightSelectType.value}
                                        key={heightSelectType.value}>
                                        {heightSelectType.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.height?.message}
                            </div>
                        )}
                    </div>
                </div>

                <div className="col-md-8">
                    <label htmlFor="weight">Weight</label>
                    <div style={{ position: 'relative' }}>
                        <input
                            type="text"
                            className={
                                errors?.weight
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="weight"
                            placeholder="Enter weight in kg"
                            {...register('weight', {
                                required: true,
                                maxLength: {
                                    value: 50,
                                    message:
                                        'Weight can be max. 50 char length',
                                },
                            })}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                top: '0',
                            }}>
                            <select
                                className={
                                    errors?.title_id
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                id="title_id"
                                {...register('weight_unit', { required: true })}
                                onChange={e => {
                                    if (
                                        e?.target.value === 'KG' &&
                                        getValues('weight') !== 'KG' &&
                                        getValues('weight')
                                    ) {
                                        setValue(
                                            'weight',
                                            parseFloat(
                                                (
                                                    getValues('weight') * 0.4534
                                                ).toFixed(3),
                                            ),
                                        );
                                    } else if (
                                        e?.target.value === 'LB' &&
                                        getValues('weight') !== 'LB' &&
                                        getValues('weight')
                                    ) {
                                        setValue(
                                            'weight',
                                            parseFloat(
                                                (
                                                    getValues('weight') / 0.4534
                                                ).toFixed(3),
                                            ),
                                        );
                                    }
                                    setValue('weight_unit', e.target.value);
                                }}>
                                {weightSelectTypes.map(weightSelectType => (
                                    <option
                                        value={weightSelectType.value}
                                        key={weightSelectType.value}>
                                        {weightSelectType.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.weight?.message}
                            </div>
                        )}
                    </div>
                </div>

                <div className="col-md-12">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Submit
                    </button>
                </div>
            </form>
        </AppModal>
    );
};

export default EditLabOrder;
