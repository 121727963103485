import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';

import { fulfillmentStates } from '../../constants';
import { saveKitOrderStatusUpdate } from '../../services/kitOrder.service';
import { fetchUserList } from '../../services/user.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';

const UpdateKitOrderStatus = props => {
    const [show, setModalShow] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [userList, setUserList] = useState([]);
    const userDetails = JSON.parse(localStorage.getItem('user_me'));

    const getUsersList = async () => {
        try {
            const resp = await fetchUserList();
            setUserList(resp);
        } catch (error) {
            setMessage({
                type: 'Error',
                message: 'Error in loading fulfilled user list',
            });
        }
    };

    useEffect(() => {
        getUsersList();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setValue(
            'fulfiled_by_id',
            props.selectedRow.fulfiled_by?.id
                ? props.selectedRow.fulfiled_by?.id
                : userDetails.user_id,
        );
    }, [userList]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable();
        }
    };

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            status: props.selectedRow.status,
            fulfillment_date: props.selectedRow.fulfillment_date
                ? moment(props.selectedRow.fulfillment_date).toDate()
                : '',
            fulfiled_by_id: props.selectedRow.fulfiled_by?.id,
            fulfilment_state: props.selectedRow.fulfilment_state,
            notes: props.selectedRow.notes,
        },
    });

    const onChangeFullfillmentState = (state, fulfillment_date) => {
        if (
            state['label'] === 'Done' &&
            (fulfillment_date === '' || fulfillment_date === null)
        )
            setValue('fulfillment_date', new Date());
        else setValue('fulfillment_date', fulfillment_date);

        setValue('fulfilment_state', state['label']);
    };

    const onChangeDateTime = value => {
        setValue('fulfillment_date', value);
    };

    const handleStatusUpdate = async data => {
        setLoading(true);
        const values = {
            ...data,
            id: props.selectedRow.id,
            kit_order_id: props.selectedRow.kit_order_id,
            fulfiled_by_id:
                data.fulfiled_by_id === '' ? null : data.fulfiled_by_id,
            fulfillment_date:
                data.fulfillment_date === '' ? null : data.fulfillment_date,
        };
        try {
            await saveKitOrderStatusUpdate(
                props.selectedRow.id,
                props.selectedRow.kit_order_id,
                values,
            );
            setMessage({ message: 'Saved successfully', type: 'Success' });
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }
        setLoading(false);
    };
    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Update kit order status">
            <div className="formView">
                <form
                    className="row g-2"
                    onSubmit={handleSubmit(handleStatusUpdate)}>
                    {message && message.type && (
                        <MessageContainer message={message} />
                    )}
                    <div className="col-8">
                        <label htmlFor="status">Status</label>
                        <div>
                            <b>{props.selectedRow.status}</b>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <label htmlFor="fullfilledBy">Fulfilled by</label>
                        <select
                            className="form-control"
                            id="fullfilledBy"
                            {...register('fulfiled_by_id')}>
                            {userList?.map(user => (
                                <option value={user.id} key={user.id}>
                                    {`${user.first_name} ${user.last_name} [${user.role_name}]`}
                                </option>
                            ))}
                        </select>
                        {errors && (
                            <div className="invalid-feedback">
                                {errors?.fulfiled_by_id?.message}
                            </div>
                        )}
                    </div>
                    <div className="col-md-8">
                        <label htmlFor="fulfillmentDate">Fulfilled Date</label>
                        <Controller
                            control={control}
                            id="fulfillmentDate"
                            name="fulfillment_date"
                            render={({ field }) => (
                                <DatePicker
                                    selected={field.value}
                                    showTimeSelect
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                    onChange={datetime =>
                                        onChangeDateTime(datetime)
                                    }
                                />
                            )}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors?.fulfillment_date?.message}
                            </div>
                        )}
                    </div>
                    <div className="col-md-8">
                        <label htmlFor="fullfillmentState">
                            Fulfillment state
                        </label>
                        <Controller
                            control={control}
                            name="fulfilment_state"
                            render={({ field: { onChange, value, ref } }) => {
                                return (
                                    <Select
                                        inputRef={ref}
                                        options={fulfillmentStates}
                                        value={fulfillmentStates.find(
                                            c => c.label === value,
                                        )}
                                        onChange={val => {
                                            const values = getValues();
                                            onChangeFullfillmentState(
                                                val,
                                                values.fulfillment_date,
                                            );
                                        }}
                                    />
                                );
                            }}
                        />
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="notes">Notes</label>
                        <textarea
                            cols="70"
                            rows="3"
                            id="notes"
                            className={
                                errors?.notes
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            {...register('notes', {
                                maxLength: {
                                    value: 200,
                                    message: 'Note can be max. 200 char length',
                                },
                            })}
                        />
                        {errors?.body && (
                            <div className="invalid-feedback">
                                {errors?.notes.message}
                            </div>
                        )}
                    </div>

                    <div className="col-md-12">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </AppModal>
    );
};

export default WithLogin(UpdateKitOrderStatus);
