import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { fetchAccount, fetchAccountList } from '../../services/account.service';
import {
    createkitOrder,
    fetchProductList,
} from '../../services/kitOrder.service';
import { fetchAdminClinicsList } from '../../services/provider.service';
import { fetchStates } from '../../services/stateCity.service';
import { formatAddress } from '../../utils';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';

const AddKitOrder = props => {
    const [show, setModalShow] = useState(props.open);
    const [shippingStates, setShippingStates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [productList, setProductList] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [clinicList, setClinicList] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState({});
    const [selectedAccountDetail, setSelectedAccountDetail] = useState({});
    const [selectedProduct, setSelectedProduct] = useState({});
    const [kitOrderData, setKitOrderData] = useState({});
    const [displayPage, setDisplayPage] = useState('orderInfo');
    const [productPrice, setProductPrice] = useState(0.0);

    const country = 'US';
    const getStates = async address => {
        const stateList = await fetchStates(country);
        if (address === 'shipping_address') {
            setShippingStates(stateList);
            setValue('shipping_address.state', stateList[0].state_code);
        }
    };

    const getAccountList = async () => {
        setLoading(true);
        try {
            const accounts = await fetchAccountList();
            setAccountList(accounts);
            setValue('account_id', accounts[0].id);
            getClinicList(accounts[0].id);
            setSelectedAccount(accounts[0]);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during account list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const getClinicList = async accountId => {
        setLoading(true);
        try {
            const clinics = await fetchAdminClinicsList(accountId);
            setClinicList(clinics);
            setValue('clinic_id', clinics[0].id);
            setValue('shipping_address', clinics[0].address);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during account list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const handleModalClose = () => {
        setModalShow(false);
        props.setOpenAddForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable(1);
        }
    };

    const getAccount = async id => {
        try {
            const resp = await fetchAccount(id);
            setSelectedAccountDetail(resp);
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };

    useEffect(() => {
        setModalShow(props.open);
        getAccountList();
        getStates('shipping_address');
        getProductList();
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedAccount?.id) {
            getAccount(selectedAccount.id);
        }
    }, [selectedAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    const getProductList = async () => {
        try {
            const products = await fetchProductList();
            setProductList(products);
            setSelectedProduct(products[0]);
            setValue('product_id', products[0].id);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during product list loading.',
                type: 'Error',
            });
        }
    };

    const onChangeSetAccount = accountId => {
        const account = accountList.find(
            account => account.id === parseInt(accountId),
        );
        setSelectedAccount(account);
        getClinicList(account.id);
    };

    const onChangeSetClinic = clinicId => {
        const clinic = clinicList.find(
            clinic => clinic.id === parseInt(clinicId),
        );
        setValue('shipping_address', clinic.address);
        if (!clinic.address?.contact_mobile_number) {
            setValue(
                'shipping_address.contact_mobile_number',
                getValues('billing_address.contact_mobile_number'),
            );
        }
    };

    const onChangeProductSelection = productId => {
        console.log(parseInt(productId));
        console.log(productList);
        const product = productList.find(
            product => product.id === parseInt(productId),
        );
        setSelectedProduct(product);
    };

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            account_id: '',
            product_id: '',
            quantity: '',
            shipping_address: {
                line1: '',
                line2: '',
                city: '',
                zip: '',
                state: '',
                country: 'US',
            },
        },
    });

    const handleCreateOrder = async data => {
        try {
            setLoading(true);
            setMessage({});
            setKitOrderData({
                ...data,
                processingFees: 0,
            });
            setDisplayPage('orderConfirm');
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }

        setLoading(false);
    };

    const handleKitOrderCreate = async () => {
        setLoading(true);
        try {
            const resp = await createkitOrder(kitOrderData);
            setMessage({ message: resp.detail, type: 'Success' });
            setDisplayPage('');
            reset();
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }
        setLoading(false);
    };

    const getProductPrice = product => {
        let price = parseFloat(product.unit_price);
        if (
            Object.keys(product).length &&
            Object.keys(selectedAccountDetail).length &&
            product?.extra_attributes?.prices
        ) {
            product?.extra_attributes?.prices.forEach(productPlanPrice => {
                if (
                    productPlanPrice.subscription ===
                    selectedAccountDetail.subscription_detail?.short_code
                ) {
                    price = parseFloat(productPlanPrice.price);
                }
            });
        }

        setProductPrice(price);
    };

    useEffect(() => {
        getProductPrice(selectedProduct);
    }, [selectedAccountDetail, selectedProduct]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Add kit order">
            {message && message.type && <MessageContainer message={message} />}
            {displayPage === 'orderInfo' && (
                <div className="formView">
                    <form
                        className="row g-2"
                        onSubmit={handleSubmit(handleCreateOrder)}>
                        <div className="col-8">
                            <label htmlFor="account">Account</label>
                            <select
                                className="form-control"
                                id="account"
                                {...register('account_id', {
                                    onChange: e => {
                                        onChangeSetAccount(e.target.value);
                                    },
                                })}>
                                {accountList.map(account => (
                                    <option value={account.id} key={account.id}>
                                        {account.name}(id: {account.id})
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-8">
                            <label htmlFor="clinic">Site</label>
                            <select
                                className="form-control"
                                id="clinic"
                                {...register('clinic_id', {
                                    onChange: e => {
                                        onChangeSetClinic(e.target.value);
                                    },
                                })}>
                                {clinicList.map(clinic => (
                                    <option value={clinic.id} key={clinic.id}>
                                        {clinic.name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-8">
                            <label htmlFor="kitOrderProduct">Product</label>
                            <select
                                className="form-control"
                                id="kitOrderProduct"
                                {...register('product_id', {
                                    onChange: e => {
                                        onChangeProductSelection(
                                            e.target.value,
                                        );
                                    },
                                })}>
                                {productList.map(product => (
                                    <option value={product.id} key={product.id}>
                                        {product.name} (
                                        {
                                            product.extra_attributes[
                                                'units_per_box'
                                            ]
                                        }{' '}
                                        device
                                        {product.extra_attributes[
                                            'units_per_box'
                                        ] === 1
                                            ? ''
                                            : 's'}
                                        {product.extra_attributes[
                                            'units_per_box'
                                        ] === 1
                                            ? ''
                                            : ' / box'}
                                        )
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-8">
                            <label htmlFor="quantity">Units</label>
                            <input
                                type="number"
                                className={
                                    errors?.quantity
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                id="quantity"
                                placeholder="Enter quantity in number"
                                {...register('quantity', {
                                    required: true,
                                    validate: value => value > 0,
                                })}
                            />
                        </div>
                        <div className="col-8">
                            <span className="txt-label">Item Price</span>
                            <span className="txt-label-value">
                                {productPrice.toFixed(2) !==
                                    parseFloat(
                                        selectedProduct?.unit_price,
                                    ).toFixed(2) && (
                                    <>
                                        <span
                                            style={{
                                                textDecoration: 'line-through',
                                            }}>
                                            ${selectedProduct?.unit_price}
                                        </span>
                                        <span> </span>
                                    </>
                                )}
                                <b>${productPrice.toFixed(2)}</b>
                            </span>
                        </div>

                        <div className="col-12">
                            <h5>Shipping Address</h5>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="Shippingline1">Address line1</label>
                            <input
                                type="text"
                                className={
                                    errors?.shipping_address?.line1
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                id="Shippingline1"
                                placeholder="Enter line1"
                                {...register('shipping_address.line1', {
                                    required: true,
                                })}
                            />
                            {errors && (
                                <div className="invalid-feedback">
                                    {errors.shipping_address?.line1?.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="Shippingline2">Address line2</label>
                            <input
                                type="text"
                                className={
                                    errors?.shipping_address?.line2
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                id="Shippingline2"
                                placeholder="Enter line1"
                                {...register('shipping_address.line2', {
                                    required: false,
                                })}
                            />
                            {errors && (
                                <div className="invalid-feedback">
                                    {errors.shipping_address?.line2?.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="ShippingCountry">Country</label>
                            <select
                                className="form-control"
                                id="ShippingCountry"
                                {...register('shipping_address.country', {
                                    required: true,
                                })}>
                                <option value={'US'}>US</option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="ShipingState">State</label>
                            <select
                                className="form-control"
                                id="ShipingState"
                                {...register('shipping_address.state', {
                                    required: true,
                                })}>
                                {shippingStates.map(({ state_code, name }) => {
                                    return (
                                        <option
                                            key={state_code}
                                            value={state_code}>
                                            {name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="ShipingCity">City</label>
                            <input
                                className={
                                    errors?.shipping_address?.city
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                id="ShipingCity"
                                {...register('shipping_address.city', {
                                    required: true,
                                    minLength: {
                                        value: 2,
                                        message:
                                            'City can not be less than 2 characters',
                                    },
                                    maxLength: {
                                        value: 255,
                                        message:
                                            'City can be max. 255 characters',
                                    },
                                })}></input>
                            {errors && (
                                <div className="invalid-feedback">
                                    {errors.shipping_address?.city?.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="ShippingZip">Zip</label>
                            <input
                                type="number"
                                className={
                                    errors?.shipping_address?.zip
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                id="ShippingZip"
                                placeholder="Enter zip code"
                                {...register('shipping_address.zip', {
                                    required: true,
                                })}
                            />
                            {errors && (
                                <div className="invalid-feedback">
                                    {errors.shipping_address?.zip?.message}
                                </div>
                            )}
                        </div>

                        <div className="col-md-12">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                Next
                            </button>
                            <span className="mx-1"></span>
                            <button
                                type="Cancel"
                                className="btn btn-secondary"
                                disabled={loading}
                                onClick={e => {
                                    e.preventDefault();
                                    reset();
                                    handleModalClose();
                                }}>
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
            )}
            {displayPage === 'orderConfirm' && (
                <div>
                    <div className="col-md-12">
                        <span className="w-15 txt-label">Account</span>
                        <span className="txt-label-value">
                            {selectedAccount.name}
                        </span>
                    </div>
                    <div className="col-md-12">
                        <span className="w-15 txt-label">Product</span>
                        <span className="txt-label-value">
                            {selectedProduct.name}
                        </span>
                    </div>
                    <div className="col-md-12">
                        <span className="w-15 txt-label">Units</span>
                        <span className="txt-label-value">
                            {kitOrderData.quantity}
                        </span>
                    </div>
                    <div className="col-md-12">
                        <span className="w-15 txt-label">Unit Price</span>
                        <span className="txt-label-value">
                            ${productPrice.toFixed(2)}
                        </span>
                    </div>

                    <div className="col-md-12">
                        <span className="w-15 txt-label">Total Price</span>
                        <span className="txt-label-value">
                            <b>
                                $
                                {parseFloat(
                                    parseFloat(kitOrderData.quantity) *
                                        parseFloat(productPrice) *
                                        parseInt(
                                            selectedProduct?.extra_attributes
                                                ?.units_per_box,
                                        ),
                                ).toFixed(2)}
                            </b>
                        </span>
                    </div>
                    <div className="col-md-12">
                        <span className="w-15 txt-label">Shipping Address</span>
                        <span className="txt-label-value">
                            {formatAddress(kitOrderData.shipping_address)}
                        </span>
                    </div>

                    <div className="col-md-12">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}
                            onClick={handleKitOrderCreate}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Confirm
                        </button>
                        <span className="mx-1"></span>
                        <button
                            type="Cancel"
                            className="btn btn-secondary"
                            disabled={loading}
                            onClick={e => {
                                e.preventDefault();
                                setDisplayPage('orderInfo');
                            }}>
                            Back
                        </button>
                    </div>
                </div>
            )}
        </AppModal>
    );
};

export default WithLogin(AddKitOrder);
