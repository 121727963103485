import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

import { fetchLabOrderById } from '../../services/labOrder.service';
import { dateFormat } from '../../utils';
import AppModal from '../appModal.component';
import WithLogin from '../withLogin.component';
import ViewLabStatusLogs from './processingStatusLog.component';

const ViewLabOrder = props => {
    const [message, setMessage] = useState({});
    const [labOrder, setlabOrder] = useState({});
    const [accordianKey, setAccordiankey] = useState('ProcessingStatusLog');
    const [show, setModalShow] = useState(props.open);

    const getLabOrders = async () => {
        try {
            const resp = await fetchLabOrderById(props.selectedRow.id);
            setlabOrder(resp);
        } catch (error) {
            console.log('errors', `${error.response}`);
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
            setMessage({
                type: 'Error',
                message: `${error.response.data.detail}`,
            });
        }
    };

    useEffect(() => {
        setModalShow(props.open);
        getLabOrders();
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable();
        }
    };

    return (
        <AppModal
            size="xl"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Lab Order Detail">
            <div className="mt-2">
                <div className="col-md-12">
                    <span className="txt-label">ID</span>
                    <span className="txt-label-value">{labOrder.id}</span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Site ID</span>
                    <span className="txt-label-value">
                        {labOrder.clinic_name}
                    </span>
                </div>

                <div className="col-md-12">
                    <span className="txt-label">Sample ID</span>
                    <span className="txt-label-value">
                        {labOrder.sample_id}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Subject ID</span>
                    <span className="txt-label-value">
                        {labOrder.patient?.subject_id}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">height</span>
                    <span className="txt-label-value">
                        {labOrder.height}{' '}
                        {labOrder.height_unit
                            ? labOrder.height_unit.toLowerCase()
                            : ''}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Weight</span>
                    <span className="txt-label-value">
                        {labOrder.weight}{' '}
                        {labOrder.weight_unit
                            ? labOrder.weight_unit.toLowerCase()
                            : ''}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">BMI</span>
                    <span className="txt-label-value">
                        {labOrder.bmi ?? ''}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">DOB</span>
                    <span className="txt-label-value">
                        {dateFormat(labOrder.patient?.dob)}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Ethnicity</span>
                    <span className="txt-label-value">
                        {labOrder.patient?.ethnicity ?? ''}
                    </span>
                </div>

                <div className="col-md-12">
                    <span className="txt-label">Lab order Status</span>
                    <span className="txt-label-value">
                        {labOrder.mode === 'Draft' ? 'Draft' : labOrder.status}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Result</span>
                    <span className="txt-label-value">
                        {labOrder.pheno_type ?? ''}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Score</span>
                    <span className="txt-label-value">
                        {labOrder.score ?? ''}
                    </span>
                </div>
                <div className="new-line" />
                {labOrder && (
                    <div className="col-md-12">
                        <Accordion
                            defaultActiveKey="ProcessingStatusLog"
                            flush
                            onSelect={key => {
                                setAccordiankey(key);
                            }}>
                            <Accordion.Item eventKey="ProcessingStatusLog">
                                <Accordion.Header>
                                    <LibraryBooksIcon />
                                    <>&nbsp;</>
                                    Processing Status Log
                                </Accordion.Header>
                                <Accordion.Body>
                                    {accordianKey === 'ProcessingStatusLog' && (
                                        <>
                                            <div className="new-line" />
                                            <ViewLabStatusLogs
                                                labOrder={labOrder}
                                            />
                                        </>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                )}
            </div>
        </AppModal>
    );
};

export default WithLogin(ViewLabOrder);
