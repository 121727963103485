import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { getAccountById, updateAccount } from '../../services/account.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';

const EditAccount = props => {
    const [show, setModalShow] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const getAccount = async () => {
        try {
            const resp = await getAccountById(props.selectedRow.id);
            setValue('name', resp.name);
            setValue('is_active', resp.is_active.toString());
        } catch (error) {
            console.log(error);
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };

    useEffect(() => {
        setModalShow(props.open);
        getAccount();
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable();
        }
    };

    const saveAccount = async data => {
        setLoading(true);
        setMessage({});
        try {
            await updateAccount(props.selectedRow.id, data);
            setMessage({
                type: 'Success',
                message: 'Changes have been saved successfully',
            });
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }
        setLoading(false);
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Update account">
            <div className="formView">
                <div className="col-12">
                    <h5>Account</h5>
                </div>
                {message && <MessageContainer message={message} />}
                <form className="row g-2" onSubmit={handleSubmit(saveAccount)}>
                    <div className="col-md-6">
                        <label htmlFor="name">Name</label>
                        <input
                            type="text"
                            className={
                                errors?.name
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="name"
                            placeholder="Enter account name"
                            {...register('name', {
                                required: true,
                                maxLength: {
                                    value: 200,
                                    message:
                                        'First name can be max. 200 char length',
                                },
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.name?.message}
                            </div>
                        )}
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="isActive">Status</label>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="radio-isprimary-yes"
                                value={true}
                                {...register('is_active')}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="radio-isprimary-yes">
                                Active
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                type="radio"
                                id="radio-isprimary-no"
                                value={false}
                                {...register('is_active')}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="radio-isprimary-no">
                                Inactive
                            </label>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </AppModal>
    );
};

export default EditAccount;
