import InventoryIcon from '@mui/icons-material/Inventory';
import PeopleIcon from '@mui/icons-material/People';
import ScienceIcon from '@mui/icons-material/Science';
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { useParams } from 'react-router-dom';

import { accountRoles } from '../../constants';
import { getAccountById } from '../../services/account.service';
import { dateTimeFormat } from '../../utils';
import AccountManagerList from '../accountManager/accountManagerList.component';
import ClinicList from '../clinic/clinicList.component';
import KitInventories from '../kitInventories/kitInventories.component';
import LabOrderList from '../labOrder/labOrderList.component';
import MessageContainer from '../message.component';
import PatientList from '../patient/patientList.component';
import PCCListComponent from '../pcc/pccList.component';
import ProviderList from '../provider/providerList.component';
import WithLogin from '../withLogin.component';

// eslint-disable-line

const ViewAccount = props => {
    const { id: accountId } = useParams();
    const [message, setMessage] = useState({});
    const [accData, setAccData] = useState({});
    const [accordianKey, setAccordiankey] = useState('clinics');

    const fetchAccDetails = async () => {
        try {
            const resp = await getAccountById(accountId);
            setAccData(resp);
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };

    useEffect(() => {
        fetchAccDetails();
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="mt-2">
            <h5>Account Details</h5>
            {message && <MessageContainer message={message} />}
            <hr />
            <div className="mt-2">
                <div className="col-md-12">
                    <span className="txt-label">Id</span>
                    <span className="txt-label-value">{accData.id}</span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Name</span>
                    <span className="txt-label-value">{accData.name}</span>
                </div>

                <div className="col-md-12">
                    <span className="txt-label">Activation mail sent ?</span>
                    <span className="txt-label-value">
                        {accData.is_sent_activation_email ? 'Yes' : 'No'}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">P.I Count </span>
                    <span className="txt-label-value">
                        {accData.provider_count ? accData.provider_count : 0}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Account Manager Count </span>
                    <span className="txt-label-value">
                        {accData.account_manager_count
                            ? accData.account_manager_count
                            : 0}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Site Count </span>
                    <span className="txt-label-value">
                        {accData.clinic_count ? accData.clinic_count : 0}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Patient Count </span>
                    <span className="txt-label-value">
                        {accData.patient_count ? accData.patient_count : 0}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Is Active</span>
                    <span className="txt-label-value">
                        {accData.is_active ? 'Yes' : 'No'}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Created At</span>
                    <span className="txt-label-value">
                        {dateTimeFormat(accData.created_at)}
                    </span>
                </div>
                <div className="new-line"></div>
                <div className="col-md-12">
                    <Accordion
                        defaultActiveKey="clinics"
                        flush
                        onSelect={key => {
                            setAccordiankey(key);
                        }}>
                        <Accordion.Item eventKey="clinics">
                            <Accordion.Header>
                                <PeopleIcon />
                                <>&nbsp;</>
                                Sites
                            </Accordion.Header>
                            <Accordion.Body>
                                {accordianKey === 'clinics' && (
                                    <ClinicList
                                        showFilters={false}
                                        accountId={accountId}
                                    />
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="accountManagers">
                            <Accordion.Header>
                                <PeopleIcon />
                                <>&nbsp;</>
                                Account Managers
                            </Accordion.Header>
                            <Accordion.Body>
                                {accordianKey === 'accountManagers' && (
                                    <AccountManagerList accountId={accountId} />
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="providers">
                            <Accordion.Header>
                                <PeopleIcon />
                                <>&nbsp;</>
                                {accountRoles.PI}s
                            </Accordion.Header>
                            <Accordion.Body>
                                {accordianKey === 'providers' && (
                                    <ProviderList accountId={accountId} />
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="pccs">
                            <Accordion.Header>
                                <PeopleIcon />
                                <>&nbsp;</>
                                Patient Care Collaborators
                            </Accordion.Header>
                            <Accordion.Body>
                                {accordianKey === 'pccs' && (
                                    <PCCListComponent accountId={accountId} />
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="patients">
                            <Accordion.Header bsPrefix="accordion-header">
                                <span>
                                    <PeopleIcon />
                                </span>
                                <>&nbsp;</>
                                <span>Patients</span>
                            </Accordion.Header>
                            <Accordion.Body>
                                {accordianKey === 'patients' && (
                                    <PatientList
                                        showFilters={false}
                                        accountId={accountId}
                                    />
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="labOrders">
                            <Accordion.Header bsPrefix="accordion-header">
                                <span>
                                    <ScienceIcon />
                                </span>
                                <>&nbsp;</>
                                <span>Lab Orders</span>
                            </Accordion.Header>
                            <Accordion.Body>
                                {accordianKey === 'labOrders' && (
                                    <LabOrderList
                                        showFilters={false}
                                        accountId={accountId}
                                    />
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="kitInventories">
                            <Accordion.Header bsPrefix="accordion-header">
                                <span>
                                    <InventoryIcon fontSize="small" />
                                </span>
                                <>&nbsp;</>
                                <span>Kit Inventories</span>
                            </Accordion.Header>
                            <Accordion.Body>
                                {accordianKey === 'kitInventories' && (
                                    <KitInventories
                                        showFilters={false}
                                        accountId={accountId}
                                    />
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </div>
    );
};

export default WithLogin(ViewAccount);
