import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Routes, Route, Link } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-bootstrap';

import KitOrder from './kitOrder.component';
import ViewKitOrder from './kitOrderView.component';

function KitorderBase() {
    const { isLoggedIn } = useSelector(state => state.auth);
    const [activeKey, setActive] = useState('kit-orders');
    if (!isLoggedIn) {
        return <Navigate to="/login/" />;
    }
    return (
        <>
            <div className="col-md-12">
                <Nav
                    as="ul"
                    activeKey={activeKey}
                    onSelect={selectedKey => setActive(selectedKey)}
                    className="nav nav-tabs">
                    <Nav.Item as="li">
                        <NavLink
                            as={Link}
                            eventKey="kit-orders"
                            to="/kit-orders/">
                            Kit orders
                        </NavLink>
                    </Nav.Item>
                </Nav>
            </div>
            <div className="col-md-12">
                <Routes>
                    <Route path="/" element={<KitOrder />} />
                    <Route path="/:id/" element={<ViewKitOrder />} />
                    <Route
                        path="/kit-inventories/"
                        element={<ViewKitOrder />}
                    />
                </Routes>
            </div>
        </>
    );
}

export default KitorderBase;
