import api from './api';
import { API_URL, LDH_API_URL } from './base';

export const fetchLabOrders = (page, perPage, labOrderSearchFilters) => {
    let url = `${API_URL}/admin/accounts/lab-orders/?page=${page}&size=${perPage}`;
    if (labOrderSearchFilters.accountId) {
        url = `${url}&account_id=${labOrderSearchFilters.accountId}`;
    }
    if (labOrderSearchFilters.searchText) {
        url = `${url}&q=${labOrderSearchFilters.searchText}`;
    }

    if (labOrderSearchFilters.startDate) {
        url = `${url}&start_date=${labOrderSearchFilters.startDate}`;
    }

    if (labOrderSearchFilters.endDate) {
        url = `${url}&end_date=${labOrderSearchFilters.endDate}`;
    }
    if (labOrderSearchFilters.currentStatus) {
        url = `${url}&lab_order_status=${labOrderSearchFilters.currentStatus}`;
    }
    if (labOrderSearchFilters.partnerID) {
        url = `${url}&partner_id=${labOrderSearchFilters.partnerID}`;
    }

    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const fetchExportLabOrders = labOrderFilter => {
    const headers = {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };
    let url = `${API_URL}/admin/accounts/lab-orders/export/?`;
    console.log('labOrderFilter>>', labOrderFilter);
    if (labOrderFilter.startDate) {
        url = `${url}start_date=${labOrderFilter.startDate}`;
    }

    if (labOrderFilter.endDate) {
        url = `${url}&end_date=${labOrderFilter.endDate}`;
    }

    if (labOrderFilter.searchText) {
        url = `${url}&q=${labOrderFilter.searchText}`;
    }
    if (labOrderFilter.partnerId) {
        url = `${url}&partner_id=${labOrderFilter.partnerId}`;
    }
    if (labOrderFilter.accountID) {
        url = `${url}&account_id=${labOrderFilter.accountID}`;
    }
    if (labOrderFilter.currentStatus) {
        url = `${url}&current_status=${labOrderFilter.currentStatus}`;
    }
    if (labOrderFilter.exportType) {
        url = `${url}&export_type=${labOrderFilter.exportType}`;
    }

    return api.get(url, { responseType: 'blob', headers }).then(resp => {
        return resp.data;
    });
};

export const fetchLabOrderById = id => {
    return api.get(`${API_URL}/admin/accounts/lab-orders/${id}/`).then(resp => {
        return resp.data;
    });
};

export const updateLabOrder = (data, id) => {
    return api
        .put(`${API_URL}/admin/accounts/lab-orders/${id}/`, data)
        .then(resp => {
            return resp.data;
        });
};

export const labOrderReportRevision = id => {
    return api
        .get(`${API_URL}/admin/accounts/report-revision/lab-orders/${id}/`)
        .then(resp => {
            return resp.data;
        });
};

export const runAiq = row => {
    return api
        .post(`${API_URL}/admin/accounts/lab-orders/${row.id}/run-aiq`)
        .then(resp => {
            return resp.data;
        });
};

export const fetchLabOrderStatusLogs = labOrderId => {
    return api
        .get(`${API_URL}/admin/accounts/lab-orders/${labOrderId}/status-logs/`)
        .then(resp => {
            return resp.data;
        });
};

export const fetchLabSampleStatus = (
    page,
    perPage,
    labOrderSearchFilters,
    status,
) => {
    let url = `${LDH_API_URL}/sample-status/?page=${page}&size=${perPage}`;
    if (status) {
        url = `${url}&status=${status}`;
    }
    if (labOrderSearchFilters.searchText) {
        url = `${url}&q=${labOrderSearchFilters.searchText}`;
    }

    if (labOrderSearchFilters.startDate) {
        url = `${url}&start_date=${labOrderSearchFilters.startDate}`;
    }

    if (labOrderSearchFilters.endDate) {
        url = `${url}&end_date=${labOrderSearchFilters.endDate}`;
    }
    if (labOrderSearchFilters.currentStatus) {
        url = `${url}&status=${labOrderSearchFilters.currentStatus}`;
    }

    if (labOrderSearchFilters.app_id) {
        url = `${url}&app_id=${labOrderSearchFilters.app_id}`;
    }

    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const fetchSampleLogsBySampleId = sample_id => {
    let url = `${LDH_API_URL}/sample-status/${sample_id}`;

    return api.get(url).then(resp => {
        return resp;
    });
};

export const fetchAppConfigData = () => {
    let url = `${LDH_API_URL}/app-names/`;

    return api.get(url).then(resp => {
        return resp;
    });
};
