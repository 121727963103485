import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import { fetchLabOrderStatusLogs } from '../../services/labOrder.service';
import { dateTimeFormat, titleCase } from '../../utils';

const ProcessingStatusLog = props => {
    const [statusLogs, setStatusLogs] = useState([]);
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            name: 'Status',
            selector: row => titleCase(row.status),
        },
        {
            name: 'Created at',
            selector: row => dateTimeFormat(row.created_at),
        },
        {
            name: 'Updated at',
            selector: row => dateTimeFormat(row.updated_at),
        },
        {
            name: 'Info',
            selector: row => {
                let ret = (
                    <>
                        {row?.extras ? (
                            <pre style={{ whiteSpace: 'pre-wrap' }}>
                                {JSON.stringify(row.extras, null, 2)}
                            </pre>
                        ) : (
                            ''
                        )}
                        {row?.info !== row.status ? (
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {row.info.split('\n').map((item, idx) => {
                                    return (
                                        <span key={idx}>
                                            {item}
                                            <br />
                                        </span>
                                    );
                                })}
                            </div>
                        ) : (
                            ''
                        )}
                    </>
                );
                return ret;
            },
            width: '30%',
        },
    ];

    const getStatusLogs = async labOrderId => {
        setLoading(true);
        if (labOrderId) {
            try {
                const resp = await fetchLabOrderStatusLogs(labOrderId);
                setStatusLogs(resp);
            } catch (error) {
                console.log(error);
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        getStatusLogs(props.labOrder?.id);
    }, [props.labOrder]);

    return (
        <>
            <div className="mt-2">
                <DataTable
                    width="100%"
                    columns={columns}
                    data={statusLogs}
                    progressPending={loading}
                />
            </div>
        </>
    );
};

export default ProcessingStatusLog;
