import axios from 'axios';

import { API_URL } from './base';
import TokenService from './token.service';

const instance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use(
    config => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    res => {
        return res;
    },
    async err => {
        const originalConfig = err.config;
        if (
            !originalConfig.url.includes('/admin/oauth/token/') &&
            err.response
        ) {
            // Access Token was expired
            if (
                (err.response.status === 401 || err.response.status === 403) &&
                !originalConfig._retry
            ) {
                originalConfig._retry = true;

                const headers = {
                    'Content-Type': 'multipart/form-data',
                    grant_type: 'client_credentials',
                };
                try {
                    const resp = await instance.post(
                        '/admin/oauth/refresh-token/',
                        {
                            refresh_token: TokenService.getLocalRefreshToken(),
                            grant_type: 'refresh_token',
                        },
                        { headers },
                    );

                    const { access_token, refresh_token } = resp.data;
                    TokenService.updateLocalAccessToken(
                        access_token,
                        refresh_token,
                    );

                    return instance(originalConfig);
                } catch (_error) {
                    if (
                        _error.response.status === 404 ||
                        _error.response.status === 400 ||
                        _error.response.status === 401
                    ) {
                        const failureCnt =
                            TokenService.getLocalRefreshTokenFailureCnt();
                        console.log(failureCnt);
                        if (failureCnt <= 1) {
                            TokenService.updateLocalAccessTokenFailureCount();
                        } else if (failureCnt > 1) {
                            TokenService.removeLocalAccessToken();
                        }
                        window.location.reload();
                        return Promise.reject(_error);
                    }
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    },
);

export default instance;
