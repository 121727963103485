import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

import AccountUserList from '../accountUser/accountUserList.component';
import ClinicList from '../clinic/clinicList.component';
import PatientList from '../patient/patientList.component';
import Account from './account.component';
import AccountSideBar from './accountSideBar.component';
import AccountView from './accountView.component';

function AccountBase() {
    const { isLoggedIn } = useSelector(state => state.auth);
    if (!isLoggedIn) {
        return <Navigate to="/login/" />;
    }

    return (
        <>
            <div className="col-md-12">
                <AccountSideBar />
            </div>
            <div className="col-md-12">
                <Routes>
                    <Route path="/" element={<Account />} />
                    <Route path="/:id/" element={<AccountView />} />
                    <Route
                        path="/account-user/"
                        element={<AccountUserList />}
                    />
                    <Route path="/clinics/" element={<ClinicList />} />
                    <Route path="/patients/" element={<PatientList />} />
                </Routes>
            </div>
        </>
    );
}

export default AccountBase;
