const getLocalRefreshToken = () => {
    const auth = JSON.parse(localStorage.getItem('admin_auth'));
    return auth ? auth.refresh_token : null;
};

const getLocalAccessToken = () => {
    const auth = JSON.parse(localStorage.getItem('admin_auth'));
    return auth ? auth.access_token : null;
};

const removeLocalAccessToken = () => {
    localStorage.removeItem('admin_auth');
    localStorage.removeItem('user_me');
};

const updateLocalAccessToken = (token, refresh_token) => {
    let auth = JSON.parse(localStorage.getItem('admin_auth'));
    auth.access_token = token;
    auth.refresh_token = refresh_token;
    auth.failure_cnt = 0;
    localStorage.setItem('admin_auth', JSON.stringify(auth));
};
const updateLocalAccessTokenFailureCount = () => {
    let auth = JSON.parse(localStorage.getItem('admin_auth'));
    auth.failure_cnt += 1;
    localStorage.setItem('admin_auth', JSON.stringify(auth));
};

const getLocalRefreshTokenFailureCnt = () => {
    const auth = JSON.parse(localStorage.getItem('admin_auth'));
    return auth.failure_cnt ? auth.failure_cnt : 0;
};

const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    removeLocalAccessToken,
    updateLocalAccessToken,
    updateLocalAccessTokenFailureCount,
    getLocalRefreshTokenFailureCnt,
};

export default TokenService;
