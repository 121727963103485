import api from './api';
import { API_URL } from './base';

export const addClinic = (data, accountId) => {
    return api
        .post(`${API_URL}/admin/accounts/${accountId}/clinics/`, {
            name: data.name,
            address: data.address,
        })
        .then(resp => {
            return resp.data;
        });
};

export const fetchClinicById = id => {
    return api.get(`${API_URL}/admin/accounts/clinics/${id}/`).then(resp => {
        return resp.data;
    });
};

export const fetchClinics = (page, perPage, searchText, accountId) => {
    let url = `${API_URL}/admin/accounts/${accountId}/clinics/?page=${page}&size=${perPage}`;
    if (searchText) {
        url = `${url}&q=${searchText}`;
    }
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const updateClinic = (data, clinic_id) => {
    return api
        .put(`${API_URL}/admin/accounts/clinics/${clinic_id}/`, {
            name: data.name,
            address: data.address,
        })
        .then(resp => {
            return resp.data;
        });
};

// delete a clinic
export const deleteClinicById = id => {
    return api.delete(`${API_URL}/admin/accounts/clinics/${id}/`).then(resp => {
        return resp.data;
    });
};
