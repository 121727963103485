import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import { fetchAccountUserDetails } from '../../services/accountUser.service';
import AppModal from '../appModal.component';
import WithLogin from '../withLogin.component';

const ViewAccountUser = props => {
    const [message, setMessage] = useState({});
    const [acmData, setAccData] = useState({});
    const [show, setModalShow] = useState(props.open);
    const [carePersonsProviders, setCarePersonsProviders] = useState([]);

    const getAUDetails = async () => {
        try {
            const resp = await fetchAccountUserDetails(props.selectedRow.id);
            let pccProviders = [];

            resp.care_person?.pcc_clinics_provider?.forEach(clinic => {
                clinic.providers.forEach(provider => {
                    pccProviders.push({
                        clinic_name: clinic.name,
                        name: `${provider.first_name} ${provider.last_name}`,
                        provider_id: provider.id,
                        clinic_id: clinic.id,
                    });
                });
            });
            setCarePersonsProviders(pccProviders);
            setAccData(resp);
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };

    const columns = [
        {
            name: 'Site',
            selector: row => row.clinic_name,
            width: '50%',
        },
        {
            name: 'P.I Name',
            selector: row => row.name,
            width: '50%',
        },
    ];

    useEffect(() => {
        setModalShow(props.open);
        getAUDetails();
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable();
        }
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle=" Account User Details">
            <div className="mt-2">
                <div className="col-md-12">
                    <span className="txt-label">Id</span>
                    <span className="txt-label-value">{acmData.id}</span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Name</span>
                    <span className="txt-label-value">
                        {acmData.first_name} {acmData.last_name}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Email</span>
                    <span className="txt-label-value">{acmData.email}</span>
                </div>
                {acmData?.account_manager && (
                    <>
                        <br />
                        <span className="txt-label">
                            Account manager Information
                        </span>

                        <div className="col-md-12">
                            <span className="txt-label">Is Primary</span>
                            <span className="txt-label-value">
                                {acmData.account_manager.is_primary
                                    ? 'Yes'
                                    : 'No'}
                            </span>
                        </div>
                    </>
                )}

                {acmData?.provider && (
                    <>
                        <br />
                        <span className="txt-label">P.I Information</span>
                        <div className="col-md-12">
                            <span className="txt-label">NPI ID</span>
                            <span className="txt-label-value">
                                {acmData.provider.npi_id}
                            </span>
                        </div>
                        <div className="col-md-12">
                            <span className="txt-label">Site</span>
                            <span className="txt-label-value">
                                {acmData?.provider.clinics?.map(
                                    (clinic, key) => (
                                        <>
                                            <span key={clinic.name}>
                                                {clinic.name}
                                            </span>
                                            {key !==
                                                acmData?.provider.clinics
                                                    .length -
                                                    1 && <span>, </span>}
                                        </>
                                    ),
                                )}
                            </span>
                        </div>
                    </>
                )}
                <br />
                {acmData?.care_person && (
                    <>
                        <br />
                        <span className="txt-label">
                            Patient care collaborator Information
                        </span>
                        <div className="accounts-list-table">
                            <DataTable
                                width="100%"
                                columns={columns}
                                data={carePersonsProviders}
                            />
                        </div>
                    </>
                )}
            </div>
        </AppModal>
    );
};

export default WithLogin(ViewAccountUser);
