import api from './api';
import { API_URL } from './base';

export const fetchKitInventories = (
    page,
    perPage,
    kitInventorySearchFilters,
) => {
    let url = `${API_URL}/admin/accounts/kits/?page=${page}&size=${perPage}`;
    if (kitInventorySearchFilters.accountId) {
        url = `${url}&account_id=${kitInventorySearchFilters.accountId}`;
    }
    if (kitInventorySearchFilters.kitOrderId) {
        url = `${url}&kit_order_id=${kitInventorySearchFilters.kitOrderId}`;
    }
    if (kitInventorySearchFilters.searchText) {
        url = `${url}&q=${kitInventorySearchFilters.searchText}`;
    }

    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const downloadSampleCsv = () => {
    return api
        .get(`${API_URL}/admin/accounts/download-sample-kit-csv/`)
        .then(resp => {
            return resp.data;
        });
};

export const uploadcsv = formData => {
    return api
        .post(`${API_URL}/admin/accounts/kits/add-kits-by-csv/`, formData, {
            headers: {
                'Content-Type': 'application/csv charset=utf-8',
            },
        })
        .then(resp => {
            return resp.data;
        });
};

export const addKitInventory = data => {
    return api.post(`${API_URL}/admin/accounts/kits/`, data).then(resp => {
        return resp.data;
    });
};

export const fetchAddedKitsCount = kitOrderId => {
    return api
        .get(`${API_URL}/admin/accounts/kit-orders/${kitOrderId}/kits-count/`)
        .then(resp => {
            return resp.data;
        });
};

export const activateKits = (kitOrderId, data) => {
    return api
        .post(
            `${API_URL}/admin/accounts/kit-orders/${kitOrderId}/activation/`,
            data,
        )
        .then(resp => {
            return resp.data;
        });
};

export const getKitDetails = kitId => {
    return api
        .get(`${API_URL}/admin/accounts/kits/${kitId}`)
        .then(resp => {
            return resp.data;
        });
};


export const updateKit = (kitId, data) => {
    return api
        .put(`${API_URL}/admin/accounts/kits/${kitId}`, data)
        .then(resp => {
            return resp.data;
        });
};

export const deleteKit = (kitId) => {
    return api
        .delete(`${API_URL}/admin/accounts/kits/${kitId}`)
        .then(resp => {
            return resp.data;
        });
};