import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { fetchClinicById, updateClinic } from '../../services/clinic.service';
import { fetchStates } from '../../services/stateCity.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';

const EditClinic = props => {
    const clinic_id = props.selectedRow.clinic_details.id;
    const [show, setModalShow] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [states, setStates] = useState([]);

    const [clinic, setClinic] = useState({});

    const country = 'US';
    const getStates = async () => {
        const stateList = await fetchStates(country);
        setStates(stateList);
    };

    const {
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const getClinics = async () => {
        try {
            const resp = await fetchClinicById(clinic_id);
            setClinic(resp);
            setValue('name', resp.name);
            setValue('address', resp.address);
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };

    useEffect(() => {
        setModalShow(props.open);
        getClinics();
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        getStates();
    }, [clinic]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (clinic.address?.state && states.length > 0) {
            if (
                states.some(state => state.state_code === clinic.address?.state)
            ) {
                setValue('address.state', clinic.address.state);
            }
        }
    }, [clinic, states]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable();
        }
    };

    const saveClinic = async data => {
        setLoading(true);
        setMessage({});
        try {
            await updateClinic(data, clinic_id);
            setMessage({
                type: 'Success',
                message: 'Changes have been saved successfully',
            });
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }
        setLoading(false);
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Update Site">
            {message && <MessageContainer message={message} />}
            <form className="row g-2" onSubmit={handleSubmit(saveClinic)}>
                <div className="col-md-6">
                    <h5>
                        <label htmlFor="clinic_name">Name</label>
                    </h5>
                    <input
                        type="text"
                        title="Only alphanumeric"
                        className={
                            errors?.name
                                ? 'form-control is-invalid'
                                : 'form-control'
                        }
                        id="clinic_name"
                        placeholder="Enter site ID"
                        {...register('name', {
                            required: true,
                            pattern: {
                                value: /^(?![0-9 ]+$)[a-zA-Z0-9-: ]*$/i,
                                message:
                                    'Combination of alphabets and numbers are allowed',
                            },
                            maxLength: {
                                value: 255,
                                message: 'Site ID can be max. 255 char length',
                            },
                        })}
                    />
                    {errors && (
                        <div className="invalid-feedback">
                            {errors.name?.message}
                        </div>
                    )}
                </div>

                <div className="col-12">
                    <h5>Address</h5>
                </div>
                <div className="col-md-6">
                    <label htmlFor="Accountline1">Address line1</label>
                    <input
                        type="text"
                        className={
                            errors?.address?.line1
                                ? 'form-control is-invalid'
                                : 'form-control'
                        }
                        id="Accountline1"
                        placeholder="Enter line1"
                        {...register('address.line1', {
                            required: true,
                        })}
                    />
                    {errors && (
                        <div className="invalid-feedback">
                            {errors.address?.line1?.message}
                        </div>
                    )}
                </div>
                <div className="col-md-6">
                    <label htmlFor="Accountline2">Address line2</label>
                    <input
                        type="text"
                        className={
                            errors?.address?.line2
                                ? 'form-control is-invalid'
                                : 'form-control'
                        }
                        id="Accountline1"
                        placeholder="Enter line1"
                        {...register('address.line2', {
                            required: false,
                        })}
                    />
                    {errors && (
                        <div className="invalid-feedback">
                            {errors.address?.line2?.message}
                        </div>
                    )}
                </div>
                <div className="col-md-6">
                    <label htmlFor="AccountBillingCountry">Country</label>
                    <select
                        className="form-control"
                        id="AccountBillingCountry"
                        {...register('address.country', {
                            required: true,
                        })}>
                        <option value={'US'}>US</option>
                    </select>
                </div>
                <div className="col-md-6">
                    <label htmlFor="AccountBillingState">State</label>
                    <select
                        className="form-control"
                        id="AccountBillingState"
                        {...register('address.state', {
                            required: true,
                        })}>
                        {states.map(({ state_code, name }) => {
                            return (
                                <option key={state_code} value={state_code}>
                                    {name}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="col-md-6">
                    <label htmlFor="AccountBillingCity">City</label>
                    <input
                        type="text"
                        title="Only text"
                        className={
                            errors?.address?.city
                                ? 'form-control is-invalid'
                                : 'form-control'
                        }
                        id="AccountBillingCity"
                        {...register('address.city', {
                            required: true,
                            pattern: {
                                value: /[a-zA-Z]+/i,
                                message: 'Only text allowed',
                            },
                            minLength: {
                                value: 2,
                                message:
                                    'City can not be less than 2 characters',
                            },
                            maxLength: {
                                value: 255,
                                message: 'City can be max. 255 characters',
                            },
                        })}></input>
                    {errors && (
                        <div className="invalid-feedback">
                            {errors.address?.city?.message}
                        </div>
                    )}
                </div>
                <div className="col-md-6">
                    <label htmlFor="AccountBillingZip">Zip</label>
                    <input
                        type="number"
                        className={
                            errors?.address?.zip
                                ? 'form-control is-invalid'
                                : 'form-control'
                        }
                        id="AccountBillingZip"
                        placeholder="Enter zip code"
                        {...register('address.zip', {
                            required: true,
                        })}
                    />
                    {errors && (
                        <div className="invalid-feedback">
                            {errors.address?.zip?.message}
                        </div>
                    )}
                </div>

                <div className="col-md-12">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Submit
                    </button>
                </div>
            </form>
        </AppModal>
    );
};

export default EditClinic;
