import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import { detachPccClinic, manageClinicsList } from '../../services/pcc.service';
import AppModal from '../appModal.component';
import DeleteConfirmation from '../deleteConfirmation.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';
import AddPccClinics from './addPccClinics.component';
import ManageProviders from './manageProviders.component';

const ManageClinics = props => {
    const [message, setMessage] = useState({});
    const [show, setModalShow] = useState(props.open);
    const [openAddForm, setOpenAddForm] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [openmanageProvidersForm, setOpenmanageProvidersForm] =
        useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const handlePageChange = page => {
        setPage(page);
        getPCCs(page, perPage, props.selectedRow.account_person_id);
    };

    const onDelete = async row => {
        setShowDeleteConfirmation(true);
        setSelectedRow(row);
    };

    const onManageProviders = row => {
        setSelectedRow(row);
        setOpenmanageProvidersForm(true);
    };

    const DeletePccClinic = async clinic_id => {
        setMessage({});
        try {
            const data = {
                account_person_id: props.selectedRow.account_person_id,
                clinic_id: clinic_id,
            };
            await detachPccClinic(data);
            await getPCCs(page, perPage, props.selectedRow.account_person_id);
            setMessage({
                type: 'Success',
                message: `Site detached successfully`,
            });
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: 'Error deleting clinic',
                });
            }
        }
        setShowDeleteConfirmation(false);
    };

    const reloadDataTable = toPage => {
        getPCCs(page, perPage, props.selectedRow.account_person_id);
        if (toPage) {
            setPage(toPage);
        }
    };

    const handlePerRowsChange = async newPerPage => {
        setLoading(true);
        setMessage({});
        await getPCCs(page, perPage, props.selectedRow.account_person_id);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const getPCCs = async (
        toPage,
        toPerPage,
        account_person_id = props.selectedRow.account_person_id,
    ) => {
        setLoading(true);
        toPage = toPage ? toPage : page;
        toPerPage = toPerPage ? toPerPage : perPage;
        try {
            setMessage({});
            const response = await manageClinicsList(
                toPage,
                toPerPage,
                account_person_id,
            );
            setData(response.items);
            setTotalRows(response.total);
            Promise.resolve();
        } catch (error) {
            setMessage({
                message: 'An error has occurrded during data loading.',
            });
            Promise.reject();
        }
        setLoading(false);
    };

    const getProviderNames = providers => {
        return providers.map(provider => {
            return `${provider.first_name} ${provider.last_name}`;
        });
    };

    const columns = [
        {
            name: 'Id',
            selector: row => row.id,
            width: '10%',
        },
        {
            name: 'Clinic',
            selector: row => row.clinic_name,
        },
        {
            name: 'Providers',
            cell: row => {
                const providerNames = getProviderNames(row.providers);
                return (
                    <div key={row.id}>
                        {providerNames.sort().map((name, key) => (
                            <>
                                <span key={key}>{name}</span>
                                {key !== providerNames.length - 1 && (
                                    <span>, </span>
                                )}
                            </>
                        ))}
                    </div>
                );
            },
        },
        {
            key: 'action',
            text: 'Action',
            name: 'Action',
            className: 'action',
            width: '100px',
            align: 'left',
            sortable: false,
            cell: row => {
                return (
                    <Fragment>
                        <span className="table-action-icon">
                            <EditIcon
                                className="icon-color"
                                onClick={() => {
                                    onManageProviders(row);
                                }}
                            />
                        </span>
                        &nbsp; &nbsp;
                        <DeleteIcon
                            color="red"
                            className="table-action-icon red"
                            onClick={() => onDelete(row)}
                        />
                    </Fragment>
                );
            },
        },
    ];

    useEffect(() => {
        setModalShow(props.open);
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable();
        }
    };

    useEffect(() => {
        getPCCs(page, perPage, props.selectedRow.account_person_id);
    }, [props.accountId]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {openAddForm && (
                <AddPccClinics
                    open={openAddForm}
                    setAddForm={setOpenAddForm}
                    selectedRow={props.selectedRow}
                    clinic_id={selectedRow.clinic_id}
                    reloadDataTable={reloadDataTable}
                    setMessage={setMessage}
                />
            )}

            {openmanageProvidersForm && (
                <ManageProviders
                    open={openmanageProvidersForm}
                    setOpenmanageProvidersForm={setOpenmanageProvidersForm}
                    clinic={selectedRow}
                    selectedRow={props.selectedRow}
                    CarePersonClinicId={props.CarePersonClinicId}
                    reloadDataTable={reloadDataTable}
                />
            )}

            <DeleteConfirmation
                showModal={showDeleteConfirmation}
                hideModal={() => setShowDeleteConfirmation(false)}
                confirmModal={DeletePccClinic}
                deleteId={selectedRow.clinic_id}
                message="Are you sure you want to delete it"
            />

            {!openAddForm &&
                !openmanageProvidersForm &&
                !showDeleteConfirmation && (
                    <AppModal
                        size="lg"
                        show={show}
                        onHide={handleModalClose}
                        backdrop="static"
                        modalTitle="Manage Clinics">
                        <button
                            className="btn btn-primary "
                            style={{
                                float: 'right',
                                marginRight: '10px',
                                marginTop: '10px',
                            }}
                            onClick={() => setOpenAddForm(true)}>
                            + Assign Clinic
                        </button>

                        <div>
                            {message && <MessageContainer message={message} />}
                            <div className="accounts-list-table">
                                <DataTable
                                    width="100%"
                                    columns={columns}
                                    data={data}
                                    progressPending={loading}
                                    pagination
                                    paginationServer
                                    paginationDefaultPage={page}
                                    paginationResetDefaultPage={true}
                                    paginationTotalRows={totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                />
                            </div>
                        </div>
                    </AppModal>
                )}
        </>
    );
};

export default WithLogin(ManageClinics);
