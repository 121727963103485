export const genderList = {
    MALE: 'Male',
    FEMALE: 'Female',
    NONBINARY: 'Nonbinary or Genderqueer',
    TF: 'Transgender female(male to female)',
    TM: 'Transgender male(female to male)',
    PNTD: 'Prefer not to disclose',
};

export const sexAssignedAtBirthList = {
    MALE: 'Male',
    FEMALE: 'Female',
};

export const ethnicityList = [
    'White',
    'Black',
    'Hispanic',
    'Asian/Pacific Islander',
    'American Indian/Alaska Native',
    'Other/Unknown',
];

export const statusList = {
    OI: 'Order initiated',
    OCN: 'Order cancelled',
    OC: 'Order confirmed',
    PG: 'Packaged',
    SH: 'Shipped',
    DV: 'Delivered',
};

export const fulfillmentStates = [
    { value: 'PD', label: 'Pending' },
    { value: 'DN', label: 'Done' },
    { value: 'FL', label: 'Failed' },
];

export const accountRoles = {
    ACM: 'Account Manager',
    PROVIDER: 'Provider',
    PCC: 'Patient Care Collaborator',
    PATIENT: 'Patient',
    PI: 'Principal Investigator',
};

export const dates = {
    START_DATE: '2022-01-01',
};

export const kitActiveState = {
    ACTIVE: 'Active',
    INACTIVE: 'InActive',
};

export const labOrderStatus = {
    OI: 'order initiated',
    LAB_OR: 'Lab - order received',
    LAB_SR: 'Lab - sample received',
    LAB_US: 'Lab - unknown sample',
    LAB_SRJ: 'Lab - sample rejected',
    LAB_RR: 'Lab - result ready',
    LAB_QNS: 'Lab - quantity not sufficient',
    LAB_SPF: 'Lab - sample processing failed',
    LAB_SPC: 'Lab - sample processing canceled',
    LAB_SB: 'Lab - sample batched',
    LAB_UDD: 'Lab - upcoming data delivery',
    PD_S: 'AIQ Job - Success',
    PD_F: 'AIQ Job - Failed',
    PD_R: 'AIQ Job - Running',
};

export const labMonitoringStatus = {
    SAMPLE_RECEIVED: 'KIT_RECEIVED',
    INTERIM_STATUS: 'INTERIM_STATUS',
    RESULT_READY: 'DATA_DELIVERED',
    STATUS_UPDATE_FAILED: 'STATUS_UPDATE_FAILED',
    SAMPLE_PROCESSING_FAILED: 'SAMPLE_PROCESSING_FAILED',
    SAMPLE_PROCESSING_CANCELED: 'SAMPLE_PROCESSING_CANCELED',
    SAMPLE_BATCHED: 'SAMPLE_BATCHED',
    UPCOMING_DATA_DELIVERY: 'UPCOMING_DATA_DELIVERY',
};
