import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { Link, useLocation } from 'react-router-dom';

const AccountSideBar = () => {
    let location = useLocation();
    const [activeKey, setActive] = useState('accounts');
    useEffect(() => {
        if (location.pathname.startsWith('/accounts/account-user/')) {
            setActive('acm');
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <Nav
            as="ul"
            activeKey={activeKey}
            onSelect={selectedKey => setActive(selectedKey)}
            className="nav nav-tabs">
            <Nav.Item as="li">
                <NavLink as={Link} eventKey="accounts" to="/accounts/">
                    Accounts
                </NavLink>
            </Nav.Item>
            <Nav.Item as="li">
                <NavLink as={Link} eventKey="clinics" to="/accounts/clinics/">
                    Sites
                </NavLink>
            </Nav.Item>
            <Nav.Item as="li">
                <NavLink
                    as={Link}
                    eventKey="users"
                    to="/accounts/account-user/">
                    Users
                </NavLink>
            </Nav.Item>

            <Nav.Item as="li">
                <NavLink as={Link} eventKey="patients" to="/accounts/patients/">
                    Patients
                </NavLink>
            </Nav.Item>
        </Nav>
    );
};

export default AccountSideBar;
