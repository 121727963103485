import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { reset_password } from '../services/auth.service';
import MessageContainer from './message.component';

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [searchParams] = useSearchParams();
    const passwordToken = searchParams.get('token');
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [showPasswordRetyped, setShowPasswordRetyped] = useState(false);
    const { register, handleSubmit, setError, reset } = useForm({
        defaultValues: {
            email: '',
        },
    });
    const handleResetPassword = async data => {
        setLoading(true);
        setMessage({});
        try {
            const response = await reset_password(
                data.new_password,
                data.retyped_password,
                passwordToken,
            );
            setMessage({
                type: 'Success',
                message: `${response.detail}`,
            });
            reset();
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }
        setLoading(false);
    };

    return (
        <>
            {message && <MessageContainer message={message} />}
            <ResetPasswordContainer>
                <h4>Reset Password </h4>
                <div className="change-password-form">
                    <form
                        className="row g-2"
                        onSubmit={handleSubmit(handleResetPassword)}>
                        <div className="col-md-12">
                            <label htmlFor="new_password">New Password</label>
                            <div style={{position: 'relative'}}>
                                <input
                                    id="new_password"
                                    type={showPasswordNew ? 'text' : 'password'}
                                    className="form-control"
                                    name="new_password"
                                    {...register('new_password', {
                                        required: true,
                                    })}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        right: 10,
                                        top: '20%',
                                    }}
                                    onClick={() =>
                                        setShowPasswordNew(
                                            password => !password,
                                        )
                                    }>
                                    {showPasswordNew ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="retyped_password">
                                Retyped Password
                            </label>
                            <div style={{position: 'relative'}}>
                                <input
                                    id="retyped_password"
                                    type={showPasswordRetyped ? 'text' : 'password'}
                                    className="form-control"
                                    name="retyped_password"
                                    {...register('retyped_password', {
                                        required: true,
                                    })}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        right: 10,
                                        top: '20%',
                                    }}
                                    onClick={() =>
                                        setShowPasswordRetyped(
                                            password => !password,
                                        )
                                    }>
                                    {showPasswordRetyped ? (
                                        <Visibility />
                                    ) : (
                                        <VisibilityOff />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <button
                                className="btn btn-primary"
                                disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Submit</span>
                            </button>
                        </div>
                    </form>
                </div>
            </ResetPasswordContainer>
        </>
    );
};

const ResetPasswordContainer = styled.div`
    padding: 10% 0 0 20%;
`;

export default ResetPassword;
