import React, { useEffect, useState } from 'react';



import { fetchAdminById, resetPassword } from '../../services/admin.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';


const ViewUser = props => {
    const [message, setMessage] = useState({});
    const [admin, setAdmin] = useState({});
    const [show, setModalShow] = useState(props.open);

    const getAdmins = async () => {
        try {
            const resp = await fetchAdminById(props.selectedRow.id);
            setAdmin(resp);
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };

    const sendResetPasswordMail = async () => {
        try {
            const resp = await resetPassword(props.selectedRow.id);
            setMessage({
                type: 'Success',
                message: `${resp.detail}`,
            });
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };

    useEffect(() => {
        setModalShow(props.open);
        getAdmins();
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable();
        }
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Admin Detail">
            {message && <MessageContainer message={message} />}
            <div className="mt-2">
                <div className="col-md-12">
                    <span className="txt-label">Name</span>
                    <span className="txt-label-value">
                        {admin.first_name} {admin.last_name}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Email</span>
                    <span className="txt-label-value">{admin.email}</span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Mobile Number</span>
                    <span className="txt-label-value">
                        {admin.mobile_number}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Role</span>
                    <span className="txt-label-value">{admin.role_name}</span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Status</span>
                    <span className="txt-label-value">
                        {admin.is_active ? 'Active' : 'Inactive'}
                    </span>
                </div>
                <hr />
                <div className="col-md-12">
                    <button
                        className="btn btn-primary"
                        onClick={sendResetPasswordMail}>
                        <span>Reset Password</span>
                    </button>
                </div>
            </div>
        </AppModal>
    );
};

export default WithLogin(ViewUser);