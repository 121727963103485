import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';

import { ethnicityList, sexAssignedAtBirthList } from '../../constants';
import {
    fetchPatientById,
    updatePatient,
} from '../../services/patient.service';
import { dateFormatDob } from '../../utils';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import PatientProviderAssociation from './patientProviderAssociation.component';

const EditPatient = props => {
    const id = props.selectedRow.id;
    const [show, setModalShow] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});

    const {
        control,
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const getPatient = async () => {
        try {
            const resp = await fetchPatientById(props.accountId, id);
            Object.keys(resp).forEach(key => {
                if (key === 'dob') {
                    setValue(key, moment(resp[key]).toDate());
                } else {
                    setValue(key, resp[key]);
                }
            });
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };

    useEffect(() => {
        setModalShow(props.open);
        getPatient();
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable();
        }
    };

    const savePatient = async data => {
        setLoading(true);
        setMessage({});
        try {
            data.dob = dateFormatDob(data.dob);
            await updatePatient(data, props.accountId, id);
            setMessage({
                type: 'Success',
                message: 'Changes have been saved successfully',
            });
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }
        setLoading(false);
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Update Patient">
            <div className="formView">
                <div className="col-12">
                    <h5>Patient</h5>
                </div>
            </div>

            {message && <MessageContainer message={message} />}
            <form className="row g-2" onSubmit={handleSubmit(savePatient)}>
                <div className="col-md-6">
                    <label htmlFor="subject_id">Subject ID</label>
                    <input
                        type="text"
                        className={
                            errors?.subject_id
                                ? 'form-control is-invalid'
                                : 'form-control'
                        }
                        id="subject_id"
                        placeholder="Enter subject ID"
                        {...register('subject_id', {
                            required: true,
                            maxLength: {
                                value: 255,
                                message:
                                    'Subject ID can be max. 255 char length',
                            },
                        })}
                    />
                    {errors && (
                        <div className="invalid-feedback">
                            {errors.subject_id?.message}
                        </div>
                    )}
                </div>

                <div className="col-md-6">
                    <label htmlFor="sex">Sex</label>
                    <select
                        className="form-control"
                        id="sex"
                        required
                        {...register('sex', { required: true })}>
                        {Object.keys(sexAssignedAtBirthList).map(sex => (
                            <option
                                value={sexAssignedAtBirthList[sex]}
                                key={sex}>
                                {sexAssignedAtBirthList[sex]}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="col-md-6">
                    <label htmlFor="dob">DOB</label>
                    <Controller
                        control={control}
                        id="dob"
                        name="dob"
                        render={({ field }) => (
                            <DatePicker
                                selected={field.value}
                                maxDate={new Date()}
                                onChange={e => setValue('dob', e)}
                                onSelect={e => setValue('dob', e)}
                                dateFormat="MMMM d, yyyy"
                                placeholder="Select a date"
                                required={true}
                            />
                        )}
                    />
                    {errors && (
                        <div className="text-danger">{errors.dob?.message}</div>
                    )}
                </div>
                <div className="col-md-6">
                    <label htmlFor="ethnicity">Ethnicity</label>
                    <select
                        className="form-control"
                        id="ethnicity"
                        required
                        {...register('ethnicity', { required: true })}>
                        {Object.keys(ethnicityList).map(ethnicity => (
                            <option
                                value={ethnicityList[ethnicity]}
                                key={ethnicity}>
                                {ethnicityList[ethnicity]}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="col-md-12">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Submit
                    </button>
                </div>
            </form>

            <>&nbsp;</>

            <Accordion defaultActiveKey="PatientProviderAssociation" flush>
                <Accordion.Item eventKey="PatientProviderAssociation">
                    <Accordion.Header>P.I Association</Accordion.Header>
                    <Accordion.Body>
                        <PatientProviderAssociation
                            accountId={props.selectedRow.account_id}
                            patientId={id}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </AppModal>
    );
};

export default EditPatient;
