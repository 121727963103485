import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { fetchAccountList } from '../../services/account.service';
import { fetchKitOrders } from '../../services/kitOrder.service';
import { customTableStyles, dateTimeFormat } from '../../utils';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';
import AddKitOrder from './kitOrderAdd.component';

const KitOrder = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [openAddForm, setOpenAddForm] = useState(false);
    const [message, setMessage] = useState({});
    const [kitOrderSearchFilters, setKitOrderSearchFilters] = useState({
        searchText: '',
        accountId: '',
    });
    const [accountList, setAccountList] = useState([]);

    const columns = [
        {
            name: 'Order id',
            selector: row => row.id,
            width: '80px',
        },
        {
            name: 'Account',
            selector: row => `${row.account.name}(id: ${row.account_id})`,
        },

        {
            name: 'Product',
            selector: row => row.product.name,
        },
        {
            name: 'Quantity',
            selector: row => row.quantity,
            width: '100px',
        },
        {
            name: 'Site',
            selector: row => row.clinic?.name,
        },
        {
            name: 'Status',
            selector: row => row.current_status,
            width: '10%',
        },
        {
            name: 'Created at',
            selector: row => dateTimeFormat(row.created_at),
        },
        {
            name: 'Updated at',
            selector: row => dateTimeFormat(row.updated_at),
        },
        {
            key: 'action',
            text: 'Action',
            name: 'Action',
            className: 'action',
            width: '75px',
            align: 'left',
            sortable: false,
            cell: row => {
                return (
                    <Fragment>
                        <Link
                            to={`/kit-orders/${row.id}/`}
                            className="table-action-icon">
                            <VisibilityIcon />
                        </Link>
                    </Fragment>
                );
            },
        },
    ];

    const conditionalRowStyles = [
        {
            when: row => row.current_status.toLowerCase() === 'delivered',
            style: {
                backgroundColor: '#e4e5e6',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: row => row.current_status.toLowerCase() !== 'delivered',
            style: row => ({
                backgroundColor: 'inerit',
            }),
        },
    ];

    const handleOpenAddForm = () => {
        setOpenAddForm(true);
    };

    const getAccountList = async () => {
        setLoading(true);
        try {
            const accounts = await fetchAccountList();
            setAccountList(accounts);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during account list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const getKitOrders = async (toPage, toPerPage) => {
        setTableLoading(true);
        setMessage({});
        toPage = toPage ? toPage : page;
        toPerPage = toPerPage ? toPerPage : perPage;
        try {
            const response = await fetchKitOrders(
                toPage,
                toPerPage,
                kitOrderSearchFilters,
            );
            setData(response.items);
            setTotalRows(response.total);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during data loading.',
                type: 'Error',
            });
        }
        setTableLoading(false);
    };

    useEffect(() => {
        getAccountList();
        getKitOrders(1);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = toPage => {
        getKitOrders(toPage);
        setPage(toPage);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getKitOrders(page, newPerPage);
        setPerPage(newPerPage);
    };

    const reloadDataTable = toPage => {
        toPage = toPage ? toPage : page;
        getKitOrders(toPage);
        if (toPage) {
            setPage(toPage);
        }
    };

    const { register, handleSubmit, reset } = useForm({
        defaultValues: {},
    });

    useEffect(() => {
        getKitOrders(1);
        setPage(1);
    }, [kitOrderSearchFilters]); // eslint-disable-line react-hooks/exhaustive-deps

    const setSearchFilters = async data => {
        setKitOrderSearchFilters({
            searchText: data.searchText.trim(),
            accountId: data.accountId,
        });
    };

    return (
        <div>
            <div className="pageHeader">
                <span id="header">
                    <h4>Kit orders</h4>
                </span>
                <span id="header-add-btn">
                    <button
                        className="btn btn-primary"
                        onClick={handleOpenAddForm}>
                        + Add kit order
                    </button>
                </span>
            </div>

            <hr />

            {openAddForm && (
                <AddKitOrder
                    open={openAddForm}
                    setOpenAddForm={setOpenAddForm}
                    reloadDataTable={reloadDataTable}
                />
            )}
            {message && <MessageContainer message={message} />}

            <div className="new-line" />
            <form className="row g-2" onSubmit={handleSubmit(setSearchFilters)}>
                <div className="col-md-3">
                    <input
                        type="text"
                        className="form-control"
                        id="search"
                        placeholder="Enter kit order UID"
                        {...register('searchText')}
                    />
                </div>
                <div className="col-md-3">
                    <select
                        className="form-control"
                        id="accountId"
                        {...register('accountId')}>
                        <option value="" key="0">
                            Select an account
                        </option>
                        {accountList.map(account => (
                            <option value={account.id} key={account.id}>
                                {account.name}(id: {account.id})
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-6">
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Search
                    </button>
                    <span className="mx-1"></span>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={loading}
                        onClick={() => {
                            reset();
                        }}>
                        Reset
                    </button>
                </div>
            </form>
            <div className="new-line"></div>
            <div className="accounts-list-table">
                <DataTable
                    width="100%"
                    customStyles={customTableStyles}
                    columns={columns}
                    data={data}
                    progressPending={tableLoading}
                    pagination
                    paginationServer
                    paginationDefaultPage={page}
                    paginationResetDefaultPage={true}
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    conditionalRowStyles={conditionalRowStyles}
                />
            </div>
        </div>
    );
};

export default WithLogin(KitOrder);
