import EditIcon from '@mui/icons-material/Edit';
import PsychologyIcon from '@mui/icons-material/Psychology';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { clearSpinner, setSpinner } from '../../app_store/actions/spinner';
import { dates, labOrderStatus } from '../../constants';
import ExcelIcon from '../../icons/excelIcon';
import { fetchLabOrders, runAiq } from '../../services/labOrder.service';
import { fetchPartnerOptions } from '../../services/partner.service';
import {
    customTableStyles,
    customTableStylesAccountView,
    dateTimeFormat,
} from '../../utils';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';
import EditLabOrder from './labOrderEdit.component';
import ViewLabOrder from './labOrderView.component';
import ViewExportLabOrderForm from './viewExportLabOrderForm.component';

const LabOrderList = props => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [page, setPage] = useState(1);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [openViewForm, setOpenViewForm] = useState(false);
    const [openExportForm, setOpenExportForm] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [message, setMessage] = useState({});
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const showFilters = props.showFilters == null ? true : false;
    const [partners, setPartners] = useState([]);

    const [labOrderSearchFilters, setLabOrderSearchFilters] = useState({
        searchText: '',
        accountId: props.accountId,
        currentStatus: '',
        startDate: '',
        endDate: '',
    });

    const dispatch = useDispatch();

    const columns = [
        {
            name: 'ID',
            selector: row => row.id,
            width: '75px',
        },
        {
            name: 'Site',
            selector: row => row.clinic_name,
        },
        {
            name: 'Provider Name',
            selector: row =>
                `${row.provider.account_person.first_name} ${row.provider.account_person.last_name}`,
        },
        {
            name: 'Subject ID',
            selector: row => row.patient.subject_id,
        },
        {
            name: 'Sample Id',
            selector: row => row.sample_id,
            width: '8%',
        },
        {
            name: 'Status',
            selector: row => (row.mode === 'Draft' ? 'Draft' : row.status),
            width: '8%',
        },

        {
            name: 'Result',
            selector: row => row.pheno_type,
            width: '7%',
        },
        {
            name: 'Score',
            selector: row => row.score,
            width: '5%',
        },
        {
            name: 'BMI',
            selector: row => row.bmi,
            width: '5%',
        },
        {
            name: 'Created at',
            selector: row => dateTimeFormat(row.created_at),
        },
        {
            key: 'action',
            text: 'Action',
            name: 'Action',
            className: 'action',
            width: '180px',
            align: 'left',
            sortable: false,
            cell: row => {
                return (
                    <>
                        {!row.report?.is_published_provider && (
                            <>
                                <span
                                    className="table-action-icon"
                                    title="Edit">
                                    <EditIcon
                                        className="icon-color"
                                        onClick={() => {
                                            onEdit(row);
                                        }}
                                    />
                                </span>
                                &nbsp;
                            </>
                        )}
                        <span className="table-action-icon" title="Details">
                            <VisibilityIcon
                                className="icon-color"
                                onClick={() => {
                                    onView(row);
                                }}
                            />
                        </span>
                        <>&nbsp;</>

                        {(row.status.toLowerCase() ===
                            'AIQ Job - Success'.toLowerCase() ||
                            row.status.toLowerCase() ===
                                'AIQ Job - Failed'.toLowerCase() ||
                            row.status.toLowerCase() ===
                                'Report generation completed'.toLowerCase() ||
                            row.status.toLowerCase() ===
                                'Report revoked'.toLowerCase()) &&
                            !row.report?.is_published_provider && (
                                <>
                                    <span
                                        className="table-action-icon"
                                        title="Run AIQ">
                                        <PsychologyIcon
                                            className="icon-color"
                                            onClick={() => reRunAiq(row)}
                                        />
                                    </span>
                                    &nbsp;
                                </>
                            )}
                    </>
                );
            },
        },
    ];

    const conditionalRowStyles = [
        {
            when: row => row.status === 'AIQ Job - Success',
            style: {
                backgroundColor: '#e4e5e6',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ];

    const onEdit = row => {
        setOpenEditForm(true);
        setSelectedRow(row);
    };
    const onView = row => {
        setOpenViewForm(true);
        setSelectedRow(row);
    };

    const reRunAiq = async row => {
        dispatch(setSpinner());
        try {
            const resp = await runAiq(row);

            dispatch(clearSpinner());
            reloadDataTable();
            setMessage({
                type: 'Success',
                message: `${resp.detail}`,
            });
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
        dispatch(clearSpinner());
    };

    const getPartnerOptions = async () => {
        setLoading(true);
        try {
            const partnerList = await fetchPartnerOptions();
            setPartners(partnerList);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during partners loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };
    const getLabOrders = async (toPage, toPerPage) => {
        setTableLoading(true);
        setMessage({});
        toPage = toPage ? toPage : page;
        toPerPage = toPerPage ? toPerPage : perPage;
        try {
            const response = await fetchLabOrders(
                toPage,
                toPerPage,
                labOrderSearchFilters,
            );
            setData(response.items);
            setTotalRows(response.total);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during data loading.',
                type: 'Error',
            });
        }
        setTableLoading(false);
    };

    const handlePageChange = toPage => {
        getLabOrders(toPage);
        setPage(toPage);
    };

    const handlePerRowsChange = (newPerPage, page) => {
        getLabOrders(page, newPerPage);
        setPerPage(newPerPage);
    };

    const reloadDataTable = toPage => {
        toPage = toPage ? toPage : page;
        getLabOrders(toPage);
        if (toPage) {
            setPage(toPage);
        }
    };

    const { control, setValue, getValues, register, handleSubmit, reset } =
        useForm({
            defaultValues: {},
        });

    useEffect(() => {
        setLoading(true);
        getPartnerOptions();
        getLabOrders(1);
        setPage(1);
        setLoading(false);
    }, [labOrderSearchFilters]); // eslint-disable-line react-hooks/exhaustive-deps

    const setSearchFilters = async data => {
        setLabOrderSearchFilters({
            partnerId: data.partnerId,
            searchText: data.searchText.trim(),
            accountId: data.accountId ? data.accountId : props.accountId,
            currentStatus: data.currentStatus,
            startDate: startDate,
            endDate: endDate,
        });
    };

    return (
        <>
            {openEditForm && (
                <EditLabOrder
                    open={openEditForm}
                    setEditForm={setOpenEditForm}
                    selectedRow={selectedRow}
                    reloadDataTable={reloadDataTable}
                />
            )}
            {openViewForm && (
                <ViewLabOrder
                    open={openViewForm}
                    setEditForm={setOpenViewForm}
                    selectedRow={selectedRow}
                    reloadDataTable={reloadDataTable}
                />
            )}

            {openExportForm && (
                <ViewExportLabOrderForm
                    open={openExportForm}
                    setEditForm={setOpenExportForm}
                    filter={labOrderSearchFilters}
                    partnerList={partners}
                />
            )}

            <div>
                {showFilters && (
                    <>
                        <div className="pageHeader">
                            <span id="header">
                                <h4>Lab orders</h4>
                            </span>
                        </div>
                        <hr />

                        {message && <MessageContainer message={message} />}
                        <form
                            className="row g-2"
                            onSubmit={handleSubmit(setSearchFilters)}>
                            <div className="col-md-2">
                                <label htmlFor="partnerId">Partner:</label>
                                <select
                                    className="form-control"
                                    id="partnerId"
                                    {...register('partnerId')}>
                                    <option value="" key="0">
                                        All
                                    </option>
                                    {partners.map(partner => (
                                        <option
                                            value={partner.id}
                                            key={partner.id}>
                                            {partner.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="search">
                                    Search by sample :
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="search"
                                    placeholder="Enter kit sample id"
                                    {...register('searchText')}
                                />
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="start_date">Status:</label>
                                <select
                                    className="form-control"
                                    id="Status"
                                    {...register('currentStatus')}>
                                    <option value="" key="0">
                                        All
                                    </option>
                                    {Object.keys(labOrderStatus).map(status => (
                                        <option
                                            value={labOrderStatus[status]}
                                            key={status}>
                                            {labOrderStatus[status]}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2">
                                <div className="row">
                                    <label htmlFor="start_date">
                                        Start Date :
                                    </label>
                                    <div>
                                        <Controller
                                            control={control}
                                            id="start_date"
                                            name="start_date"
                                            render={({ field }) => (
                                                <DatePicker
                                                    selected={field.value}
                                                    minDate={
                                                        new Date(
                                                            dates.START_DATE,
                                                        )
                                                    }
                                                    maxDate={new Date()}
                                                    onChange={e => {
                                                        if (!e) {
                                                            setStartDate('');
                                                            setValue(
                                                                'start_date',
                                                                '',
                                                            );
                                                        } else {
                                                            setStartDate(
                                                                moment(
                                                                    e,
                                                                ).format(
                                                                    'YYYY-MM-DD HH:mm',
                                                                ),
                                                            );
                                                            setValue(
                                                                'start_date',
                                                                e,
                                                            );
                                                            if (
                                                                getValues(
                                                                    'start_date',
                                                                ) >
                                                                getValues(
                                                                    'end_date',
                                                                )
                                                            ) {
                                                                setValue(
                                                                    'end_date',
                                                                    null,
                                                                );
                                                                setEndDate('');
                                                            }
                                                        }
                                                    }}
                                                    placeholderText="Select start date"
                                                    dateFormat="MMMM d, yyyy"
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="row">
                                    <div>
                                        <label htmlFor="end_date">
                                            End Date :
                                        </label>
                                    </div>
                                    <div>
                                        <Controller
                                            control={control}
                                            id="end_date"
                                            name="end_date"
                                            render={({ field }) => (
                                                <DatePicker
                                                    selected={field.value}
                                                    onChange={e => {
                                                        if (!e) {
                                                            setEndDate('');
                                                            setValue(
                                                                'end_date',
                                                                '',
                                                            );
                                                        } else {
                                                            var newEndDate =
                                                                new Date(
                                                                    moment(
                                                                        e,
                                                                    ).format(
                                                                        'YYYY-MM-DD HH:mm',
                                                                        e,
                                                                    ),
                                                                );
                                                            newEndDate.setHours(
                                                                23,
                                                            );
                                                            newEndDate.setMinutes(
                                                                59,
                                                            );
                                                            setEndDate(
                                                                moment(
                                                                    newEndDate,
                                                                ).format(
                                                                    'YYYY-MM-DD HH:mm',
                                                                ),
                                                            );
                                                            setValue(
                                                                'end_date',
                                                                e,
                                                            );
                                                        }
                                                    }}
                                                    placeholderText="Select end date"
                                                    maxDate={new Date()}
                                                    minDate={getValues(
                                                        'start_date',
                                                    )}
                                                    dateFormat="MMMM d, yyyy"
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div>&nbsp;</div>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={loading}>
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    Search
                                </button>
                                <span className="mx-1"></span>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={loading}
                                    onClick={() => {
                                        setStartDate('');
                                        setEndDate('');
                                        reset();
                                    }}>
                                    Reset
                                </button>
                                <span className="mx-1"></span>
                                <button
                                    disabled={loading}
                                    className="btn btn-primary"
                                    onClick={e => setOpenExportForm(true)}>
                                    <ExcelIcon />
                                    &nbsp; Export
                                </button>
                            </div>
                        </form>
                    </>
                )}
                <span
                    color="primary"
                    className="float-end"
                    style={{ marginRight: '10px' }}
                    onClick={() => reloadDataTable()}>
                    <SyncIcon />
                </span>
                <div className="new-line"></div>
                <div className="accounts-list-table">
                    <DataTable
                        width="100%"
                        customStyles={
                            props.accountId
                                ? customTableStylesAccountView
                                : customTableStyles
                        }
                        columns={columns}
                        data={data}
                        progressPending={tableLoading}
                        paginationPerPage={perPage}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        conditionalRowStyles={conditionalRowStyles}
                    />
                </div>
            </div>
        </>
    );
};

export default WithLogin(LabOrderList);
