import AuthService from '../../services/auth.service';
import {
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    REFRESH_TOKEN,
    SET_MESSAGE,
} from './types';

export const login = (email, password) => dispatch => {
    return AuthService.login(email, password).then(
        data => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: { user: data },
            });

            return Promise.resolve();
        },
        error => {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.detail) ||
                error.message ||
                error.toString();

            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        },
    );
};

export const logout = () => dispatch => {
    return AuthService.logout().then(
        data => {
            dispatch({
                type: LOGOUT,
            });
            return Promise.resolve();
        },
        error => {
            return Promise.reject();
        },
    );
};

export const refreshToken = accessToken => dispatch => {
    dispatch({
        type: REFRESH_TOKEN,
        payload: accessToken,
    });
};

export const checkLogin = () => {
    const user = JSON.parse(localStorage.getItem('user_me'));
    return user ? true : false;
};
