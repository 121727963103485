import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
    PccClinicsDropdown,
    assignPccClinic,
} from '../../services/pcc.service';
import AppModal from '../appModal.component';
import WithLogin from '../withLogin.component';

const AddPccClinics = props => {
    const [show, setModalShow] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [clinics, setClinics] = useState([]);

    const onChangeClinicName = clinic_name => {
        const clinic_ids = [];
        Object.keys(clinic_name).forEach(k => {
            clinic_ids.push(clinic_name[k]['id']);
        });
        setValue('clinic_ids', clinic_ids);
    };

    const clinicListingPCC = async account_id => {
        setLoading(true);
        try {
            const resp = await PccClinicsDropdown(account_id);
            const clinics = resp.items.map(clinics => {
                return {
                    id: clinics.clinic_details.id,
                    name: clinics.clinic_details.name,
                };
            });

            setClinics(clinics);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during site list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const savePccClinic = async formData => {
        setLoading(true);

        try {
            const data = {
                account_person_id: props.selectedRow.account_person_id,
                clinic_id: formData.clinic_ids.pop(),
            };
            await assignPccClinic(data);
            props.reloadDataTable();
            props.setAddForm(false);
            props.setMessage({
                type: 'Success',
                message: `Site added successfully`,
            });
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const handleModalClose = () => {
        setModalShow(false);
        props.setAddForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable(1);
        }
    };

    useEffect(() => {
        setModalShow(props.open);
        clinicListingPCC(props.selectedRow.account_id);
        setMessage({});
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Assign Clinic">
            <div className="formView">
                <form
                    className="row g-2"
                    onSubmit={handleSubmit(savePccClinic)}>
                    <div className="col-md-8">
                        <label htmlFor="Clinics">Assign Clinics</label>
                        <Controller
                            control={control}
                            id="Clinics"
                            name="clinic_id"
                            render={() => (
                                <Multiselect
                                    className="col-md-6"
                                    displayValue="name"
                                    isObject={true}
                                    singleSelect={true}
                                    options={clinics}
                                    onSelect={clinic_name => {
                                        onChangeClinicName(clinic_name);
                                    }}
                                />
                            )}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors?.clinic_id?.message}
                            </div>
                        )}
                    </div>
                    <div className="col-md-12">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Assign
                        </button>
                    </div>
                </form>
            </div>
        </AppModal>
    );
};

export default WithLogin(AddPccClinics);
