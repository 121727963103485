import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';

import { fetchAccountList } from '../../services/account.service';
import {
    deleteKit,
    fetchKitInventories,
} from '../../services/kitInventories.service';
import { fetchKitOrderList } from '../../services/kitOrder.service';
import {
    customTableStyles,
    customTableStylesAccountView,
    dateTimeFormat,
} from '../../utils';
import DeleteConfirmation from '../deleteConfirmation.component';
import InfoToolTip from '../infoToolTip.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';
import ActivateKitInventory from './activateKitInventory.component';
import EditKitInventory from './kitInventoriesEdit.Component';
import AddKitInventory from './kitInventoryAdd.component';

const KitInventories = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const [openAddForm, setOpenAddForm] = useState(false);
    const [message, setMessage] = useState({});
    const [kitInventorySearchFilters, setKitInventorySearchFilters] = useState({
        searchText: '',
        accountId: props.accountId,
        kitOrderId: '',
    });
    const [accountList, setAccountList] = useState([]);
    const [kitOrderList, setKitOrderList] = useState([]);
    const [openActivateKitForm, setOpenActivateKitForm] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    const [filterAccountId, setFilterAccountId] = useState('');
    const [kitOrderId, setFilterOrderId] = useState('');

    const showFilters = props.showFilters == null ? true : false;

    const columns = [
        {
            name: 'id',
            selector: row => row.id,
        },
        {
            name: 'Account',
            selector: row => row.account_name,
        },
        {
            name: 'Kit Order Id',
            selector: row => row.kit_order_id,
        },
        {
            name: 'Product Name',
            selector: row => row.kit_product_name,
        },
        {
            name: 'Lot no',
            selector: row => row.lot_no,
        },
        {
            name: 'Device ID',
            selector: row => row.unique_device_id,
        },
        {
            name: 'Sample ID',
            selector: row => row.sample_id,
        },
        {
            name: 'Site',
            selector: row => row.clinic_name,
        },
        {
            name: 'Lab order',
            selector: row => row.lab_order_id,
        },
        {
            name: 'Subject ID',
            selector: row => row.subject_id ?? '',
        },
        {
            name: (
                <>
                    Active?{' '}
                    <InfoToolTip text="Active status means provider can use this sample id while creating lab order" />
                </>
            ),
            selector: row => (row.is_active ? 'Yes' : 'No'),
        },
        {
            name: 'Expiry',
            selector: row => dateTimeFormat(row.expiry),
        },
        {
            name: 'Created at',
            selector: row => dateTimeFormat(row.created_at),
        },
        {
            name: 'Updated at',
            selector: row => dateTimeFormat(row.updated_at),
        },
        {
            key: 'action',
            text: 'Action',
            name: 'Action',
            className: 'action',
            align: 'left',
            cell: row => {
                return (
                    <>
                        {!row.lab_order_id && (
                            <>
                                <span
                                    className="table-action-icon"
                                    title="Edit">
                                    <EditIcon
                                        className="icon-color"
                                        onClick={() => {
                                            onEdit(row);
                                        }}
                                    />
                                </span>
                                <span
                                    className="table-action-icon"
                                    title="Delete">
                                    <DeleteIcon
                                        color="red"
                                        className="table-action-icon red"
                                        onClick={() => {
                                            onDelete(row);
                                        }}
                                    />
                                </span>
                                &nbsp;
                            </>
                        )}
                    </>
                );
            },
        },
    ];

    const onEdit = async row => {
        setOpenEditForm(true);
        setSelectedRow(row);
    };

    const onDelete = async row => {
        setShowDeleteConfirmation(true);
        setSelectedRow(row);
    };
    const handleOpenAddForm = () => {
        setOpenAddForm(true);
    };

    const deleteKitInventory = async kit => {
        setMessage({});
        try {
            const resp = await deleteKit(kit.id);
            reloadDataTable(page);
            setMessage({
                type: 'Success',
                message: `${resp.message}`,
            });
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
        setShowDeleteConfirmation(false);
    };

    const getAccountList = async () => {
        setLoading(true);
        try {
            const accounts = await fetchAccountList();
            setAccountList(accounts);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during account list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };
    const getKitOrderList = async () => {
        setFilterOrderId();
        if (filterAccountId === '') {
            setKitOrderList([]);
        } else {
            try {
                const kitOrders = await fetchKitOrderList(filterAccountId, [
                    'OC',
                    'PG',
                    'SH',
                    'DV',
                ]);
                setKitOrderList(kitOrders);
            } catch (error) {
                setMessage({
                    detail: 'An error has occurrded during product list loading.',
                    type: 'Error',
                });
            }
        }
    };

    useEffect(() => {
        getKitOrderList();
        setValue('account_id', filterAccountId);
    }, [filterAccountId]); // eslint-disable-line react-hooks/exhaustive-deps

    const getKitInventories = async (toPage, toPerPage) => {
        setLoading(true);
        setMessage({});
        toPage = toPage ? toPage : page;
        toPerPage = toPerPage ? toPerPage : perPage;
        setTotalRows(0);
        try {
            const response = await fetchKitInventories(
                toPage,
                toPerPage,
                kitInventorySearchFilters,
            );
            setData(response.items);
            setTotalRows(response.total);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during data loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        getAccountList();
        if (showFilters) {
            getKitInventories(1);
        } else {
            getKitInventories(1, 10, props.accountId);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = toPage => {
        getKitInventories(toPage);
        setPage(toPage);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getKitInventories(page, newPerPage);
        setPerPage(newPerPage);
    };

    const reloadDataTable = toPage => {
        toPage = toPage ? toPage : page;
        getKitInventories(toPage);
        if (toPage) {
            setPage(toPage);
        }
    };

    const { register, handleSubmit, reset, setValue } = useForm({
        defaultValues: {},
    });

    useEffect(() => {
        getKitInventories(1);
        setPage(1);
    }, [kitInventorySearchFilters]); // eslint-disable-line react-hooks/exhaustive-deps

    const setSearchFilters = async data => {
        setKitInventorySearchFilters({
            searchText: data.searchText.trim(),
            accountId: data.accountId,
            kitOrderId: kitOrderId,
        });
    };

    return (
        <div>
            {showFilters && (
                <div className="pageHeader">
                    <span id="header">
                        <h4>Kit inventories</h4>
                    </span>
                    <span id="header-add-btn">
                        <button
                            className="btn btn-primary"
                            onClick={e => {
                                e.preventDefault();
                                setOpenActivateKitForm(true);
                            }}>
                            Activate kit
                        </button>
                    </span>
                    <span className="mx-">&nbsp;</span>
                    <span id="header-add-btn">
                        <button
                            className="btn btn-primary"
                            onClick={handleOpenAddForm}>
                            + Add kit
                        </button>
                    </span>
                </div>
            )}
            {showFilters && <hr />}
            {openActivateKitForm && (
                <ActivateKitInventory
                    open={openActivateKitForm}
                    setOpenAddForm={setOpenActivateKitForm}
                    reloadAccountTable={reloadDataTable}
                />
            )}

            {openAddForm && (
                <AddKitInventory
                    open={openAddForm}
                    setOpenAddForm={setOpenAddForm}
                    reloadAccountTable={reloadDataTable}
                />
            )}
            {message && <MessageContainer message={message} />}
            {showFilters && (
                <form
                    className="row g-2"
                    onSubmit={handleSubmit(setSearchFilters)}>
                    <div className="col-md-3">
                        <input
                            type="text"
                            className="form-control"
                            id="search"
                            placeholder="Enter kit sample id"
                            {...register('searchText')}
                        />
                    </div>
                    <div className="col-md-3">
                        <select
                            className="form-control"
                            id="accountId"
                            {...register('accountId')}
                            onChange={e => {
                                setFilterAccountId(e.target.value);
                            }}>
                            <option value="" key="0">
                                Select an account
                            </option>
                            {accountList.map(account => (
                                <option value={account.id} key={account.id}>
                                    {account.name}(id: {account.id})
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <select
                            className="form-control"
                            id="kitOrderId"
                            {...register('kitOrderId')}
                            onChange={e => setFilterOrderId(e.target.value)}>
                            <option value="" key="0">
                                Please select an order
                            </option>
                            {kitOrderList.map(kitOrder => (
                                <option value={kitOrder.id} key={kitOrder.id}>
                                    {`order Id: ${kitOrder.id}, product: ${kitOrder.product_name}, units:${kitOrder.quantity} `}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Search
                        </button>
                        <span className="mx-1"></span>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}
                            onClick={() => {
                                setFilterOrderId();
                                reset();
                            }}>
                            Reset
                        </button>
                    </div>
                </form>
            )}
            <span
                color="primary"
                className="float-end"
                style={{ marginRight: '10px' }}
                onClick={() => reloadDataTable()}>
                <SyncIcon />
            </span>
            <div className="new-line"></div>
            <div className="accounts-list-table">
                <DataTable
                    width="100%"
                    customStyles={
                        props.accountId
                            ? customTableStylesAccountView
                            : customTableStyles
                    }
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={page}
                    paginationResetDefaultPage={true}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                />
                {showDeleteConfirmation && (
                    <DeleteConfirmation
                        showModal={showDeleteConfirmation}
                        hideModal={() => setShowDeleteConfirmation(false)}
                        confirmModal={deleteKitInventory}
                        deleteId={selectedRow}
                        message="Are you sure you want to delete it"
                    />
                )}
                {openEditForm && (
                    <EditKitInventory
                        open={openEditForm}
                        reloadDataTable={reloadDataTable}
                        setEditForm={setOpenEditForm}
                        selectedRow={selectedRow}
                    />
                )}
            </div>
        </div>
    );
};

export default WithLogin(KitInventories);
