import api from './api';
import { API_URL } from './base';

export const addPatient = (data, accountId) => {
    return api
        .post(`${API_URL}/admin/accounts/${accountId}/patients/`, data)
        .then(resp => {
            return resp.data;
        });
};

export const fetchPatients = (page, perPage, searchText, accountId) => {
    let url = `${API_URL}/admin/accounts/${accountId}/patients/?page=${page}&size=${perPage}`;
    if (searchText) {
        url = `${url}&q=${searchText}`;
    }
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const fetchPatientById = (accountId, id) => {
    return api
        .get(`${API_URL}/admin/accounts/${accountId}/patients/${id}/`)
        .then(resp => {
            return resp.data;
        });
};

export const updatePatient = (data, accountId, id) => {
    return api
        .put(`${API_URL}/admin/accounts/${accountId}/patients/${id}/`, data)
        .then(resp => {
            return resp.data;
        });
};

export const fetchProviders = accountId => {
    return api
        .get(`${API_URL}/admin/accounts/${accountId}/providers/`)
        .then(resp => {
            return resp.data;
        });
};

export const fetchPatientProviders = patientID => {
    return api
        .get(`${API_URL}/admin/accounts/patients/${patientID}/providers/`)
        .then(resp => {
            return resp.data;
        });
};

export const linkPatientProvider = (accountID, provider_id, patient_id) => {
    return api
        .put(
            `${API_URL}/admin/accounts/${accountID}/add-patient-provider-association/`,
            {
                provider_id,
                patient_id,
            },
        )
        .then(resp => {
            return resp.data;
        });
};

export const unlinkPatientProvider = (accountID, provider_id, patient_id) => {
    return api
        .put(
            `${API_URL}/admin/accounts/${accountID}/remove-patient-provider-association/`,
            {
                provider_id,
                patient_id,
            },
        )
        .then(resp => {
            return resp.data;
        });
};
