import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import Select from 'react-select';

import {
    accountRoles,
    ethnicityList,
    sexAssignedAtBirthList,
} from '../../constants';
import { fetchAccountList } from '../../services/account.service';
import { addPatient, fetchProviders } from '../../services/patient.service';
import { dateFormatDob } from '../../utils';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';

const AddPatient = props => {
    const [show, setModalShow] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [providers, setProviders] = useState([]);
    const [dob, setDOB] = useState({});
    const [accountList, setAccountList] = useState([]);
    const [accountId, setAccountId] = useState();

    const handleModalClose = () => {
        setModalShow(false);
        props.setopenAddForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable(1);
        }
    };

    const getAccountList = async () => {
        setLoading(true);
        try {
            const accounts = await fetchAccountList();
            setAccountList(accounts);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during account list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const setDatetime = value => {
        setDOB(moment(value).format('YYYY-MM-DD'));
    };

    const {
        control,
        register,
        handleSubmit,
        setValue,
        setError,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            subject_id: '',
            sex: 'Male',
            dob: '',
            ethnicity: '',
        },
    });

    const onChangeProvider = _provider => {
        setValue('provider_id', _provider.value);
    };

    const getProviders = async accountId => {
        const resp = await fetchProviders(accountId);
        const providers = resp.map(provider => {
            return {
                value: provider.id,
                label: `${provider.first_name} ${provider.last_name} (id:${provider.id})`,
            };
        });

        setProviders(providers);
    };

    useEffect(() => {
        setModalShow(props.open);
        getAccountList();
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleAddPatient = async data => {
        setLoading(true);
        try {
            setMessage({});
            data.dob = dateFormatDob(dob);
            await addPatient(data, data.account_id);
            setMessage({
                type: 'Success',
                message: 'Patient has been created successfully',
            });
            reset();
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }

        setLoading(false);
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Add new patient">
            <div className="formView">
                <form
                    className="row g-2"
                    onSubmit={handleSubmit(handleAddPatient)}>
                    {message && <MessageContainer message={message} />}
                    <div className="col-12">
                        <label htmlFor="account_id">Account</label>
                        <select
                            className={
                                errors?.account_id
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="account_id"
                            {...register('account_id', { required: true })}
                            onChange={e => {
                                setAccountId(e.target.value);
                                getProviders(e.target.value);
                            }}>
                            <option value="" key="0">
                                Select an account
                            </option>
                            {accountList.map(account => (
                                <option value={account.id} key={account.id}>
                                    {account.name}(id: {account.id})
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-12">
                        <label htmlFor="Providers">{accountRoles.PI}</label>
                        <Controller
                            name="provider_id"
                            control={control}
                            render={() => (
                                <Select
                                    isSearchable={true}
                                    key={accountId}
                                    options={providers}
                                    isDisabled={accountId ? false : true}
                                    onChange={onChangeProvider}
                                    placeholder="Please select a P.I"
                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.provider_id && (
                            <div
                                className="invalid-feedback"
                                style={{ display: 'block' }}>
                                Provider is required
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="FirstName">Subject ID</label>
                        <input
                            type="text"
                            className={
                                errors?.subject_id
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="subjectId"
                            placeholder="Enter subject ID"
                            {...register('subject_id', {
                                required: true,
                                maxLength: {
                                    value: 255,
                                    message:
                                        'Subject ID can be max. 255 char length',
                                },
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.subject_id?.message}
                            </div>
                        )}
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="sex">Sex assigned at birth</label>
                        <select
                            className="form-control"
                            id="sex"
                            required
                            {...register('sex', { required: true })}>
                            {Object.keys(sexAssignedAtBirthList).map(sex => (
                                <option
                                    value={sexAssignedAtBirthList[sex]}
                                    key={sex}>
                                    {sexAssignedAtBirthList[sex]}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="dob">DOB</label>
                        <Controller
                            control={control}
                            id="dob"
                            name="dob"
                            render={({ field }) => (
                                <DatePicker
                                    selected={field.value}
                                    onChange={e => setValue('dob', e)}
                                    onSelect={e => setDatetime(e)}
                                    placeholder="Select a date"
                                    maxDate={new Date()}
                                    dateFormat="MMMM d, yyyy"
                                    required={true}
                                />
                            )}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors?.dob?.message}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="ethnicity">Ethnicity</label>
                        <select
                            className="form-control"
                            id="ethnicity"
                            required
                            {...register('ethnicity', { required: true })}>
                            {Object.keys(ethnicityList).map(ethnicity => (
                                <option
                                    value={ethnicityList[ethnicity]}
                                    key={ethnicity}>
                                    {ethnicityList[ethnicity]}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-12">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </AppModal>
    );
};

export default WithLogin(AddPatient);
