import DownloadIcon from '@mui/icons-material/Download';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { fetchAccountList } from '../../services/account.service';
import {
    addKitInventory,
    downloadSampleCsv,
    uploadcsv,
} from '../../services/kitInventories.service';
import { fetchKitOrderList } from '../../services/kitOrder.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';

const AddKitInventory = props => {
    const [show, setModalShow] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [kitOrderList, setKitOrderList] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [isCsvUpload, setCsvUpload] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState({});
    const [selectedKitOrder, setSelectedKitOrder] = useState({});

    const getAccountList = async () => {
        setLoading(true);
        try {
            const accounts = await fetchAccountList();
            setAccountList(accounts);
            setValue('accountId', accounts[0].id);
            if (isCsvUpload) csvSetValue('accountId', accounts[0].id);
            setSelectedAccount(accounts[0]);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during account list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const handleModalClose = () => {
        setModalShow(false);
        props.setOpenAddForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadAccountTable(1, 10);
        }
    };
    useEffect(() => {
        setModalShow(props.open);
        getAccountList();
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const getKitOrderList = async () => {
        if (!selectedAccount?.id > 0) return [];
        try {
            const kitOrders = await fetchKitOrderList(selectedAccount.id, ['OC','PG']);
            setKitOrderList(kitOrders);
            if (kitOrders.length > 0) {
                setSelectedKitOrder(kitOrders[0]);
                setValue('kit_order_id', kitOrders[0].id);
                if (isCsvUpload) csvSetValue('kit_order_id', kitOrders[0].id);
            } else {
                setMessage({
                    message: 'No eligible kit ordered found',
                    type: 'Success',
                });
                setSelectedKitOrder({});
                setValue('kit_order_id', null);
                if (isCsvUpload) csvSetValue('kit_order_id', null);
            }
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during product list loading.',
                type: 'Error',
            });
        }
    };

    useEffect(() => {
        getKitOrderList();
        setValue('account_id', selectedAccount.id);
    }, [selectedAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isCsvUpload) {
            csvSetValue('kit_order_id', selectedKitOrder.id);
            csvSetValue('accountId', selectedAccount.id);
        }else {
            setValue('accountId', selectedAccount.id);
            setValue('kit_order_id', selectedKitOrder.id);
        }
    }, [selectedKitOrder, isCsvUpload]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeSetAccount = accountId => {
        const account = accountList.find(
            account => account.id === parseInt(accountId),
        );
        setSelectedAccount(account);
    };

    const onChangeKitOrderSelection = kitOrderId => {
        const kitOrder = kitOrderList.find(
            kitOrder => kitOrder.id === parseInt(kitOrderId),
        );
        setSelectedKitOrder(kitOrder);
    };

    const {
        control,
        register,
        handleSubmit,
        setError,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            account_id: selectedAccount.id,
            expiry: null,
        },
    });

    const onChangeDateTime = value => {
        setValue('expiry', value);
    };

    const handleKitInventoryCreate = async data => {
        console.log('>>handleKitInventoryCreate>>', data);
        if(!data['account_id']) data['account_id'] = selectedAccount.id;
        setLoading(true);
        try {
            await addKitInventory(data);
            setMessage({
                message: 'Kit order has been added successfully',
                type: 'Success',
            });
            reset();
            setValue('accountId', selectedAccount.id);
            setValue('account_id', selectedAccount.id);
            setValue('kit_order_id', selectedKitOrder.id);
        } catch (error) {
            console.log(error);
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }
        setLoading(false);
    };

    const downloadCsv = async () => {
        try {
            const resp = await downloadSampleCsv();
            const blob = new Blob([resp], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('href', url);
            a.setAttribute('download', 'kitupload.csv');
            a.click();
        } catch (error) {
            setMessage({ message: 'Error in downloading csv', type: 'Error' });
        }
    };
    const {
        register: csvUploadRegister,
        handleSubmit: csvUploadHandleSubmit,
        reset: csvUploadReset,
        setValue: csvSetValue,
    } = useForm({
        defaultValues: {
            file: null,
            accountId: selectedAccount.id,
            kit_order_id: selectedKitOrder.id,
        },
    });

    const handleCsvUpload = async data => {
        console.log(data);
        setLoading(true);
        const formData = new FormData();
        formData.append('account_id', selectedAccount.id);
        formData.append('kit_order_id', selectedKitOrder.id);
        if (!data.file) {
            setMessage({ message: 'Please select csv file', type: 'Error' });
            setLoading(false);
            return;
        }
        formData.append('csv_file', data.file[0]);
        try {
            const resp = await uploadcsv(formData);
            setMessage({ message: resp.detail, type: 'Success' });
            csvUploadReset();
            csvSetValue('accountId', selectedAccount.id);
            csvSetValue('kit_order_id', selectedKitOrder.id);
        } catch (error) {
            setMessage({ message: error.response.data.detail, type: 'Error' });
        }
        setLoading(false);
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Add kit">
            {message && message.type && <MessageContainer message={message} />}
            {!isCsvUpload && (
                <>
                    <div className="col-md-8">
                        <button
                            style={{ width: '100%' }}
                            className="btn btn-primary"
                            onClick={e => {
                                e.preventDefault();
                                setCsvUpload(true);
                            }}>
                            Upload by csv
                        </button>
                    </div>
                    <div className="col-md-8" style={{ textAlign: 'center' }}>
                        <label>
                            <b>OR</b>
                        </label>
                    </div>
                </>
            )}

            <div className="formView">
                {isCsvUpload ? (
                    <form
                        className="row g-2"
                        onSubmit={csvUploadHandleSubmit(handleCsvUpload)}>
                        <div className="col-md-8">
                            <Link
                                onClick={e => {
                                    e.preventDefault();
                                    downloadCsv();
                                }}>
                                <DownloadIcon />
                                Download sample csv
                            </Link>
                        </div>
                        <div className="new-line"></div>
                        <div className="col-8">
                            <label htmlFor="account">Account</label>
                            <select
                                className="form-control"
                                id="account"
                                {...csvUploadRegister('accountId', {
                                    onChange: e => {
                                        onChangeSetAccount(e.target.value);
                                    },
                                })}>
                                {accountList.map(account => (
                                    <option value={account.id} key={account.id}>
                                        {account.name}(id: {account.id})
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-8">
                            <label htmlFor="kit_order_id">Order Id</label>
                            <select
                                className="form-control"
                                id="kit_order_id"
                                {...csvUploadRegister('kit_order_id', {
                                    onChange: e => {
                                        onChangeKitOrderSelection(
                                            e.target.value,
                                        );
                                    },
                                })}>
                                {kitOrderList.map(kitOrder => (
                                    <option
                                        value={kitOrder.id}
                                        key={kitOrder.id}>
                                        {`order Id: ${kitOrder.id}, product: ${kitOrder.product_name}, units:${kitOrder.quantity} kits:${kitOrder.item_counts} `}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-8">
                            <Form.Group controlId="formFile" className="mb-3">
                                <Form.Label>CSV file</Form.Label>
                                <Form.Control
                                    type="file"
                                    {...csvUploadRegister('file')}
                                />
                            </Form.Group>
                        </div>
                        <div className="new-line"></div>
                        <div className="col-md-8">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                Upload
                            </button>
                            <span className="mx-1"></span>
                            <button
                                type="Cancel"
                                className="btn btn-secondary"
                                disabled={loading}
                                onClick={e => {
                                    e.preventDefault();
                                    reset();
                                    setCsvUpload(false);
                                }}>
                                Cancel
                            </button>
                        </div>
                    </form>
                ) : (
                    <form
                        className="row g-2"
                        onSubmit={handleSubmit(handleKitInventoryCreate)}>
                        <div className="col-8">
                            <label htmlFor="account">Account</label>
                            <select
                                className="form-control"
                                id="account"
                                {...register('accountId', {
                                    onChange: e => {
                                        onChangeSetAccount(e.target.value);
                                    },
                                })}>
                                {accountList.map(account => (
                                    <option value={account.id} key={account.id}>
                                        {account.name}(id: {account.id})
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-8">
                            <label htmlFor="kit_order_id">Order Id</label>
                            <select
                                className="form-control"
                                id="kit_order_id"
                                {...register('kit_order_id', {
                                    onChange: e => {
                                        onChangeKitOrderSelection(
                                            e.target.value,
                                        );
                                    },
                                })}>
                                {kitOrderList.map(kitOrder => (
                                    <option
                                        value={kitOrder.id}
                                        key={kitOrder.id}>
                                        {`order Id: ${kitOrder.id}, product: ${kitOrder.product_name}, units:${kitOrder.quantity} kits:${kitOrder.item_counts} `}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-8">
                            <label htmlFor="unique_device_id">
                                Device Unique Id
                            </label>
                            <input
                                type="text"
                                className={
                                    errors?.unique_device_id
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                id="unique_device_id"
                                placeholder="Enter device id"
                                {...register('unique_device_id', {
                                    required: true,
                                })}
                            />
                        </div>
                        <div className="col-md-8">
                            <label htmlFor="sampleId">Sample Id</label>
                            <input
                                type="text"
                                className={
                                    errors?.sample_id
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                id="sampleId"
                                placeholder="Enter sample id"
                                {...register('sample_id', {
                                    required: true,
                                })}
                            />
                            {errors && (
                                <div className="invalid-feedback">
                                    {errors.sample_id?.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-8">
                            <label htmlFor="lotNo">Lot No</label>
                            <input
                                type="text"
                                className={
                                    errors?.lot_no
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                id="lotNo"
                                placeholder="Enter lot no."
                                {...register('lot_no', {
                                    required: true,
                                })}
                            />
                            {errors && (
                                <div className="invalid-feedback">
                                    {errors.lot_no?.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-8">
                            <label htmlFor="expiry">Expiry Date</label>
                            <Controller
                                control={control}
                                id="expiry"
                                name="expiry"
                                render={({ field }) => (
                                    <DatePicker
                                        selected={field.value}
                                        showTimeSelect
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        onChange={datetime =>
                                            onChangeDateTime(datetime)
                                        }
                                    />
                                )}
                            />
                            {errors && (
                                <div className="invalid-feedback">
                                    {errors?.expiry?.message}
                                </div>
                            )}
                        </div>

                        <div className="col-md-12">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                Create
                            </button>
                            <span className="mx-1"></span>
                            <button
                                type="Cancel"
                                className="btn btn-secondary"
                                disabled={loading}
                                onClick={e => {
                                    e.preventDefault();
                                    reset();
                                    handleModalClose();
                                }}>
                                Cancel
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </AppModal>
    );
};

export default WithLogin(AddKitInventory);
