import api from './api';
import { API_URL } from './base';

export const fetchAccountUsers = (page, perPage, searchText, accountId) => {
    let url = `${API_URL}/admin/account/account-persons/?page=${page}&size=${perPage}`;
    if (accountId) {
        url = `${url}&account_id=${accountId}`;
    }
    if (searchText) {
        url = `${url}&q=${searchText}`;
    }
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const createAccountUser = data => {
    return api
        .post(`${API_URL}/admin/accounts/${data.account_id}/create-user/`, data)
        .then(resp => {
            return resp.data;
        });
};

export const modifyAccountUser = data => {
    return api
        .put(
            `${API_URL}/admin/accounts/account-persons/${data.account_person_id}/`,
            data,
        )
        .then(resp => {
            return resp.data;
        });
};

export const fetchAccountUserDetails = account_person_id => {
    const url = `${API_URL}/admin/account/account-persons/${account_person_id}/`;
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const fetchTitleList = () => {
    const url = `${API_URL}/admin/account/user-titles/`;
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const fetchAccRoles = () => {
    const url = `${API_URL}/user/roles/`;
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const deleteAccountUser = id => {
    return api
        .delete(`${API_URL}/admin/account/account-person/${id}/`)
        .then(resp => {
            return resp.data;
        });
};

// ACM
export const fetchAccountManagerbyId = id => {
    const url = `${API_URL}/admin/account-managers/${id}/`;
    return api.get(url).then(resp => {
        return resp.data;
    });
};
