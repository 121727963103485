import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInputWithCountry from 'react-phone-number-input/react-hook-form';

import { genderList } from '../../constants';
import { addAdmin } from '../../services/admin.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';

const AddAdmin = props => {
    const [show, setModalShow] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});

    const handleModalClose = () => {
        setModalShow(false);
        props.setOpenAddForm(false);
        props.reloadDataTable(1);
    };

    useEffect(() => {
        setModalShow(props.open);
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        control,
        reset,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {
            first_name: '',
            last_name: '',
            gender: 'MALE',
            dob: '',
            mobile_number: '',
            role_name: 'Admin',
        },
    });

    const handleCreateAdmin = async data => {
        setLoading(true);
        try {
            setMessage({});
            await addAdmin(data);
            setMessage({
                type: 'Success',
                message: 'Account has been created successfully',
            });
            reset();
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }

        setLoading(false);
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Add Admin user">
            <div className="formView">
                <form
                    className="row g-2"
                    onSubmit={handleSubmit(handleCreateAdmin)}>
                    {message && message.type && (
                        <MessageContainer message={message} />
                    )}
                    <div className="col-12">
                        <h5>Admin</h5>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="FirstName">First name</label>
                        <input
                            type="text"
                            className={
                                errors?.first_name
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="FirstName"
                            placeholder="Enter first name"
                            {...register('first_name', {
                                required: true,
                                maxLength: {
                                    value: 200,
                                    message:
                                        'First name can be max. 200 char length',
                                },
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.first_name?.message.replace('', '')}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="LastName">Last name</label>
                        <input
                            type="text"
                            className={
                                errors?.last_name
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="LastName"
                            placeholder="Enter last name"
                            {...register('last_name', {
                                required: true,
                                maxLength: {
                                    value: 200,
                                    message:
                                        'Last name can be max. 200 char length',
                                },
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.last_name?.message.replace('', '')}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="gender">Gender</label>
                        <select
                            className="form-control"
                            id="gender"
                            required
                            {...register('gender', { required: true })}>
                            {Object.keys(genderList).map(gender => (
                                <option value={genderList[gender]} key={gender}>
                                    {genderList[gender]}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="Email">Email</label>
                        <input
                            type="text"
                            className={
                                errors?.email
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="Email"
                            placeholder="Enter email"
                            {...register('email', { required: true })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.email?.message.replace('', '')}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="mobile">Mobile Number</label>
                        <PhoneInputWithCountry
                            name="mobile_number"
                            countries={['US']}
                            control={control}
                            defaultCountry="US"
                            placeholder="Enter phone number"
                            rules={{
                                required: true,
                                validate: isPossiblePhoneNumber,
                            }}
                            className={
                                errors?.mobile_number
                                    ? 'form-control is-invalid'
                                    : 'phone-number'
                            }
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.mobile_number?.message.replace('', '')}
                            </div>
                        )}
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.contact_office_number?.message.replace(
                                    '',
                                    '',
                                )}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="role-name">Role Name</label>
                        <select
                            className="form-control"
                            id="role-name"
                            {...register('role_name', {
                                required: true,
                            })}>
                            {['Admin', 'Moderator'].map(role => (
                                <option value={role} key={role}>
                                    {role}
                                </option>
                            ))}
                        </select>
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.role_name?.message.replace('', '')}
                            </div>
                        )}
                    </div>
                    <div className="col-md-12">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </AppModal>
    );
};

export default WithLogin(AddAdmin);
