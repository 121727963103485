import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import Select from 'react-select';

import { accountRoles } from '../../constants';
import {
    fetchProviders,
    linkPatientProvider,
} from '../../services/patient.service';
import MessageContainer from '../message.component';
import PatientProviderList from './patientProvidersList.component';

const PatientProviderAssociation = props => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [providers, setProviders] = useState([]);
    const [accountId] = useState(props.accountId);
    const [reloadProviderTable, setReloadProviderTable] = useState(0);

    const {
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const onChangeProvider = _providers => {
        setValue('provider_id', _providers.value);
    };

    const getProviderList = async () => {
        const resp = await fetchProviders(accountId);
        const providers = resp.map(provider => {
            return {
                value: provider.id,
                label: `${provider.first_name} ${provider.last_name} (id:${provider.id})`,
            };
        });

        setProviders(providers);
    };

    useEffect(() => {
        getProviderList();
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const saveProviderAssociation = async data => {
        setLoading(true);
        setMessage({});
        try {
            const addProvider = await linkPatientProvider(
                props.accountId,
                data.provider_id,
                props.patientId,
            );
            setMessage({
                type: 'Success',
                message: addProvider.details,
            });
            setReloadProviderTable(reloadProviderTable + 1);
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }

        setLoading(false);
    };

    return (
        <>
            {message && <MessageContainer message={message} />}
            <form
                className="row g-2"
                name="form-1"
                onSubmit={handleSubmit(saveProviderAssociation)}>
                <div className="new-line" />
                <div className="col-md-8">
                    <label htmlFor="Providers">{accountRoles.PI}</label>
                    <Select
                        isSearchable={true}
                        options={providers}
                        onChange={onChangeProvider}
                        placeholder="Select a P.I"
                    />
                    {errors && (
                        <div className="invalid-feedback">
                            {errors?.clinic_ids?.message}
                        </div>
                    )}
                </div>
                <div className="col-md-4">
                    <button
                        type="submit"
                        className="btn btn-primary associate-button"
                        disabled={loading}>
                        {loading && (
                            <span className="spinner-border spinner-border-sm"></span>
                        )}
                        Associate
                    </button>
                </div>
                <PatientProviderList
                    accountId={props.accountId}
                    patientId={props.patientId}
                    reload={reloadProviderTable}
                    setMessage={setMessage}
                />
            </form>
        </>
    );
};

export default PatientProviderAssociation;
