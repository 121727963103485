import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import PeopleIcon from '@mui/icons-material/People';
import SyncIcon from '@mui/icons-material/Sync';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

import { deleteAccountUser } from '../../services/accountUser.service';
import { fetchPCCs } from '../../services/pcc.service';
import { customTableStyles, customTableStylesAccountView } from '../../utils';
import NotifyAccount from '../account/notifyAccountManager.component';
import EditAccountUser from '../accountUser/accountUserEdit.component';
import DeleteConfirmation from '../deleteConfirmation.component';
import MessageContainer from '../message.component';
import NotifyConfirmation from '../notifyConfirmation.component';
import ViewPCC from '../pcc/pccView.component';
import WithLogin from '../withLogin.component';
import ManageClinics from './manageClinics.component';

const PCCList = props => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const [message, setMessage] = useState({});
    const [openViewForm, setOpenViewForm] = useState(false);
    const [openManageClinicsForm, setOpenManageClinicsForm] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [showNotifyConfirmation, setShowNotifyConfirmation] = useState(false);
    const [openNotifyForm, setOpenNotifyForm] = useState(false);
    const [accountPersonId, setAccountPersonId] = useState(false);

    const onView = row => {
        setOpenViewForm(true);
        setSelectedRow(row);
    };
    const onManageClinics = row => {
        setOpenManageClinicsForm(true);
        setSelectedRow(row);
    };

    const onEdit = row => {
        setSelectedRow(row);
        setOpenEditForm(true);
    };

    const onDelete = async row => {
        setShowDeleteConfirmation(true);
        setSelectedRow(row);
    };

    const reloadDataTable = (toPage, perPage) => {
        getPCCs(toPage, perPage, props.pccSearchText, props.accountId);
        if (toPage) {
            setPage(toPage);
        }
    };

    const onNotify = row => {
        setAccountPersonId(row.account_person_id);
        setShowNotifyConfirmation(true);
        setSelectedRow(row);
    };

    const notifyAccountUser = row => {
        setOpenNotifyForm(true);
        setSelectedRow(row);
    };

    const deletePCC = async id => {
        setMessage({});
        try {
            const resp = await deleteAccountUser(id);
            reloadDataTable(page);
            setMessage({
                type: 'Success',
                message: `${resp.detail}`,
            });
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
        setShowDeleteConfirmation(false);
    };

    const columns = [
        {
            name: 'id',
            selector: row => row.account_person_id,
            width: '10%',
        },
        {
            name: 'Account',
            selector: row => row.account_name,
            width: '11%',
        },
        {
            name: 'Name',
            selector: row => `${row.first_name} ${row.last_name}`,
            width: '12%',
        },
        {
            name: 'Email',
            selector: row => row.email,
            width: '14%',
        },
        {
            name: 'Site',
            cell: row => (
                <div>
                    {row.clinic_names.map((clinic, i) => (
                        <span key={i}>
                            {clinic}
                            {i !== row.clinic_names.length - 1 && (
                                <span>, </span>
                            )}
                        </span>
                    ))}
                </div>
            ),
            width: '15%',
        },
        {
            name: 'Providers',
            cell: row => (
                <div>
                    {row.providers.map((provider, i) => (
                        <span key={i}>
                            {provider.first_name} {provider.last_name}
                            {i !== row.providers.length - 1 && <span>, </span>}
                        </span>
                    ))}
                </div>
            ),
            width: '13%',
        },
        {
            key: 'action',
            text: 'Action',
            name: 'Action',
            className: 'action',
            align: 'left',
            sortable: false,
            cell: row => {
                return (
                    <Fragment>
                        <span className="table-action-icon">
                            <EditIcon
                                className="icon-color"
                                onClick={() => {
                                    onEdit(row);
                                }}
                            />
                        </span>
                        &nbsp;
                        <VisibilityIcon
                            className="icon-color"
                            onClick={() => {
                                onView(row);
                            }}
                        />
                        &nbsp;
                        <span className="table-action-icon">
                            <PeopleIcon
                                className="icon-color"
                                onClick={() => {
                                    onManageClinics(row);
                                }}
                            />
                        </span>
                        &nbsp;
                        <DeleteIcon
                            color="red"
                            className="table-action-icon red"
                            onClick={() => onDelete(row)}
                        />
                        &nbsp;
                        {!row.is_activated_by_user && (
                            <Link
                                onClick={() => {
                                    onNotify(row);
                                }}
                                className="table-action-icon">
                                <NotificationsNoneIcon className="icon-color" />
                            </Link>
                        )}
                    </Fragment>
                );
            },
        },
    ];

    const getPCCs = async (toPage, toPerPage, searchText, accountId) => {
        setLoading(true);
        toPage = toPage ? toPage : page;
        toPerPage = toPerPage ? toPerPage : perPage;
        try {
            setMessage({});
            const response = await fetchPCCs(
                toPage,
                toPerPage,
                searchText,
                accountId,
            );
            setData(response.items);
            setTotalRows(response.total);
            Promise.resolve();
        } catch (error) {
            setMessage({
                message: 'An error has occurrded during data loading.',
            });
            Promise.reject();
        }
        setLoading(false);
    };

    useEffect(() => {
        getPCCs(page, perPage, props.pccSearchText, props.accountId);
    }, [props.accountId, props.pccSearchText, props.reloadData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = page => {
        setPage(page);
        getPCCs(page);
    };

    const handlePerRowsChange = async (newPerPage, toPage) => {
        setLoading(true);
        setMessage({});
        await getPCCs(toPage, newPerPage);
        setPerPage(newPerPage);
        setLoading(false);
    };

    return (
        <>
            {openEditForm && (
                <EditAccountUser
                    open={openEditForm}
                    setEditForm={setOpenEditForm}
                    selectedRow={selectedRow}
                    reloadDataTable={reloadDataTable}
                    accountPersonId={selectedRow.account_person_id}
                />
            )}
            {openViewForm && (
                <ViewPCC
                    open={openViewForm}
                    setEditForm={setOpenViewForm}
                    selectedRow={selectedRow}
                    reloadDataTable={reloadDataTable}
                />
            )}
            {openManageClinicsForm && (
                <ManageClinics
                    open={openManageClinicsForm}
                    setEditForm={setOpenManageClinicsForm}
                    selectedRow={selectedRow}
                    reloadDataTable={reloadDataTable}
                    CarePersonClinicId={selectedRow.account_person_id}
                />
            )}
            {openNotifyForm && (
                <NotifyAccount
                    open={openNotifyForm}
                    setNotifyForm={setOpenNotifyForm}
                    accountPersonId={accountPersonId}
                    reloadDataTable={reloadDataTable}
                />
            )}
            <span
                color="primary"
                className="float-end"
                style={{ marginRight: '10px' }}
                onClick={() => reloadDataTable()}>
                <SyncIcon />
            </span>
            <div>
                <div className="new-line"></div>
                {message && <MessageContainer message={message} />}
                <div className="accounts-list-table">
                    <DataTable
                        width="100%"
                        columns={columns}
                        customStyles={
                            props.accountId
                                ? customTableStylesAccountView
                                : customTableStyles
                        }
                        data={data}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationDefaultPage={page}
                        paginationResetDefaultPage={true}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </div>
                <DeleteConfirmation
                    showModal={showDeleteConfirmation}
                    hideModal={() => setShowDeleteConfirmation(false)}
                    confirmModal={deletePCC}
                    deleteId={selectedRow.account_person_id}
                    message="Are you sure you want to delete it"
                />
                {showNotifyConfirmation && (
                    <NotifyConfirmation
                        showModal={showNotifyConfirmation}
                        hideModal={() => setShowNotifyConfirmation(false)}
                        confirmModal={notifyAccountUser}
                        notifyAccountId={selectedRow}
                        buttonName="Send"
                        message={`Do you want to resend welcome email with login credential to ${selectedRow.first_name} ${selectedRow.last_name} (${selectedRow.email})`}
                    />
                )}
            </div>
        </>
    );
};

export default WithLogin(PCCList);
