import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, Navigate, useNavigate } from 'react-router-dom';

import { login } from '../app_store/actions/auth';

const Login = () => {
    let navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    });
    const [loading, setLoading] = useState(false);

    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);
    const [showPassword, setShowPassword] = useState(false);

    const dispatch = useDispatch();

    const handleLogin = data => {
        setLoading(true);
        dispatch(login(data.email, data.password))
            .then(() => {
                navigate('/accounts/');
                window.location.reload();
            })
            .catch(() => {
                setLoading(false);
            });
    };

    if (isLoggedIn) {
        return <Navigate to="/accounts/" />;
    }

    return (
        <div className="col-md-12">
            <div className="card card-container">
                <img
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    alt="profile-img"
                    className="profile-img-card"
                />
                {message && (
                    <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                            {message}
                        </div>
                    </div>
                )}
                <form onSubmit={handleSubmit(handleLogin)}>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            type="text"
                            tabIndex={1}
                            className={
                                errors?.email
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            name="email"
                            {...register('email', {
                                required: true,
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: 'invalid email address',
                                },
                            })}
                        />
                        <div>
                            <span className="password-label-left">
                                <label htmlFor="password">Password</label>
                            </span>
                            <span className="password-label-right">
                                <NavLink as={Link} to="/forgot-password/">
                                    Forgot password?
                                </NavLink>
                            </span>
                        </div>
                        <div className="password-div">
                            <input
                                tabIndex={2}
                                type={showPassword ? 'text' : 'password'}
                                className={
                                    errors?.password
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                name="password"
                                {...register('password', {
                                    required: true,
                                })}
                            />
                            <div
                                className="eye-icon"
                                onClick={() =>
                                    setShowPassword(password => !password)
                                }>
                                {showPassword ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-2">
                        <button
                            tabIndex={3}
                            className="btn btn-primary btn-block login-btn-w"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Login</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
