import React, { useEffect, useState } from 'react';

import { fetchProviderById } from '../../services/provider.service';
import { dateTimeFormat } from '../../utils';
import AppModal from '../appModal.component';
import WithLogin from '../withLogin.component';

const ViewProvider = props => {
    const [message, setMessage] = useState({});
    const [provider, setProvider] = useState({});
    const [show, setModalShow] = useState(props.open);

    const getProviders = async () => {
        try {
            const resp = await fetchProviderById(props.selectedRow.id);
            setProvider(resp);
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };

    useEffect(() => {
        setModalShow(props.open);
        getProviders();
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable();
        }
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Provider Detail">
            <div className="mt-2">
                <div className="col-md-12">
                    <span className="txt-label">ID</span>
                    <span className="txt-label-value">{provider.id}</span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Name</span>
                    <span className="txt-label-value">
                        {provider.first_name} {provider.last_name}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Email</span>
                    <span className="txt-label-value">{provider.email}</span>
                </div>

                <div className="col-md-12">
                    <span className="txt-label">NPI ID</span>
                    <span className="txt-label-value">{provider.npi_id}</span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Site</span>
                    <span className="txt-label-value">
                        {provider?.clinics?.map(clinic => (
                            <span key={clinic.name}> {clinic.name}</span>
                        ))}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Created at</span>
                    <span className="txt-label-value">
                        {dateTimeFormat(provider.created_at)}
                    </span>
                </div>
            </div>
        </AppModal>
    );
};

export default WithLogin(ViewProvider);
