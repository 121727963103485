import React, { useEffect, useState } from 'react';

import { accountRoles } from '../../constants';
import { fetchClinicById } from '../../services/clinic.service';
import { formatAddress } from '../../utils';
import AppModal from '../appModal.component';
import WithLogin from '../withLogin.component';

const ViewClinic = props => {
    const [message, setMessage] = useState({});
    const [clinic, setClinic] = useState({});
    const [show, setModalShow] = useState(props.open);

    const getClinics = async () => {
        try {
            const resp = await fetchClinicById(
                props.selectedRow.clinic_details.id,
            );
            setClinic(resp);
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };

    useEffect(() => {
        setModalShow(props.open);
        getClinics();
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable();
        }
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Site Detail">
            <div className="mt-2">
                <div className="col-md-12">
                    <span className="txt-label">Id</span>
                    <span className="txt-label-value">{clinic.id}</span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Site ID</span>
                    <span className="txt-label-value">{clinic.name}</span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Address</span>
                    <span className="txt-label-value">
                        {formatAddress(clinic.address)}
                    </span>
                </div>

                <div className="col-md-12">
                    <span className="txt-label">{accountRoles.PI}s</span>
                    <span className="txt-label-value">
                        {clinic.clinic_providers &&
                        clinic.clinic_providers.length !== 0 ? (
                            <span>
                                {clinic.clinic_providers.map((provider, i) => (
                                    <span key={i}>
                                        {' '}
                                        {provider.first_name}{' '}
                                        {provider.last_name}
                                        {i !==
                                            clinic.clinic_providers.length -
                                                1 && <span>, </span>}
                                    </span>
                                ))}
                            </span>
                        ) : (
                            <span>No PI Associated</span>
                        )}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">
                        Patient Care Collaborators
                    </span>
                    <span className="txt-label-value">
                        {clinic.clinic_care_persons &&
                        clinic.clinic_care_persons.length !== 0 ? (
                            <span>
                                {clinic.clinic_care_persons.map(
                                    (provider, i) => (
                                        <span key={i}>
                                            {' '}
                                            {provider.first_name}{' '}
                                            {provider.last_name}
                                            {i !==
                                                clinic.clinic_care_persons
                                                    .length -
                                                    1 && <span>, </span>}
                                        </span>
                                    ),
                                )}
                            </span>
                        ) : (
                            <span>No Care Persons Associated</span>
                        )}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Total {accountRoles.PI}s</span>
                    <span className="txt-label-value">
                        {props.selectedRow.provider_count}
                    </span>
                </div>
            </div>
        </AppModal>
    );
};

export default WithLogin(ViewClinic);
