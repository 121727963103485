import NoteAddIcon from '@mui/icons-material/NoteAdd';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { dateTimeFormat } from '../../utils';
import MessageContainer from '../message.component';

const Notes = props => {
    const { count, title, notes: noteList, handleUpdate, field_name } = props;
    const [openAdd, setOpenAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const handleSubmitNote = async data => {
        try {
            setLoading(true);
            await handleUpdate(data, field_name);
            reset();
            setLoading(false);
        } catch (error) {
            console.log(error);
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };
    return (
        <>
            <div className="col-md-12">
                <span className="txt-label">
                    {title} {count > 0 ? `[total ${count}]` : ''}
                </span>
                {!openAdd && (
                    <span className="txt-label-value float-right">
                        <Link
                            className="icon-color"
                            to="#"
                            onClick={e => {
                                e.preventDefault();
                                setOpenAdd(true);
                            }}>
                            <NoteAddIcon className="icon-color" />
                            Add Notes
                        </Link>
                    </span>
                )}
            </div>
            {openAdd && (
                <form
                    className="row g-2"
                    onSubmit={handleSubmit(handleSubmitNote)}>
                    {message && message.type && (
                        <MessageContainer message={message} />
                    )}
                    <div className="col-md-12">
                        <textarea
                            cols="70"
                            rows="3"
                            className={
                                errors?.body
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            {...register('body', {
                                required: true,
                                minLength: {
                                    value: 2,
                                    message:
                                        'Note can not be less than 2 characters',
                                },
                                maxLength: {
                                    value: 200,
                                    message: 'Note can be max. 200 char length',
                                },
                            })}
                        />
                        {errors?.body && (
                            <div className="invalid-feedback">
                                {console.log(errors)}
                                {errors?.body.message}
                            </div>
                        )}
                    </div>
                    <div className="col-md-12">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Add
                        </button>
                        <span className="mx-1"></span>
                        <button
                            type="submit"
                            className="btn btn-secondary"
                            onClick={e => {
                                e.preventDefault();
                                reset();
                                setLoading(false);
                                setOpenAdd(false);
                            }}>
                            Close
                        </button>
                    </div>
                </form>
            )}
            <div className="col-md-12 mt-2">
                <div className="txt-label-value">
                    {!noteList
                        ? '-'
                        : noteList.map((note, cnt) => (
                              <div className="note text-dark" key={cnt}>
                                  <i>
                                      <b>{dateTimeFormat(note.datetime)}</b>
                                      {note.user_fullname && (
                                          <b> by {note.user_fullname}</b>
                                      )}
                                  </i>
                                  <p>{note.body}</p>
                              </div>
                          ))}
                </div>
            </div>
        </>
    );
};

export default Notes;
