import DeleteIcon from '@mui/icons-material/Delete';
import Multiselect from 'multiselect-react-dropdown';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Controller, useForm } from 'react-hook-form';

import {
    PccProvidersDropdown,
    assignProvider,
    detachPccProvider,
    manageClinicsList,
} from '../../services/pcc.service';
import AppModal from '../appModal.component';
import DeleteConfirmation from '../deleteConfirmation.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';

const ManageProviders = props => {
    const [show, setModalShow] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [enableButton, setEnableButton] = useState(false);
    const [message, setMessage] = useState({});
    const [data, setData] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [providers, setProviders] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const onDelete = async row => {
        setShowDeleteConfirmation(true);
        setSelectedRow(row);
    };

    const handlePageChange = page => {
        setPage(page);
        getPccProvidersDropdown(page, perPage, props.clinic.clinic_id);
    };

    const handlePerRowsChange = async newPerPage => {
        setLoading(true);
        setMessage({});
        await getPccProvidersDropdown(page, perPage, props.clinic.clinic_id);
        setPerPage(newPerPage);
        setLoading(false);
    };

    const getPCCs = async (toPage, toPerPage, account_person_id, clinic_id) => {
        setLoading(true);
        toPage = toPage ? toPage : page;
        toPerPage = toPerPage ? toPerPage : perPage;
        try {
            setMessage({});
            const response = await manageClinicsList(
                toPage,
                toPerPage,
                account_person_id,
                clinic_id,
            );
            setData(response.items[0].providers);
            setTotalRows(response.total);
            Promise.resolve();
        } catch (error) {
            setMessage({
                message: 'An error has occurrded during data loading.',
            });
            Promise.reject();
        }
        setLoading(false);
    };

    const DeletePccProvider = async formData => {
        setMessage({});
        try {
            const data = {
                care_person_clinic_id: props.clinic.id,
                provider_id: formData.id,
            };
            await detachPccProvider(data);
            await getPCCs(
                page,
                perPage,
                props.selectedRow.account_person_id,
                props.clinic.clinic_id,
            );
            setMessage({
                type: 'Success',
                message: `Provider detected successfully`,
            });
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: 'Error deleting provider',
                });
            }
        }
        setShowDeleteConfirmation(false);
    };

    const getPccProvidersDropdown = async clinic_id => {
        setLoading(true);
        try {
            const resp = await PccProvidersDropdown(clinic_id);
            setTotalRows(resp.total);
            const providers = resp.map(provider => {
                return {
                    id: provider.id,
                    name: `${provider.first_name} ${provider.last_name}`,
                };
            });

            setProviders(providers);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during site list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const columns = [
        {
            name: '#',
            selector: row => row.id,
            width: '10%',
        },
        {
            name: 'Name',
            selector: row => `${row.first_name} ${row.last_name}`,
        },
        {
            name: 'Npi',
            selector: row => row.npi_id,
        },
        {
            key: 'action',
            text: 'Action',
            name: 'Action',
            className: 'action',
            width: '100px',
            align: 'left',
            sortable: false,
            cell: row => {
                return (
                    <Fragment>
                        <DeleteIcon
                            color="red"
                            className="table-action-icon red"
                            onClick={() => onDelete(row)}
                        />
                    </Fragment>
                );
            },
        },
    ];

    const handleModalClose = () => {
        setModalShow(false);
        props.setOpenmanageProvidersForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable(1);
        }
    };

    const onChangeProviderName = providers => {
        const provider_ids = [];
        Object.keys(providers).forEach(k => {
            provider_ids.push(providers[k]['id']);
        });
        if (provider_ids.length !== 0) setEnableButton(true);

        setValue('provider_ids', provider_ids);
    };

    const saveProvider = async formData => {
        setLoading(true);
        const data = {
            care_person_clinic_id: props.clinic.id,
            provider_id: formData?.provider_ids?.pop(),
        };
        try {
            await assignProvider(data);
            await getPCCs(
                page,
                perPage,
                props.selectedRow.account_person_id,
                props.clinic.clinic_id,
            );
            setMessage({
                type: 'Success',
                message: 'Provider added successfully',
            });
        } catch (error) {
            if (error.response.data.detail === 'Invalid request') {
                setMessage({
                    type: 'Error',
                    message: `Please select provider`,
                });
            } else {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
        setLoading(false);
        setEnableButton(false);
    };

    useEffect(() => {
        setModalShow(props.open);
        getPCCs(
            page,
            perPage,
            props.selectedRow.account_person_id,
            props.clinic.clinic_id,
        );
        getPccProvidersDropdown(props.clinic.clinic_id);
        setMessage({});
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    return (
        <>
            <DeleteConfirmation
                showModal={showDeleteConfirmation}
                hideModal={() => setShowDeleteConfirmation(false)}
                confirmModal={DeletePccProvider}
                deleteId={selectedRow}
                message="Are you sure you want to delete it"
            />

            {!showDeleteConfirmation && (
                <AppModal
                    size="lg"
                    show={show}
                    onHide={handleModalClose}
                    backdrop="static"
                    modalTitle="Assign Providers">
                    <div className="formView">
                        <form
                            className="row g-2"
                            onSubmit={handleSubmit(saveProvider)}>
                            <div className="col-md-8">
                                <label htmlFor="provider_id">
                                    Assign Providers
                                </label>
                                <Controller
                                    control={control}
                                    id="Provider"
                                    name="provider_id"
                                    render={() => (
                                        <Multiselect
                                            className="col-md-6"
                                            displayValue="name"
                                            singleSelect={true}
                                            isObject={true}
                                            key={loading}
                                            options={providers}
                                            onSelect={providers => {
                                                onChangeProviderName(providers);
                                            }}
                                        />
                                    )}
                                />
                                {errors && (
                                    <div className="invalid-feedback">
                                        {errors?.provider_id?.message}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-12">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={!enableButton}>
                                    {loading && (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    )}
                                    Add
                                </button>
                            </div>
                        </form>
                    </div>
                    <div>
                        {message && <MessageContainer message={message} />}
                        <div className="accounts-list-table">
                            <DataTable
                                width="100%"
                                columns={columns}
                                data={data}
                                progressPending={loading}
                                pagination
                                paginationServer
                                paginationDefaultPage={page}
                                paginationResetDefaultPage={true}
                                paginationTotalRows={totalRows}
                                onChangeRowsPerPage={handlePerRowsChange}
                                onChangePage={handlePageChange}
                            />
                        </div>
                    </div>
                </AppModal>
            )}
        </>
    );
};

export default WithLogin(ManageProviders);
