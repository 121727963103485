import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function AlertModal({ modalData, handleAlertModalClose, withCancel }) {
    const [show, setShow] = useState(false);

    const handleClose = () => {
        setShow(false);
        if (modalData.closeSection) {
            handleAlertModalClose(modalData.closeSection);
        } else {
            handleAlertModalClose();
        }
    };
    useEffect(() => {
        setShow(modalData.showModal);
    }, [modalData]);

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}>
            <Modal.Header
                closeButton
                style={{
                    textAlign: 'center',
                    borderBottom: 'None',
                }}></Modal.Header>
            <Modal.Body style={{ textAlign: 'center', borderTop: 'None' }}>
                <p
                    className={
                        modalData?.type?.toUpperCase() === 'ERROR'
                            ? 'alert'
                            : ''
                    }>
                    {modalData.message}
                </p>
            </Modal.Body>
            <Modal.Footer
                style={{ justifyContent: 'center', borderTop: 'None' }}>
                <Button variant="secondary" onClick={handleClose}>
                    Ok
                </Button>
                {withCancel && (
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default AlertModal;
