import api from './api';
import { API_URL } from './base';

export const addProvider = (data, account_id) => {
    return api
        .post(`${API_URL}/admin/providers/account/${account_id}/`, data)
        .then(resp => {
            return resp.data;
        });
};

export const updateProvider = (data, id) => {
    return api.put(`${API_URL}/admin/providers/${id}/`, data).then(resp => {
        return resp.data;
    });
};

export const fetchProvidersList = (page, perPage, searchText, accountId) => {
    let url = `${API_URL}/admin/providers/?page=${page}&size=${perPage}`;
    if (accountId) {
        url = `${url}&account_id=${accountId}`;
    }
    if (searchText) {
        url = `${url}&q=${searchText}`;
    }
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const fetchProviderById = id => {
    return api.get(`${API_URL}/admin/providers/${id}/`).then(resp => {
        return resp.data;
    });
};

export const fetchAdminClinicsList = accountId => {
    let url = `${API_URL}/admin/clinic-list/?account_id=${accountId}`;
    return api.get(url).then(resp => {
        return resp.data;
    });
};

// delete a provider
export const deleteProviderById = id => {
    return api.delete(`${API_URL}/admin/providers/${id}/`).then(resp => {
        return resp.data;
    });
};
