import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { fetchAccountList } from '../../services/account.service';
import { addClinic } from '../../services/clinic.service';
import { fetchStates } from '../../services/stateCity.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';

const AddClinic = props => {
    const [show, setModalShow] = useState(props.open);
    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [accountList, setAccountList] = useState([]);

    const country = 'US';
    const getStates = async () => {
        const stateList = await fetchStates(country);
        setStates(stateList);
    };

    const getAccountList = async () => {
        setLoading(true);
        try {
            const accounts = await fetchAccountList();
            setAccountList(accounts);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during account list loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const handleModalClose = () => {
        setModalShow(false);
        props.setopenAddForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable(1);
        }
    };
    useEffect(() => {
        setModalShow(props.open);
        getAccountList();
        getStates();
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: '',
            address: {
                line1: '',
                line2: '',
                country: 'US',
                state: '',
                city: '',
                zip: '',
            },
        },
    });

    const handleAddClinic = async data => {
        if (!data.account_id) {
            setMessage({
                type: 'Error',
                message: `Account is required`,
            });
            return;
        }

        data.name = data.name.trim();
        setLoading(true);
        try {
            setMessage({});
            await addClinic(data, data.account_id);
            setMessage({
                type: 'Success',
                message: 'Site has been created successfully',
            });
            reset();
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }

        setLoading(false);
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Add new site">
            <div className="formView">
                {message && <MessageContainer message={message} />}
                <form
                    className="row g-2"
                    onSubmit={handleSubmit(handleAddClinic)}>
                    <div className="col-12">
                        <label htmlFor="account_id">Account</label>
                        <select
                            className={
                                errors?.account_id
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="account_id"
                            {...register('account_id', { required: true })}>
                            <option value="" key="0">
                                Select an account
                            </option>
                            {accountList.map(account => (
                                <option value={account.id} key={account.id}>
                                    {account.name}(id: {account.id})
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-8">
                        <label htmlFor="clinicName">Name</label>
                        <input
                            type="text"
                            title="Only alphanumeric"
                            className={
                                errors?.name
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="clinicName"
                            placeholder="Enter site ID"
                            {...register('name', {
                                required: true,
                                pattern: {
                                    value: /^(?![0-9 ]+$)[a-zA-Z0-9-: ]*$/i,
                                    message:
                                        'Combination of alphabets and numbers are allowed',
                                },
                                maxLength: {
                                    value: 255,
                                    message: 'Name can be max. 255 char length',
                                },
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.name?.message}
                            </div>
                        )}
                    </div>
                    <div className="col-12">
                        <h5>Address</h5>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="Accountline1">Address line1</label>
                        <input
                            type="text"
                            className={
                                errors?.address?.line1
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="Accountline1"
                            placeholder="Enter line1"
                            {...register('address.line1', {
                                required: true,
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.address?.line1?.message}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="Accountline2">Address line2</label>
                        <input
                            type="text"
                            className={
                                errors?.address?.line2
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="Accountline1"
                            placeholder="Enter line1"
                            {...register('address.line2', {
                                required: false,
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.address?.line2?.message}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="AccountBillingCountry">Country</label>
                        <select
                            className="form-control"
                            id="AccountBillingCountry"
                            {...register('address.country', {
                                required: true,
                            })}>
                            <option value={'US'}>US</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="AccountBillingState">State</label>
                        <select
                            className="form-control"
                            id="AccountBillingState"
                            {...register('address.state', {
                                required: true,
                            })}>
                            {states.map(({ state_code, name }) => {
                                return (
                                    <option key={state_code} value={state_code}>
                                        {name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="AccountBillingCity">City</label>
                        <input
                            type="text"
                            className={
                                errors?.address?.city
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="AccountBillingCity"
                            {...register('address.city', {
                                required: true,
                                pattern: {
                                    value: /[a-zA-Z]+/i,
                                    message: 'Only text allowed',
                                },
                                minLength: {
                                    value: 2,
                                    message:
                                        'City can not be less than 2 characters',
                                },
                                maxLength: {
                                    value: 255,
                                    message: 'City can be max. 255 characters',
                                },
                            })}></input>
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.address?.city?.message}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="AccountBillingZip">Zip</label>
                        <input
                            type="number"
                            className={
                                errors?.address?.zip
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="AccountBillingZip"
                            placeholder="Enter zip code"
                            {...register('address.zip', {
                                required: true,
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.address?.zip?.message}
                            </div>
                        )}
                    </div>

                    <div className="col-md-12">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </AppModal>
    );
};

export default WithLogin(AddClinic);
