import { combineReducers } from 'redux';

import auth from './auth';
import message from './message';
import spinner from './spinner';

export default combineReducers({
    auth,
    message,
    spinner,
});
