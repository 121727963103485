import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { forgot_password } from '../services/auth.service';
import MessageContainer from './message.component';

const ForgotPassword = () => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: '',
        },
    });
    const handleForgotPassword = async data => {
        setLoading(true);
        setMessage({});
        try {
            const response = await forgot_password(data.email);
            setMessage({
                type: 'Success',
                message: `${response.detail}`,
            });
            reset();
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }
        setLoading(false);
    };

    return (
        <>
            <ForgotPasswordContainer>
                <h4>Forgot Password </h4>
                <div className="change-password-form">
                    {message && <MessageContainer message={message} />}
                    <form
                        className="row g-2"
                        onSubmit={handleSubmit(handleForgotPassword)}>
                        <div className="col-md-12">
                            <label htmlFor="email">Email</label>
                            <input
                                id="email"
                                type="email"
                                className={
                                    errors?.email
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                name="email"
                                {...register('email', {
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'invalid email address',
                                    },
                                })}
                            />
                        </div>
                        <div className="col-md-12">
                            <button
                                className="btn btn-primary"
                                disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                <span>Submit</span>
                            </button>
                        </div>
                    </form>
                </div>
            </ForgotPasswordContainer>
        </>
    );
};

const ForgotPasswordContainer = styled.div`
    padding-top: 10%;
    padding-left: 20%;
`;

export default ForgotPassword;
