import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import styled from 'styled-components';

const MessageContainer = ({ message }) => {
    const [show, setShow] = useState(message.message ? true : false);
    const messageType = message.type ? message.type : null;
    useEffect(() => {
        setShow(message.message ? true : false);
    }, [message]); // eslint-disable-line react-hooks/exhaustive-deps

    if (show) {
        return (
            <>
                {message && message.message && (
                    <AlertStyled
                        variant={messageType === 'Error' ? 'danger' : 'success'}
                        onClose={() => setShow(false)}
                        dismissible>
                        {message.message}
                    </AlertStyled>
                )}
            </>
        );
    }
};

const AlertStyled = styled(Alert)`
    margin: 10px;
`;

export default MessageContainer;
