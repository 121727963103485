import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import { getKitDetails, updateKit } from '../../services/kitInventories.service';
import {kitActiveState} from '../../constants'

const EditKitInventory = props => {
    const kit_id = props.selectedRow.id;
    const [show, setModalShow] = useState(props.open);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});

    const {
        control,
        register,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        props.reloadDataTable();
    };

    const getKitInventory = async() => {
        setLoading(true);
        setMessage({});
        try {
            const kitDetails = await getKitDetails(kit_id);
            setValue('account_name', kitDetails.account_name);
            setValue('unique_device_id', kitDetails.unique_device_id);
            setValue('sample_id', kitDetails.sample_id);
            setValue('lot_no', kitDetails.lot_no);
            setValue('expiry',  moment(kitDetails.expiry).toDate());
            setValue('is_active',kitDetails.is_active ? 'Active': 'InActive');
        }catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
        setLoading(false);
    }

    const saveKitDetails = async data => {
        setLoading(true);
        setMessage({});
        try{
            const kit = {
                "sample_id": data.sample_id,
                "unique_device_id": data.unique_device_id,
                "lot_no": data.lot_no,
                "expiry": data.expiry,
                "is_active" : data.is_active==='Active' ? true : false,
            }
            await updateKit(kit_id, kit);
            setMessage({
                type: 'Success',
                message: 'Changes have been saved successfully',
            });
        }catch(error){
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }
        setLoading(false);
    }
    
    useEffect(() => {
        setModalShow(props.open);
        getKitInventory();
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AppModal
            size="lg"
            show={show}
            backdrop="static"
            onHide={handleModalClose}
            modalTitle="Update kit">
            <div className="formView">
            {message && <MessageContainer message={message} />}
                <form className="row g-2" onSubmit={handleSubmit(saveKitDetails)}>
                    <div className="col-8">
                        <label htmlFor="unique_device_id">
                            Device Unique Id
                        </label>
                        <input
                            type="text"
                            className={
                                errors?.unique_device_id
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="unique_device_id"
                            placeholder="Enter device id"
                            {...register('unique_device_id', {
                                required: true,
                            })}
                        />
                    </div>
                    <div className="col-md-8">
                            <label htmlFor="sampleId">Sample Id</label>
                            <input
                                type="text"
                                className={
                                    errors?.sample_id
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                id="sampleId"
                                placeholder="Enter sample id"
                                {...register('sample_id', {
                                    required: true,
                                })}
                            />
                            {errors && (
                                <div className="invalid-feedback">
                                    {errors.sample_id?.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-8">
                            <label htmlFor="lotNo">Lot No</label>
                            <input
                                type="text"
                                className={
                                    errors?.lot_no
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                id="lotNo"
                                placeholder="Enter lot no."
                                {...register('lot_no', {
                                    required: true,
                                })}
                            />
                            {errors && (
                                <div className="invalid-feedback">
                                    {errors.lot_no?.message}
                                </div>
                            )}
                        </div>
                        <div className="col-8">
                            <label htmlFor="isActive">Kit Active State</label>
                            <select
                                className="form-control"
                                id="isActive"
                                {...register('is_active', {
                                })}>
                                {Object.keys(kitActiveState).map(status => (
                                    <option value={kitActiveState[status]} key={status}>
                                        {kitActiveState[status]}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-8">
                            <label htmlFor="expiry">Expiry Date</label>
                            <Controller
                                control={control}
                                id="expiry"
                                name="expiry"
                                render={({ field }) => (
                                    <DatePicker
                                        selected={field.value}
                                        showTimeSelect
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        onChange={datetime =>
                                            setValue('expiry', datetime)
                                        }
                                    />
                                )}
                            />
                            {errors && (
                                <div className="invalid-feedback">
                                    {errors?.expiry?.message}
                                </div>
                            )}
                        </div>
                        <div className="col-md-12">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm"></span>
                                )}
                                Save
                            </button>
                        </div>
                </form>
            </div>
        </AppModal>
    );
};

export default EditKitInventory;
