import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import { fetchKitOrderStatusLogs } from '../../services/kitOrder.service';
import { dateTimeFormat, titleCase } from '../../utils';
import UpdateKitOrderStatus from './statusLogUpdate.component';

const ViewStatusLogs = props => {
    const [statusLogs, setStatusLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});

    const columns = [
        {
            name: 'Status',
            selector: row => {
                let color = '';
                switch (row.fulfilment_state) {
                    case 'Done':
                        color = '#4caf50';
                        break;
                    case 'Pending':
                        color = '#ff9800';
                        console.log(color);
                        break;
                    case 'Failed':
                        color = '#f44336';
                        break;
                    default:
                        color = '#ff9800';
                }
                return (
                    <div style={{ display: 'inline' }}>
                        <span>
                            <CircleIcon style={{ color }} />
                        </span>
                        <span>
                            <b>{titleCase(row.status)}</b>
                        </span>
                    </div>
                );
            },
        },
        {
            name: 'Date',
            selector: row => dateTimeFormat(row.fulfillment_date),
        },
        {
            name: 'Fulfilled by',
            selector: row =>
                row.fulfiled_by
                    ? `${row.fulfiled_by.first_name} ${row.fulfiled_by.last_name}`
                    : '',
        },
        {
            name: 'Notes',
            selector: row => row.notes,
        },
        {
            name: 'Updated by',
            selector: row =>
                row.updated_by
                    ? `${row.updated_by.first_name} ${row.updated_by.last_name}`
                    : '',
        },
        {
            key: 'action',
            text: 'Action',
            name: 'Action',
            className: 'action',
            width: '75px',
            align: 'left',
            sortable: false,
            cell: row => {
                return (
                    <>
                        <span className="table-action-icon">
                            <EditIcon
                                className="icon-color"
                                onClick={() => {
                                    onEdit(row);
                                }}
                            />
                        </span>
                    </>
                );
            },
        },
    ];

    const getStatusLogs = async kitOrderId => {
        setLoading(true);
        try {
            const resp = await fetchKitOrderStatusLogs(kitOrderId);
            setStatusLogs(resp);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const onEdit = row => {
        console.log(row);
        setSelectedRow(row);
        setOpenEditForm(true);
    };

    useEffect(() => {
        getStatusLogs(props.kitOrder.id);
    }, [props.kitOrder]);

    const reloadDataTable = () => {
        getStatusLogs(props.kitOrder.id);
    };

    return (
        <>
            <div className="mt-2">
                <DataTable
                    width="100%"
                    columns={columns}
                    data={statusLogs}
                    progressPending={loading}
                />
            </div>
            {openEditForm && (
                <UpdateKitOrderStatus
                    open={openEditForm}
                    setEditForm={setOpenEditForm}
                    selectedRow={selectedRow}
                    reloadDataTable={reloadDataTable}
                />
            )}
        </>
    );
};

export default ViewStatusLogs;
