import InfoIcon from '@mui/icons-material/Info';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const InfoToolTip = props => {
    return (
        <span style={{ margin: '0px 5px 0px 5px' }}>
            <span
                style={{ cursor: 'pointer' }}
                className="my-anchor-element"
                data-tooltip-content={props.text}>
                <InfoIcon className="icon-color" />
            </span>
            <Tooltip
                anchorSelect=".my-anchor-element"
                style={{ zIndex: 100, fontSize: 15 }}
            />
        </span>
    );
};

export default InfoToolTip;
