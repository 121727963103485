import { API_URL } from './base';
import TokenService from './token.service';
import api from './api';

export const login = (email, password) => {
    return api
        .post(
            `${API_URL}/admin/oauth/token/`,
            {
                username: email,
                password,
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    grant_type: 'client_credentials',
                },
            },
        )
        .then(auth_response => {
            localStorage.setItem(
                'admin_auth',
                JSON.stringify(auth_response.data),
            );
            if (auth_response.data.access_token) {
                return api
                    .get(API_URL + '/admin/user/me/')
                    .then(user_response => {
                        const user_detail = user_response.data;
                        if (user_detail) {
                            localStorage.setItem(
                                'user_me',
                                JSON.stringify(user_detail),
                            );
                        }
                        return user_response.data;
                    });
            }
        });
};

export const logout = () => {
    return api.get(`${API_URL}/admin/auth/logout/`).then(response => {
        if (response.data.detail) {
            TokenService.removeLocalAccessToken();
        }
        return response.data;
    });
};

export const change_password = (
    current_password,
    new_password,
    confirm_new_password,
) => {
    return api
        .post(`${API_URL}/admin/user/change-password/`, {
            current_password: current_password,
            new_password: new_password,
            retype_new_password: confirm_new_password,
        })
        .then(response => {
            return response.data;
        });
};

export const forgot_password = email => {
    return api
        .post(`${API_URL}/admin/forgot-password/`, {
            email: email,
        })
        .then(response => {
            return response.data;
        });
};

export const reset_password = (new_password, retyped_password, token) => {
    return api
        .put(`${API_URL}/admin/password-reset/${token}/`, {
            new_password: new_password,
            retyped_password: retyped_password,
        })
        .then(response => {
            return response.data;
        });
};

const AuthService = {
    login,
    logout,
    change_password,
    forgot_password,
    reset_password,
};

export default AuthService;
