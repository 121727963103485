import React, { useEffect, useState } from 'react';

import { fetchAccountManagerbyId } from '../../services/accountUser.service';
import AppModal from '../appModal.component';
import WithLogin from '../withLogin.component';

const ViewACM = props => {
    const [message, setMessage] = useState({});
    const [acmData, setAcmData] = useState({});
    const [show, setModalShow] = useState(props.open);

    const getACMDetails = async () => {
        try {
            const resp = await fetchAccountManagerbyId(props.selectedRow.id);
            setAcmData(resp);
        } catch (error) {
            if (
                error.response.status === 400 ||
                error.response.status === 404
            ) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
            }
        }
    };

    useEffect(() => {
        setModalShow(props.open);
        getACMDetails();
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleModalClose = () => {
        setModalShow(false);
        props.setEditForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable();
        }
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle=" Account Manager Details">
            <div className="mt-2">
                <div className="col-md-12">
                    <span className="txt-label">Id</span>
                    <span className="txt-label-value">{acmData.id}</span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Name</span>
                    <span className="txt-label-value">
                        {acmData.first_name} {acmData.last_name}
                    </span>
                </div>

                <div className="col-md-12">
                    <span className="txt-label">Email</span>
                    <span className="txt-label-value">{acmData.email}</span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Is Primary</span>
                    <span className="txt-label-value">
                        {acmData.is_primary ? 'Yes' : 'No'}
                    </span>
                </div>
                <div className="col-md-12">
                    <span className="txt-label">Is Active</span>
                    <span className="txt-label-value">
                        {acmData.is_active ? 'Yes' : 'No'}
                    </span>
                </div>
            </div>
        </AppModal>
    );
};

export default WithLogin(ViewACM);
