import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { addAccount } from '../../services/account.service';
import { fetchTitleList } from '../../services/accountUser.service';
import { fetchPartnerOptions } from '../../services/partner.service';
import { fetchStates } from '../../services/stateCity.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';

const AddAccount = props => {
    const [show, setModalShow] = useState(props.open);
    const [states, setStates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [otherTitle, setOtherTitle] = useState(null);
    const [title, setTitle] = useState(null);
    const [titleList, setTitleList] = useState([]);
    const [partners, setPartners] = useState([]);

    const country = 'US';
    const getStates = async () => {
        const stateList = await fetchStates(country);
        setStates(stateList);
    };

    const getPartnerOptions = async () => {
        setLoading(true);
        try {
            const partnerList = await fetchPartnerOptions();
            setPartners(partnerList);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during partners loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const getTitleList = async () => {
        setLoading(true);
        try {
            const titles = await fetchTitleList();
            setOtherTitle(
                titles.find(t => t.title === 'Others')['id'].toString(),
            );
            setTitleList(titles);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during titles loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };

    const handleModalClose = () => {
        setModalShow(false);
        props.setOpenAddForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadAccountTable(1);
        }
    };
    useEffect(() => {
        setModalShow(props.open);
        getStates();
        getTitleList();
        getPartnerOptions();
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            partner_id: 1,
            name: '',
            acm: {
                first_name: '',
                last_name: '',
                email: '',
                is_primary: true,
            },
            clinic_name: '',
            clinic_address: {
                line1: '',
                line2: '',
                country: 'US',
                state: '',
                city: '',
                zip: '',
            },
            activation_email_now: true,
        },
    });

    const handleAddAccount = async data => {
        try {
            setMessage({});
            if (title !== otherTitle) data.acm.other_title = null;

            setLoading(true);
            await addAccount(data);
            setMessage({
                type: 'Success',
                message: 'Account has been created successfully',
            });
            reset();
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }

        setLoading(false);
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle="Add new account">
            <div className="formView">
                <form
                    className="row g-2"
                    onSubmit={handleSubmit(handleAddAccount)}>
                    {message?.type && <MessageContainer message={message} />}
                    <div className="col-md-6">
                        <label htmlFor="acmEmail">Trial Partner</label>
                        <select
                            className="form-control"
                            id="PartnerID"
                            required
                            {...register('partner_id', { required: true })}>
                            {partners.map(partner => (
                                <option value={partner.id} key={partner.id}>
                                    {partner.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-8">
                        <label htmlFor="accountName">Account Name</label>
                        <input
                            type="text"
                            className={
                                errors?.name
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="accountName"
                            placeholder="Enter account name"
                            {...register('name', {
                                required: true,
                                maxLength: {
                                    value: 200,
                                    message: 'Name can be max. 200 char length',
                                },
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.name?.message}
                            </div>
                        )}
                    </div>
                    <div className="col-12">
                        <h5>Account Manager</h5>
                    </div>
                    <div className="col-12">
                        <label htmlFor="title_id">Title</label>
                        <select
                            className={
                                errors?.title_id
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="title_id"
                            {...register('acm.title_id', { required: true })}
                            onChange={e => {
                                setTitle(e.target.value);
                            }}>
                            <option value="" key="0">
                                Select a title
                            </option>
                            {titleList.map(title => (
                                <option value={title.id} key={title.id}>
                                    {title.title}
                                </option>
                            ))}
                        </select>
                    </div>
                    {title === otherTitle && (
                        <div className="col-12">
                            <label htmlFor="other_title">{''}</label>
                            <input
                                type="text"
                                className={
                                    errors?.first_name
                                        ? 'form-control is-invalid'
                                        : 'form-control'
                                }
                                id="other_title"
                                {...register('acm.other_title')}
                            />
                        </div>
                    )}
                    <div className="col-md-6">
                        <label htmlFor="acmFirstName">First name</label>
                        <input
                            type="text"
                            className={
                                errors?.acm?.first_name
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="acmFirstName"
                            placeholder="Enter first name"
                            {...register('acm.first_name', {
                                required: true,
                                maxLength: {
                                    value: 200,
                                    message:
                                        'First name can be max. 200 char length',
                                },
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.acm?.first_name?.message.replace(
                                    'acm.',
                                    '',
                                )}
                            </div>
                        )}
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="acmLastName">Last name</label>
                        <input
                            type="text"
                            className={
                                errors?.acm?.last_name
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="acmLastName"
                            placeholder="Enter last name"
                            {...register('acm.last_name', {
                                required: true,
                                maxLength: {
                                    value: 200,
                                    message:
                                        'Last name can be max. 200 char length',
                                },
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.acm?.last_name?.message.replace(
                                    'acm.',
                                    '',
                                )}
                            </div>
                        )}
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="acmEmail">Email</label>
                        <input
                            type="text"
                            className={
                                errors?.acm?.email
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="acmEmail"
                            placeholder="Enter email"
                            {...register('acm.email', { required: true })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.acm?.email?.message.replace('acm.', '')}
                            </div>
                        )}
                    </div>
                    <div className="form-check col-md-12">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="sendEMailNow"
                            {...register('activation_email_now', {
                                required: false,
                            })}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="sendEMailNow">
                            send account activation email now
                        </label>
                    </div>
                    <div className="col-12">
                        <h5>Add Site</h5>
                    </div>

                    <div className="col-8">
                        <label htmlFor="clinicName">Site</label>
                        <input
                            type="text"
                            title="Only alphanumeric"
                            className={
                                errors?.clinic_name
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="clinicName"
                            placeholder="Enter site"
                            {...register('clinic_name', {
                                required: true,
                                pattern: {
                                    value: /^(?![0-9 ]+$)[a-zA-Z0-9 ]*$/i,
                                    message:
                                        'Combination of alphabets and numbers are allowed',
                                },
                                maxLength: {
                                    value: 255,
                                    message: 'Name can be max. 255 char length',
                                },
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.clinic_name?.message}
                            </div>
                        )}
                    </div>

                    <div className="col-12">
                        <h5>Site Address</h5>
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="Accountline1">Address line1</label>
                        <input
                            type="text"
                            className={
                                errors?.clinic_address?.line1
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="Accountline1"
                            placeholder="Enter line1"
                            {...register('clinic_address.line1', {
                                required: true,
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.clinic_address?.line1?.message}
                            </div>
                        )}
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="Accountline2">Address line2</label>
                        <input
                            type="text"
                            className={
                                errors?.clinic_address?.line2
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="Accountline1"
                            placeholder="Enter line1"
                            {...register('clinic_address.line2', {
                                required: false,
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.clinic_address?.line2?.message}
                            </div>
                        )}
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="ClinicCountry">Country</label>
                        <select
                            className="form-control"
                            id="ClinicCountry"
                            {...register('clinic_address.country', {
                                required: true,
                            })}>
                            <option value={'US'}>US</option>
                        </select>
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="ClinicState">State</label>
                        <select
                            className="form-control"
                            id="ClinicState"
                            {...register('clinic_address.state', {
                                required: true,
                            })}>
                            {states.map(({ state_code, name }) => {
                                return (
                                    <option key={state_code} value={state_code}>
                                        {name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="ClinicCity">City</label>
                        <input
                            type="text"
                            className={
                                errors?.clinic_address?.city
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="ClinicCity"
                            {...register('clinic_address.city', {
                                required: true,
                                pattern: {
                                    value: /[a-zA-Z]+/i,
                                    message: 'Only text allowed',
                                },
                                minLength: {
                                    value: 2,
                                    message:
                                        'City can not be less than 2 characters',
                                },
                                maxLength: {
                                    value: 255,
                                    message: 'City can be max. 255 characters',
                                },
                            })}></input>
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.clinic_address?.city?.message}
                            </div>
                        )}
                    </div>

                    <div className="col-md-6">
                        <label htmlFor="ClinicZip">Zip</label>
                        <input
                            type="number"
                            className={
                                errors?.clinic_address?.zip
                                    ? 'form-control is-invalid'
                                    : 'form-control'
                            }
                            id="ClinicZip"
                            placeholder="Enter zip code"
                            {...register('clinic_address.zip', {
                                required: true,
                            })}
                        />
                        {errors && (
                            <div className="invalid-feedback">
                                {errors.clinic_address?.zip?.message}
                            </div>
                        )}
                    </div>

                    <div className="col-md-12">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </AppModal>
    );
};

export default WithLogin(AddAccount);
