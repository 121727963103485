import api from './api';
import { API_URL } from './base';

export const fetchKitOrders = (page, perPage, kitOrderSearchFilters) => {
    let url = `${API_URL}/admin/accounts/kit-orders/?page=${page}&size=${perPage}`;
    if (kitOrderSearchFilters.accountId) {
        url = `${url}&account_id=${kitOrderSearchFilters.accountId}`;
    }
    if (kitOrderSearchFilters.searchText) {
        url = `${url}&q=${kitOrderSearchFilters.searchText}`;
    }

    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const fetchProductList = () => {
    return api.get(`${API_URL}/kit-order-products/`).then(resp => {
        return resp.data;
    });
};

export const createkitOrder = data => {
    return api
        .post(`${API_URL}/admin/accounts/${data.account_id}/kit-orders/`, data)
        .then(resp => {
            return resp.data;
        });
};

export const getKitOrder = id => {
    return api.get(`${API_URL}/admin/accounts/kit-orders/${id}/`).then(resp => {
        return resp.data;
    });
};

export const updateKitOrder = (id, data) => {
    return api
        .put(`${API_URL}/admin/accounts/kit-orders/${id}/`, data)
        .then(resp => {
            return resp.data;
        });
};

export const updateNote = (id, data) => {
    return api
        .put(`${API_URL}/admin/accounts/kit-orders/${id}/add_notes/`, data)
        .then(resp => {
            return resp.data;
        });
};

export const fetchKitOrderStatusLogs = kitOrderId => {
    return api
        .get(`${API_URL}/admin/accounts/kit-orders/${kitOrderId}/status-logs/`)
        .then(resp => {
            return resp.data;
        });
};

export const saveKitOrderStatusUpdate = (id, kitOrderId, data) => {
    return api
        .put(
            `${API_URL}/admin/accounts/kit-orders/${kitOrderId}/status-logs/${id}/`,
            data,
        )
        .then(resp => {
            return resp.data;
        });
};

export const fetchKitOrderList = (accountId, kit_statuses) => {
    let url = `${API_URL}/admin/accounts/${accountId}/kit-orders-list/`;
    if (kit_statuses) {
        url = `${url}?kit_statuses=${kit_statuses}`;
    }
    return api.get(url).then(resp => {
        return resp.data;
    });
};

export const fetchKitOrderRevenueDetails = () => {
    return api
        .get(`${API_URL}/admin/accounts/kit-order-revenue-stats/`)
        .then(resp => {
            return resp.data;
        });
};
