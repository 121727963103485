import { Visibility, VisibilityOff } from '@mui/icons-material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { change_password } from '../services/auth.service';
import MessageContainer from './message.component';
import WithLogin from './withLogin.component';

const ChangePassword = () => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({});
    const [showPasswordCurrent, setShowPasswordCurrent] = useState(false);
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [showPasswordRetyped, setShowPasswordRetyped] = useState(false);
    const { register, handleSubmit, setError, reset } = useForm({
        defaultValues: {
            current_password: '',
            new_password: '',
            confirm_new_password: '',
        },
    });
    const handleChangePassword = async data => {
        setLoading(true);
        setMessage({});
        try {
            const response = await change_password(
                data.current_password,
                data.new_password,
                data.confirm_new_password,
            );
            setMessage({
                type: 'Success',
                message: `${response.detail}`,
            });
            reset();
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }
        setLoading(false);
    };

    return (
        <>
            {message && <MessageContainer message={message} />}
            <h4>Change Password</h4>
            <div className="change-password-form">
                <form
                    className="row g-2"
                    onSubmit={handleSubmit(handleChangePassword)}>
                    <div className="col-md-12">
                        <label htmlFor="currentPassword">
                            Current password
                        </label>
                        <div style={{position:'relative'}}>
                            <input
                                id="currentPassword"
                                type={showPasswordCurrent ? 'text' : 'password'}
                                className="form-control"
                                name="current_password"
                                {...register('current_password', {
                                    required: true,
                                })}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: '20%',
                                }}
                                onClick={() =>
                                    setShowPasswordCurrent(
                                        password => !password,
                                    )
                                }>
                                {showPasswordCurrent ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="newPassword">New password</label>
                        <div style={{ position: 'relative' }}>
                            <input
                                id="newPassword"
                                type={showPasswordNew ? 'text' : 'password'}
                                className="form-control"
                                name="new_password"
                                {...register('new_password', {
                                    required: true,
                                })}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: '20%',
                                }}
                                onClick={() =>
                                    setShowPasswordNew(password => !password)
                                }>
                                {showPasswordNew ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label htmlFor="currentNewPassword">
                            Confirm new password
                        </label>
                        <div style={{ position: 'relative' }}>
                            <input
                                type={showPasswordRetyped ? 'text' : 'password'}
                                id="currentNewPassword"
                                className="form-control"
                                name="confirm_new_password"
                                {...register('confirm_new_password', {
                                    required: true,
                                })}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    right: 10,
                                    top: '20%',
                                }}
                                onClick={() =>
                                    setShowPasswordRetyped(
                                        password => !password,
                                    )
                                }>
                                {showPasswordRetyped ? (
                                    <Visibility />
                                ) : (
                                    <VisibilityOff />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <button className="btn btn-primary" disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            <span>Update Password</span>
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default WithLogin(ChangePassword);
