import React from 'react';
import Modal from 'react-bootstrap/Modal';

const AppModal = props => {
    const { modalTitle, children, footer, ...modalProps } = props;
    return (
        <>
            <Modal {...modalProps}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="app-modal">{children}</Modal.Body>
                {footer && <Modal.Footer>{footer}</Modal.Footer>}
            </Modal>
        </>
    );
};

export default AppModal;
