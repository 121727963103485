import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { CircleSpinnerOverlay } from 'react-spinner-overlay';

import IdleTimer from './IdleTimer';
import { logout } from './app_store/actions/auth';
import { clearMessage } from './app_store/actions/message';
import AppRoute from './components/appRoutes.component';
import './css/App.css';

function App() {
    const dispatch = useDispatch();
    const [activeKey, setActive] = useState('accounts');
    // const [showModeratorBoard, setShowModeratorBoard] = useState(false);
    const [showAdminBoard, setShowAdminBoard] = useState(false);

    const { user: currentUser } = useSelector(state => state.auth);
    const { active: spinnerActive } = useSelector(state => state.spinner);
    const navigate = useNavigate();
    const [isTimeout, setIsTimeout] = useState(false);

    let location = useLocation();

    useEffect(() => {
        if (location.pathname.startsWith('/accounts')) {
            setActive('accounts');
        } else if (location.pathname.startsWith('/kit-orders')) {
            setActive('kit-orders');
        } else if (location.pathname.startsWith('/kit-inventories')) {
            setActive('kit-inventories');
        } else if (location.pathname.startsWith('/lab-orders/')) {
            setActive('lab-orders');
        } else if (location.pathname.startsWith('/admins/')) {
            setActive('admins');
        } else {
            setActive('accounts');
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (['/login'].includes(location.pathname)) {
            dispatch(clearMessage()); // clear message when changing location
        }
    }, [dispatch, location]); // eslint-disable-line react-hooks/exhaustive-deps

    const logOut = () => {
        //call logout service call
        dispatch(logout())
            .then(() => {
                navigate('/login/');
                window.location.reload();
            })
            .catch(() => {});
    };

    useEffect(() => {
        if (currentUser) {
            // setShowModeratorBoard(currentUser.role === "Moderator Admin");
            setShowAdminBoard(currentUser.role === 'Admin');
        } else {
            // setShowModeratorBoard(false);
            setShowAdminBoard(false);
        }
    }, [currentUser]);

    useEffect(() => {
        const timer = new IdleTimer({
            timeout: 600, //if user remains idle, expire session after 10 minutes
            onTimeout: () => {
                logOut();
                setIsTimeout(true);
            },
            onExpired: () => {
                // do something if expired on loadisTimeout
                setIsTimeout(true);
            },
        });

        return () => {
            timer.cleanUp();
        };
    }, [isTimeout]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="container-fluid">
            <Navbar
                collapseOnSelect
                expand="lg"
                bg="dark"
                variant="dark"
                className="bd-navbar">
                <Container fluid>
                    <Navbar.Brand href="/">
                        {currentUser
                            ? 'Clinical Trial LIMS'
                            : 'Phenomix Sciences Clinical Trial LIMS'}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        {!showAdminBoard && (
                            <Nav className="me-auto">&nbsp;</Nav>
                        )}
                        {showAdminBoard && (
                            <Nav
                                className="me-auto"
                                variant="pills"
                                activeKey={activeKey}
                                onSelect={selectedKey =>
                                    setActive(selectedKey)
                                }>
                                <Nav.Link
                                    as={Link}
                                    eventKey="accounts"
                                    to="/accounts/">
                                    Accounts
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    eventKey="kit-orders"
                                    to="/kit-orders/">
                                    Kit Orders
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    eventKey="kit-inventories"
                                    to="/kit-inventories/">
                                    Kit Inventories
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    eventKey="lab-orders"
                                    to="/lab-orders/">
                                    Lab Orders
                                </Nav.Link>
                                <Nav.Link
                                    as={Link}
                                    eventKey="admins"
                                    to="/admins/">
                                    Admins
                                </Nav.Link>
                            </Nav>
                        )}
                        {currentUser ? (
                            <Nav>
                                {/* <Nav.Link href="#deets">{currentUser.first_name}
						{currentUser.last_name}&lt;{currentUser.role}&gt;</Nav.Link> */}
                                <NavDropdown
                                    title={`${currentUser.first_name} ${currentUser.last_name}`}
                                    id="collasible-nav-dropdown1">
                                    <NavDropdown.Item
                                        to="/logout/"
                                        onClick={logOut}>
                                        Sign out
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        as={Link}
                                        to="/change-password/">
                                        Settings
                                    </NavDropdown.Item>
                                    {/* <NavDropdown.Item as={Link} to="/settings/">ChangePassword</NavDropdown.Item> */}
                                </NavDropdown>
                            </Nav>
                        ) : (
                            <Nav>
                                <Nav.Link as={Link} to="/">
                                    Login
                                </Nav.Link>
                            </Nav>
                        )}
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            <div className="mt-1">
                <CircleSpinnerOverlay
                    loading={spinnerActive}
                    overlayColor="rgba(0,153,255,0.2)"
                    zIndex={2147483647}
                    color="#6610f2"></CircleSpinnerOverlay>
                <AppRoute />
            </div>
        </div>
    );
}

export default App;
