import EditIcon from '@mui/icons-material/Edit';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { fetchAccounts } from '../../services/account.service';
import { customTableStyles, dateTimeFormat } from '../../utils';
import MessageContainer from '../message.component';
import NotifyConfirmation from '../notifyConfirmation.component';
import WithLogin from '../withLogin.component';
import AddAccount from './accountAdd.component';
import ViewAccount from './accountView.component';
import EditAccount from './accountedit.component';
import NotifyAccount from './notifyAccountManager.component';

const Account = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [openAddForm, setOpenAddForm] = useState(false);
    const [openEditForm, setOpenEditForm] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [openViewForm, setOpenViewForm] = useState(false);
    const [openNotifyForm, setOpenNotifyForm] = useState(false);

    const [message, setMessage] = useState({});
    const [accountSearchText, setAccountSearchText] = useState('');
    const [showNotifyConfirmation, setShowNotifyConfirmation] = useState(false);

    const columns = [
        {
            name: 'id',
            selector: row => row.id,
            width: '10%',
        },
        {
            name: 'Name',
            selector: row => row.name,
        },

        {
            name: 'P.I',
            selector: row => row.provider_count,
        },
        {
            name: 'Patients',
            selector: row => row.patient_count,
        },
        {
            name: 'Managers',
            selector: row => row.account_manager_count,
        },

        {
            name: 'Active',
            selector: row => (row.is_active ? 'Yes' : 'No'),
        },
        {
            name: 'Created at',
            selector: row => dateTimeFormat(row.created_at),
        },
        {
            key: 'action',
            text: 'Action',
            name: 'Action',
            className: 'action',
            width: '95px',
            align: 'left',
            sortable: false,
            cell: row => {
                return (
                    <Fragment>
                        <span className="table-action-icon">
                            <EditIcon
                                className="icon-color"
                                onClick={() => {
                                    onEdit(row);
                                }}
                            />
                        </span>
                        &nbsp;
                        <Link
                            to={`/accounts/${row.id}/`}
                            className="table-action-icon">
                            <VisibilityIcon className="icon-color" />
                        </Link>
                        &nbsp;
                        {!row.is_activated_by_owner && (
                            <Link
                                onClick={() => {
                                    onNotify(row);
                                }}
                                className="table-action-icon">
                                <NotificationsNoneIcon className="icon-color" />
                            </Link>
                        )}
                    </Fragment>
                );
            },
        },
    ];

    const onEdit = row => {
        setSelectedRow(row);
        setOpenEditForm(true);
    };
    const handleOpenAddForm = () => {
        setOpenAddForm(true);
    };
    const onNotify = row => {
        setShowNotifyConfirmation(true);
        setSelectedRow(row);
    };
    const notifyAcm = row => {
        setOpenNotifyForm(true);
        setSelectedRow(row);
    };

    const getAccounts = async (toPage, toPerPage) => {
        setLoading(true);
        setMessage({});
        toPage = toPage ? toPage : page;
        toPerPage = toPerPage ? toPerPage : perPage;
        try {
            const response = await fetchAccounts(
                toPage,
                toPerPage,
                accountSearchText,
            );
            setData(response.items);
            setTotalRows(response.total);
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded during data loading.',
                type: 'Error',
            });
        }
        setLoading(false);
    };
    useEffect(() => {
        getAccounts(1);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handlePageChange = toPage => {
        getAccounts(toPage);
        setPage(toPage);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        await getAccounts(page, newPerPage);
        setPerPage(newPerPage);
    };

    const reloadDataTable = toPage => {
        toPage = toPage ? toPage : page;
        getAccounts(toPage);
        if (toPage) {
            setPage(toPage);
        }
    };

    const { register, handleSubmit, reset } = useForm({
        defaultValues: {
            searchText: accountSearchText,
        },
    });

    useEffect(() => {
        getAccounts(1);
        setPage(1);
    }, [accountSearchText]); // eslint-disable-line react-hooks/exhaustive-deps

    const setSearchText = async data => {
        setAccountSearchText(data.searchText.trim());
    };

    return (
        <>
            {openViewForm && (
                <ViewAccount
                    open={openViewForm}
                    setEditForm={setOpenViewForm}
                    selectedRow={selectedRow}
                    reloadDataTable={reloadDataTable}
                />
            )}
            <div>
                <div className="pageHeader">
                    <span id="header">
                        <h4>Accounts</h4>
                    </span>
                    <span id="header-add-btn">
                        <button
                            className="btn btn-primary"
                            onClick={handleOpenAddForm}>
                            + Add Account
                        </button>
                    </span>
                </div>

                <hr />

                {openAddForm && (
                    <AddAccount
                        open={openAddForm}
                        setOpenAddForm={setOpenAddForm}
                        reloadAccountTable={reloadDataTable}
                    />
                )}
                {openEditForm && (
                    <EditAccount
                        open={openEditForm}
                        setEditForm={setOpenEditForm}
                        selectedRow={selectedRow}
                        reloadDataTable={reloadDataTable}
                    />
                )}
                {openNotifyForm && (
                    <NotifyAccount
                        open={openNotifyForm}
                        setNotifyForm={setOpenNotifyForm}
                        selectedRow={selectedRow}
                        reloadDataTable={reloadDataTable}
                        isAccountNotify={true}
                    />
                )}
                {message && <MessageContainer message={message} />}
                <form
                    className="row g-2"
                    onSubmit={handleSubmit(setSearchText)}>
                    <div className="col-md-3">
                        <input
                            type="text"
                            className="form-control"
                            id="search"
                            placeholder="Enter account ID or name"
                            {...register('searchText')}
                        />
                    </div>
                    <div className="col-md-9">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}>
                            {loading && (
                                <span className="spinner-border spinner-border-sm"></span>
                            )}
                            Search
                        </button>
                        <span className="mx-1"></span>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={loading}
                            onClick={() => {
                                reset();
                            }}>
                            Reset
                        </button>
                    </div>
                </form>
                <div className="new-line"></div>
                <div className="accounts-list-table">
                    <DataTable
                        width="100%"
                        customStyles={customTableStyles}
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationDefaultPage={page}
                        paginationResetDefaultPage={true}
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                    />
                </div>
            </div>
            {!openNotifyForm && showNotifyConfirmation && (
                <NotifyConfirmation
                    showModal={showNotifyConfirmation}
                    hideModal={() => setShowNotifyConfirmation(false)}
                    confirmModal={notifyAcm}
                    notifyAccountId={selectedRow}
                    buttonName="Notify"
                    message="Are you sure you want to send account activation email to Account manager?"
                />
            )}
        </>
    );
};

export default WithLogin(Account);
