import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { useSelector } from 'react-redux';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';

import LabOrderList from './labOrderList.component';

function LabOrderBase() {
    const { isLoggedIn } = useSelector(state => state.auth);
    const [activeKey, setActive] = useState('lab-orders');
    const location = useLocation();
    useEffect(() => {
        console.log(location.pathname);
        if (location.pathname === '/lab-orders/') {
            setActive('lab-orders');
        } else if (location.pathname.endsWith('/monitor-lab-status/')) {
            setActive('sample-monitor');
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    if (!isLoggedIn) {
        return <Navigate to="/login/" />;
    }

    return (
        <>
            <div className="col-md-12">
                <Nav
                    as="ul"
                    activeKey={activeKey}
                    onSelect={selectedKey => setActive(selectedKey)}
                    className="nav nav-tabs">
                    <Nav.Item as="li">
                        <NavLink
                            as={Link}
                            eventKey="lab-orders"
                            to="/lab-orders/">
                            Lab orders
                        </NavLink>
                    </Nav.Item>
                </Nav>
            </div>
            <div className="col-md-12">
                <Routes>
                    <Route path="/" element={<LabOrderList />} />
                </Routes>
            </div>
        </>
    );
}

export default LabOrderBase;
