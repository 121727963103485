import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './login.component';
import AccountBase from './account/accountBase.component';
import AdminBase from './user/userBase.component';
import NotFound from './notfound.component';
import ForgotPassword from './forgotPassword.component';
import ResetPassword from './resetPassword.component';
import ChangePassword from './changePassword.component';
import KitOrderBase from './kitOrder';
import KitInventoriesBase from './kitInventories';
import LabOrderBase from '../components/labOrder/labOrderBase.component';

const AppRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            {/* <Route path="/home" element={<Login />} /> */}
            <Route path="/login/" element={<Login />} />
            <Route path="/admins/" element={<AdminBase />} />
            <Route path="/accounts/*" element={<AccountBase />} />
            <Route path="/kit-orders/*" element={<KitOrderBase />} />
            <Route path="/lab-orders/*" element={<LabOrderBase />} />
            <Route path="/kit-inventories/*" element={<KitInventoriesBase />} />
            <Route path="/forgot-password/" element={<ForgotPassword />} />
            <Route path="/reset-password/" element={<ResetPassword />} />
            <Route path="/change-password/" element={<ChangePassword />} />
            {/* <Route path="/user" element={<BoardUser />} />
          <Route path="/mod" element={<BoardModerator />} />
          <Route path="/admin" element={<BoardAdmin />} /> */}
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoute;
