import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';

import { notifyAccountManager, notifyAccountUser } from '../../services/account.service';
import AppModal from '../appModal.component';
import MessageContainer from '../message.component';
import WithLogin from '../withLogin.component';

const NotifyAccountManager = props => {
    const [show, setModalShow] = useState(props.open);
    const [message, setMessage] = useState({});
    const [modelTitle] = useState('');

    const handleModalClose = () => {
        setModalShow(false);
        props.setNotifyForm(false);
        if (Object.keys(message).length !== 0) {
            props.reloadDataTable(1);
        }
    };

    const notifyPrimaryACM = async () => {
        try {
            handleNotifyAccountManager({ account_id: props.selectedRow.id });
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded notifying acm.',
                type: 'Error',
            });
        }
    };

    const notifyAccountUsers = async () => {
        try {
            handleNotifyAccountUser({ account_person_id: props.accountPersonId });
        } catch (error) {
            setMessage({
                detail: 'An error has occurrded notifying acm.',
                type: 'Error',
            });
        }
    };

    const { setError, reset } = useForm({
        defaultValues: {
            first_name: '',
            last_name: '',
        },
    });

    useEffect(() => {
        setModalShow(props.open);
        if(props.isAccountNotify){
            notifyPrimaryACM();
        }else{
            notifyAccountUsers();
        }
        setMessage({});
    }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleNotifyAccountManager = async data => {
        try {
            setMessage({});
            await notifyAccountManager(data.account_id);
            setMessage({
                type: 'Success',
                message: 'Account manager has been notified',
            });
            reset();
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }
    };

    const handleNotifyAccountUser = async data => {
        try {
            setMessage({});
            await notifyAccountUser(data.account_person_id);
            setMessage({
                type: 'Success',
                message: 'Selected user has been notified',
            });
            reset();
        } catch (error) {
            if (error.response.status === 400) {
                setMessage({
                    type: 'Error',
                    message: `${error.response.data.detail}`,
                });
                error.response.data.errors?.forEach(validationError => {
                    Object.keys(validationError).forEach(key => {
                        setError(key, { message: validationError[key] });
                    });
                });
            }
        }
    };

    return (
        <AppModal
            size="lg"
            show={show}
            onHide={handleModalClose}
            backdrop="static"
            modalTitle={modelTitle}>
            <div className="formView">
                {message && <MessageContainer message={message} />}
            </div>
        </AppModal>
    );
};

export default WithLogin(NotifyAccountManager);
