import moment from 'moment';

export const formatAddress = address => {
    let formatedAddress = '';
    if (!address) {
        return formatedAddress;
    }

    if (address.line1) {
        formatedAddress += `${address.line1}`;
    }
    if (address.line2) {
        formatedAddress += `, ${address.line2}`;
    }
    if (address.city) {
        formatedAddress += `, ${address.city}`;
    }
    if (address.state) {
        formatedAddress += `, ${address.state}`;
    }
    if (address.country) {
        formatedAddress += `, ${address.country}`;
    }
    if (address.zip) {
        formatedAddress += `, ${address.zip}`;
    }
    return formatedAddress;
};

export const formatAddressContact = address => {
    let formatedAddressContact = '';
    if (!address) {
        return formatedAddressContact;
    }

    if (address.contact_mobile_number) {
        formatedAddressContact += `M: ${address.contact_mobile_number}`;
    }
    if (address.contact_office_number) {
        formatedAddressContact += `, O: ${address.contact_office_number}`;
    }
    if (address.contact_email) {
        formatedAddressContact += `, E: ${address.contact_email}`;
    }
    return formatedAddressContact;
};

export const dateTimeFormat = datetime => {
    return datetime ? moment(datetime).format('MMM DD, YYYY hh:mm A') : '';
};

export const dateFormat = datetime => {
    return datetime ? moment(datetime).format('MMM DD, YYYY') : '';
};

export const dateFormatDob = datetime => {
    return datetime ? moment(datetime).format('YYYY-MM-DD') : '';
};

export const titleCase = text => {
    try {
        return text ? text[0].toUpperCase() + text.slice(1).toLowerCase() : '';
    } catch (error) {
        console.log(text);
        console.log(error);
        return text;
    }
};

export const customTableStyles = {
    headCells: {
        style: {
            backgroundColor: '#d4d0d0',
            fontSize: '13px',
        },
    },
};


export const customTableStylesAccountView = {
    headCells: {
        style: {
            backgroundColor: '#ffffff',
            fontSize: '13px',
        },
    },
};